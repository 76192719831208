import React from 'react'
import { DottedCircleLoader } from '@vega/components'
import { styled, s } from '@vega/styled'
import { prop } from '@solta/ramda-extra'

const Root = styled.div(s('flex flex-column'))
const Label = styled.p(
  s('text-2xl font-semibold text-grey-600 mx-a'),
  prop('labelStyle')
)
const Loader = styled(DottedCircleLoader)(s('mx-a'))
const LoaderWithLabel = ({ label, size = 'lg', labelStyle, ...props }) => {
  return (
    <Root {...props}>
      <Loader size={size} />
      <Label labelStyle={labelStyle}>{label}</Label>
    </Root>
  )
}

export default LoaderWithLabel
