import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Formik as FormProvider, Form } from 'formik'
import * as Validator from 'yup'
import { s } from '@vega/styled/v2'
import { ContactDetailsCard } from './ContactDetailsCard'
import { ContactDetailsMobile } from './ContactDetailsMobile'
import { updateCustomer } from 'modules/customer'
import { checkAreObjectsTheSame } from '../../utils'

const { object, string } = Validator

const validationSchema = object({
  phoneNumber: string().required('Phone number is required'),
})

// data >> {email: 'support@neoglobal.dev', phoneNumber: '02 198 8787'}

export const ContactDetails = ({ isLoading, setCustomerData, customerData }) => {
  const { id = '-', email = '-', phoneNumber = '-' } = customerData
  const dispatch = useDispatch()
  const [isMobileDetailsModalOpen, setIsMobileDetailsModalOpen] = useState(false)

  const handleSubmit = async (data) => {
    dispatch(updateCustomer({ id, payload: data }))
    setCustomerData({
      ...customerData,
      ...data,
    })
  }

  const openMobileModal = () => {
    setIsMobileDetailsModalOpen(true)
  }

  const initialValues = {
    email,
    phoneNumber,
  }

  return (
    <FormProvider
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values: formValues, resetForm }) => {
        const isFormEdited = checkAreObjectsTheSame(formValues, initialValues)

        return (
          <Form>
            <ContactDetailsCard
              isLoading={isLoading}
              style={s('mb-8')}
              isFormEdited={isFormEdited}
              resetForm={resetForm}
              isMobileModalOpened={isMobileDetailsModalOpen}
              openMobileModal={openMobileModal}
            />

            <ContactDetailsMobile
              isOpen={isMobileDetailsModalOpen}
              setIsOpen={setIsMobileDetailsModalOpen}
              style={s('mb-8', { backgroundColor: '#F9C2E8' })}
              isLoading={isLoading}
            />
          </Form>
        )
      }}
    </FormProvider>
  )
}
