import { format } from 'date-fns'

export const safelyParseDate = (
  date,
  formatString = 'dd MMM yyyy',
  placeholder = '-'
) => {
  try {
    return format(new Date(date), formatString)
  } catch (error) {
    return placeholder
  }
}
