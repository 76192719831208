import React from 'react'
import { useSelector } from 'react-redux'
import { LoggedOutButton, handleSignOut, UpdatePassword } from '@vega/components'
import { styled, s } from '@vega/styled/v2'
import { larger, Row, Col } from '@vega/styled'
import { PersonalDetailsCard } from './PersonalDetailsCard'
import { ContactDetailsCard } from './ContactDetailsCard'
import { formattedProfile } from '@vega/redux.profile'

const Container = styled.div(
  s('relative flex flex-column bg-primary-background h-full w-full pb-12 px-6', {
    overflowY: 'auto',
  }),
  larger('lg')(s('px-0'))
)

export const Header = styled.div(
  s('flex flex-column py-8 w-full mx-a'),
  larger('lg')(s('px-8')),
  ({ isLargeScreen }) => isLargeScreen && s('mb-10')
)
const Title = styled.h2(
  s('m-0 font-bold text-3xl text-grey-700 text-primary', {
    fontFamily: 'Sohne',
    letterSpacing: '-0.01em',
  })
)

const Subtitle = styled.span(s('text-primary font-semibold text-xl inline-block mb-2'))

const Body = styled.div(s('flex justify-center'), larger('lg')(s('px-1/5')))

const ContentContainer = styled.div(
  s('flex flex-column'),
  larger('lg')({ maxWidth: 530 })
)

export const AccountSettings = () => {
  const profile = useSelector(formattedProfile)

  return (
    <Container>
      <Header>
        <Row justify="center" style={s('items-center')}>
          <Col span={24} lg={16}>
            <Title>Account Settings</Title>
          </Col>
          <Col span={0} lg={5}>
            <LoggedOutButton onClick={handleSignOut} containerStyle={s('bg-secondary')}>
              Log Out
            </LoggedOutButton>
          </Col>
        </Row>
      </Header>

      <Body>
        <ContentContainer>
          <Subtitle className="sohne">My Personal Details</Subtitle>
          <PersonalDetailsCard style={s('mb-8')} {...profile} />

          <Subtitle className="sohne">Contact Details</Subtitle>
          <ContactDetailsCard style={s('mb-8')} {...profile} />

          <Subtitle className="sohne" style={s('mt-8')}>
            Password
          </Subtitle>
          <UpdatePassword email={profile?.email} />
        </ContentContainer>
      </Body>
    </Container>
  )
}
