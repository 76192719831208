import React, { useState } from 'react'
import { Item } from '@react-stately/collections'
import { styled, s } from '@vega/styled/v2'
import { Button as ButtonBase, Menu } from '@vega/components'
import { ReactComponent as ResetIcon } from '@vega/components/src/assets/images/refresh.svg'

import { entityOptions } from './filterOptions'

const Container = styled.div(s('flex justify-between', { gap: 10 }))

const Button = styled(ButtonBase)(
  s(
    'bg-white border-solid border-1 border-grey-200 py-3 px-4 text-sm text-grey-600 rounded-lg'
  )
)

const EntityFilter = styled(Menu)(
  s('', {
    minWidth: '195px',
    maxWidth: '195px',
  })
)

const FiltersBar = ({ updateSearchProps, setSearchFilter }) => {
  const [entityTypeFilter, setEntityTypeFilter] = useState({
    key: 'all',
    label: 'Entity Type',
  })

  function handleResetFilters() {
    updateSearchProps({
      filters: null,
      sorting: null,
      searchTerm: null,
    })
    setEntityTypeFilter({
      key: 'all',
      label: 'Entity Type',
    })
    setSearchFilter({
      key: 'name',
      label: 'Entity Name',
      searchLabel: 'Search entity name',
    })
    document.getElementById('searchTerm').value = ''
  }

  const updateEntityFilter = (targetFilter) => (value) => {
    updateSearchProps({ filters: { [targetFilter]: value } })

    const updateEntityFilterObject = entityOptions.find(
      (filter) => filter.key === value
    )
    setEntityTypeFilter(updateEntityFilterObject)
  }

  return (
    <Container>
      <EntityFilter
        label={entityTypeFilter.label || 'Entity Type'}
        aria-label="entity type"
        onAction={updateEntityFilter('type')}
      >
        {entityOptions.map((option) => (
          <Item key={option.key} value={option.key}>
            {option.label}
          </Item>
        ))}
      </EntityFilter>

      <Button
        StartIcon={<ResetIcon style={s('w-1 h-1 text-grey-400 mr-1')} />}
        onClick={handleResetFilters}
      >
        Reset
      </Button>
    </Container>
  )
}

export default FiltersBar
