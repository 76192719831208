import React from 'react'
import { Row, Col } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import checkCircle from '@vega/components/src/assets/images/check-circle.svg'
import { MortgageMap } from 'features/admin/homePage/mortgageMap/MortgageMap'
import { Breakdowns } from 'features/admin/homePage/breakdowns/Breakdowns'
import { ReactComponent as DownArrow } from '@vega/components/src/assets/images/stockArrowDown.svg'
import { ReactComponent as UpArrow } from '@vega/components/src/assets/images/stockArrowTop.svg'
import { pipe } from '@solta/ramda-extra'
import stop from '@vega/components/src/assets/images/stop.svg'
import { calculatePrevMonth } from 'features/admin/homePage/utils/calculatePrevMonth'

const Root = styled.div(s('flex flex-column flex-1 mx-8'))
const Column = styled.div(s('flex flex-column'))
const Title = styled.h3(s('p-0 m-0 font-semibold text-xl mt-0 ml-0 text-primary'))
const SubTitle = styled.h3(s('p-0 m-0 font-normal text-lg text-grey-600'))
const Space = styled.div(s('bg-green-100 flex my-4 justify-between', { gap: 16 }))
const PercentageChange = styled.span(s('text-xl text-error-700 mr-1'))
const Month = styled.span(s('text-grey-700 font-semibold'))
const Container = styled.div(
  s('relative', {
    width: '100%',
    height: '100%',
  })
)
const OverviewContainer = styled.div(
  s('bg-white rounded-lg px-4', {
    width: '100%',
    height: '60%',
    boxShadow:
      '0px 2px 4px -1px rgba(21, 61, 58, 0.06), 0px 4px 6px -1px rgba(21, 61, 58, 0.1)',
  })
)
const Img = styled.img(
  s('ml-a mr-a block w-1/2 h-1/2 self-center ', {
    display: 'block',
  })
)
const PendingAndApprovedContainer = styled.div(
  s('flex justify-between bg-grey-lightest rounded-lg p-3 mt-4 w-full', {
    '@media (max-width: 1500px)': s('flex-column'),
  })
)

const DisplayContainer = styled.div(
  s('bg-white mx-2 w-full rounded-lg p-4', {
    height: 116,
  })
)

const getChangesStatus = (changes) => {
  if (changes === 'N/A') return 'notApplicable'
  if (changes > 0) return 'positive'
  if (changes < 0) return 'negative'
  return 'unchanged'
}

const CircleContainer = styled.div(
  s('bg-secondary justify-center flex', {
    width: 36,
    height: 36,
    borderRadius: 50,
  })
)

const changesValueStylesByStatus = {
  positive: { marginRight: 1, color: '#009F80' },
  negative: s('text-accent-red', { marginRight: 1 }),
  unchanged: s('text-grey-500'),
  notApplicable: s('text-grey-500'),
}

const arrowIconByChangesStatus = {
  positive: <UpArrow style={{ color: '#009F80' }} />,
  negative: <DownArrow style={s('text-accent-red mr-2')} />,
}

const monthNameByNumber = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}

const defaultNaNToZero = (v) => {
  if (Number.isNaN(v) || v === 'NaN') return 0
  return v
}

const defaultInfiniteValueToInapplicable = (v) => {
  if (v === Infinity) return 'N/A'
  return v
}

function MortgageOverview({
  totalNumberOfLoans,
  pendingLoans,
  approvalLoans,
  totalValueOfLoans,
  pendingPercentageChanges,
  approvalPercentageChanges,
  year,
  month,
  loanPurposeBreakdown,
  mortgageCountByLocation,
}) {
  const formattedPendingPercentageChanges = pipe(
    defaultNaNToZero,
    defaultInfiniteValueToInapplicable
  )(pendingPercentageChanges)

  const formattedApprovalPercentageChanges = pipe(
    defaultNaNToZero,
    defaultInfiniteValueToInapplicable
  )(approvalPercentageChanges)

  const showChangesPercentSign =
    (formattedPendingPercentageChanges && formattedApprovalPercentageChanges) !== 'N/A'

  const pendingChangesStatus = getChangesStatus(formattedPendingPercentageChanges)

  const approvalChangesStatus = getChangesStatus(formattedApprovalPercentageChanges)

  const { prevMonth } = calculatePrevMonth(year, month)

  const defaultNaNToDash = (v) => {
    if (Number.isNaN(v) || v === 'NaN' || v === undefined) return '-'

    return `${v}`
  }

  return (
    <Root>
      <OverviewContainer>
        <Row style={s('items-center my-6 mx-0 justify-between ')}>
          <Row style={s('items-center mx-1 justify-between')}>
            <Title className="sohne" style={s('text-primary')}>
              Mortgage Accounts Overview
            </Title>
          </Row>

          <PendingAndApprovedContainer>
            <DisplayContainer
              style={s('bg-white rounded-lg p-4', {
                boxShadow:
                  '0px 2px 4px -1px rgba(21, 61, 58, 0.06), 0px 4px 6px -1px rgba(21, 61, 58, 0.1)',
              })}
            >
              <Row style={s('items-start justify-between')}>
                <Row style={s('mx-2')}>
                  <CircleContainer>
                    <Img src={checkCircle} />
                  </CircleContainer>

                  <Col style={s('items-start mx-2 justify-start')}>
                    <SubTitle>Pending loans</SubTitle>
                    <Title className="sohne" style={s('text-4xl')}>
                      {defaultNaNToDash(pendingLoans)}
                    </Title>
                  </Col>
                </Row>
                <Column style={s('items-end mr-4')}>
                  <Row style={s('justify-end items-center')}>
                    <PercentageChange
                      style={s(
                        'mb-1',
                        changesValueStylesByStatus[pendingChangesStatus]
                      )}
                    >
                      {formattedPendingPercentageChanges}
                      {showChangesPercentSign && '%'}
                    </PercentageChange>
                    {arrowIconByChangesStatus[pendingChangesStatus]}
                  </Row>
                  <span style={s('text-grey-500', { fontSize: 10 })}>
                    Compared to <Month>{monthNameByNumber[prevMonth]}</Month>
                  </span>
                </Column>
              </Row>
            </DisplayContainer>

            <DisplayContainer style={s('bg-grey-lightest')}>
              <Row style={s('items-start justify-between')}>
                <Row style={s('mx-2')}>
                  <CircleContainer style={s('bg-grey-200')}>
                    <Img src={stop} />
                  </CircleContainer>

                  <Col style={s('items-start mx-2 justify-between')}>
                    <SubTitle>Approved loans</SubTitle>
                    <Title className="sohne" style={s('text-4xl')}>
                      {defaultNaNToDash(approvalLoans)}
                    </Title>
                  </Col>
                </Row>
                <Column style={s('items-end mr-4')}>
                  <Row style={s('justify-end items-center')}>
                    <PercentageChange
                      style={s(
                        'mb-1',
                        changesValueStylesByStatus[approvalChangesStatus]
                      )}
                    >
                      {formattedApprovalPercentageChanges}
                      {showChangesPercentSign && '%'}
                    </PercentageChange>
                    {arrowIconByChangesStatus[approvalChangesStatus]}
                  </Row>
                  <span style={s('text-grey-500', { fontSize: 10 })}>
                    Compared to <Month>{monthNameByNumber[prevMonth]}</Month>
                  </span>
                </Column>
              </Row>
            </DisplayContainer>
          </PendingAndApprovedContainer>

          <Col style={s('ml-4 items-start justify-between w-full')}>
            <Row style={s('items-start justify-between ')}>
              <Col span={10}>
                <SubTitle style={s('font-normal text-lg')}>All loans</SubTitle>
                <Title className="sohne" style={s('text-4xl my-4')}>
                  {defaultNaNToDash(totalNumberOfLoans)}
                </Title>

                <SubTitle style={s('font-normal mt-10 text-lg')}>
                  Total value of loans
                </SubTitle>
                <Title className="sohne" style={s('text-4xl my-2')}>
                  ${defaultNaNToDash(totalValueOfLoans)}
                </Title>
              </Col>

              <Col span={12}>
                <Container>
                  <Title className="sohne" style={s('text-grey-900 p-0 font-medium')}>
                    Loan purpose
                  </Title>

                  <Breakdowns loanPurposeBreakdown={loanPurposeBreakdown} />
                </Container>
              </Col>
            </Row>
            <Space />
            <Title className="sohne" style={s('p-0 ml-0 font-medium text-grey-900')}>
              Mortgage Map Location
            </Title>
            <Row style={s('justify-center')}>
              <MortgageMap mortgageCountByLocation={mortgageCountByLocation} />
            </Row>
          </Col>
        </Row>
      </OverviewContainer>
    </Root>
  )
}

export default MortgageOverview
