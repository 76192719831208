import { anyPass, isNaN, isNilOrEmpty, isNotNilOrEmpty, or } from '@solta/ramda-extra'
import { AuthComponent } from './zoid'
import { ALPHABETICAL_SORT_ORDER } from '@neo/constants'

const validSortingValues = Object.values(ALPHABETICAL_SORT_ORDER)

export const refreshAuthSession = () => {
  return new Promise((resolve) => {
    AuthComponent({
      onAuthSessionRefreshed: () => {
        setTimeout(resolve, 0)
      },
    }).render('#root')
  })
}

class EnhancedURLSearchParams extends URLSearchParams {
  /**
   * @param {string} paramName
   * @param {string[]} values
   */
  appendArray(paramName, values) {
    for (let i = 0; i < values.length; i += 1) {
      // eslint-disable-next-line no-continue
      if (isNilOrEmpty(values[i])) continue
      this.append(paramName, values[i])
    }
  }
}

/**
 * @type {typeof import('index').QueryBuilder}
 */
export const QueryBuilder = function () {
  /** @private */
  const searchParams = new EnhancedURLSearchParams()

  return {
    setQ(q) {
      if (isNilOrEmpty(q)) return this

      searchParams.append('q', q)

      return this
    },

    setFilters(filters) {
      if (isNilOrEmpty(filters)) return this

      Object.entries(filters).forEach(([filterName, value]) => {
        if (isNilOrEmpty(value)) return

        if (Array.isArray(value))
          searchParams.appendArray(`filter[${filterName}]`, value)
        else searchParams.append(`filter[${filterName}]`, value)
      })

      return this
    },

    setSorting(sorting) {
      if (isNilOrEmpty(sorting)) return this

      Object.entries(sorting).forEach(([sortName, sortValue]) => {
        if (isNilOrEmpty(sortValue)) return

        if (!validSortingValues.includes(sortValue)) return

        searchParams.append(`sorting[${sortName}]`, sortValue)
      })

      return this
    },

    setParam(paramName, value) {
      if (or(isNilOrEmpty(paramName), isNilOrEmpty(value))) return this

      if (Array.isArray(value)) {
        searchParams.appendArray(paramName, value)
        return this
      }

      searchParams.append(paramName, value)

      return this
    },

    setPaging({ start: skip, limit }) {
      searchParams.append(
        'skip',
        anyPass([isNaN, isNilOrEmpty])(skip) ? '0' : skip.toString()
      )
      if (isNotNilOrEmpty(limit)) searchParams.append('limit', limit.toString())

      return this
    },

    build() {
      return searchParams.toString()
    },
  }
}

export { AuthComponent }
