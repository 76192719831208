import React from 'react'
import { Row, Col, larger } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { DataDisplay } from '@vega/components/src/v2'
import { DottedCircleLoader } from '@vega/components'
import { ReactComponent as ChevronDown } from '@vega/components/src/assets/images/chevron-down.svg'
import { formatCurrency, formatPercentage } from 'utils/formatter'

import { ExpandedLoanBox } from './ExpandedLoanBox'

const Container = styled.div(s('flex flex-column mt-3 bg-white shadow-sm rounded-lg'))
const TopContainer = styled.div(s('px-8 py-8 '), larger('lg')(s('px-6')))

const TitleContainer = styled.div(s('flex justify-between items-center mt-9 '))
const Title = styled.div(s('text-xl font-semibold text-primary', { lineHeight: 1.5 }))

const ExpandButton = styled.div(
  s('px-8 text-center text-sm font-semibold text-primary py-4'),
  { background: 'rgba(241, 241, 232, 0.58)' },
  { cursor: 'pointer' },
  larger('lg')(s('px-6'))
)

const ChevronDownIcon = styled(ChevronDown)(s('ml-2'), {
  cursor: 'pointer',
  path: { stroke: s('text-primry').color },
})

export const LoanCard = ({
  isLoading = true,
  loan = { loanLimit: '-', balance: '-' },
  product = {},
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  if (isLoading)
    return (
      <>
        <TitleContainer>
          <Title className="sohne">Overview</Title>
        </TitleContainer>
        <Container style={s('mt-3 h-12 flex items-center justify-center')}>
          <DottedCircleLoader size="md" />
        </Container>
      </>
    )

  return (
    <>
      <TitleContainer>
        <Title className="sohne">Overview</Title>
      </TitleContainer>

      {isExpanded ? (
        <Container>
          <TopContainer>
            <Row gutter={[24, 2]}>
              <Col span={12} sm={9}>
                <DataDisplay
                  label={'Loan Balance'}
                  value={formatCurrency(loan?.balance, 2)}
                />
              </Col>
              <Col span={24} sm={6}>
                <DataDisplay
                  label={'Interest Rate'}
                  value={formatPercentage(product?.ongoingInterestRate)}
                />
              </Col>
            </Row>
          </TopContainer>

          <ExpandedLoanBox product={product} onClick={() => setIsExpanded(false)} />
        </Container>
      ) : (
        <Container>
          <TopContainer>
            <Row gutter={[24, 2]}>
              <Col span={12} sm={9}>
                <DataDisplay
                  label={'Loan Balance'}
                  value={formatCurrency(loan?.balance, 2)}
                />
              </Col>
              <Col span={24} sm={6}>
                <DataDisplay
                  label={'Interest Rate'}
                  value={formatPercentage(product?.ongoingInterestRate)}
                />
              </Col>
            </Row>
          </TopContainer>
          <ExpandButton onClick={() => setIsExpanded(true)}>
            <Row style={s('justify-center')}>
              <div>View Details</div>
              <ChevronDownIcon width={20} height={20} />
            </Row>
          </ExpandButton>
        </Container>
      )}
    </>
  )
}
