import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { styled, s } from '@vega/styled/v2'
import { Formik as FormProvider, Form } from 'formik'
import {
  Button as ButtonBase,
  SearchBar as SearchBarBase,
  DottedCircleLoader,
} from '@vega/components'
import { Table } from '@vega/components/src/v2'
import { ReactComponent as DownloadIcon } from '@vega/components/src/assets/images/download.svg'
import { ReactComponent as UploadIcon } from '@vega/components/src/assets/images/upload.svg'
import { Columns, Rows } from './TableFormat'
import { useTableFetching } from 'hooks'
import {
  fetchPayments,
  selectTotal,
  selectAllPayments,
  sendEmail,
} from 'modules/payment'

import FiltersBar from './FiltersBar'
import { BulkExport } from './BulkExport'

const Root = styled.div(s('flex flex-column flex-1 mx-8'))
const Row = styled.div(s('flex flex-row pb-4 pt-8 justify-between'))
const PaymentDataButtonsContainer = styled.div(s('flex flex-row justify-between'))
const Title = styled.h3(s('p-0 m-0 font-semibold text-primary', { fontSize: 28 }))
const SearchAndFiltersContainer = styled.div(
  s('flex my-4 justify-between', { gap: 16 })
)

const ExportButton = styled(ButtonBase)(s('text-sm rounded-lg py-3 mr-3'))
const ImportButton = styled(ButtonBase)(s('text-sm rounded-lg py-3'))

const SearchBar = styled(SearchBarBase)(s('bg-white rounded-lg'))
const SearchForm = styled(Form)(s('w-full'))
const TableContainer = styled.div(s('h-full mt-4', { overflow: 'hidden' }))

function Overview() {
  const [isBulkExportModalOpened, setIsBulkExportModalOpened] = useState(false)
  const [hasFirstFetchFulfilled, setHasFirstFetchFulfilled] = useState(false)
  const dispatch = useDispatch()

  const total = useSelector(selectTotal)
  const payments = useSelector(selectAllPayments)

  const fetchData = useCallback(
    ({ searchParams = {}, pageIndex = 0 }) =>
      dispatch(fetchPayments({ searchParams, pageIndex })),
    [dispatch]
  )

  const {
    searchProps: { searchParams },
    updateSearchProps,
    paginationProps,
    isFetchingData,
  } = useTableFetching(total, fetchData)

  const sendOverdueEmail = async (paymentId) => {
    await dispatch(sendEmail(paymentId))
    fetchData({
      searchParams: { limit: searchParams.limit },
      pageIndex: 0,
    })
  }
  React.useEffect(() => {
    async function fetchLegalEntitiesOnMount() {
      await fetchData({
        searchParams: { limit: searchParams.limit },
        pageIndex: 0,
      })
      setHasFirstFetchFulfilled(true)
    }

    fetchLegalEntitiesOnMount()
  }, [dispatch, fetchData, searchParams.limit])

  const columns = Columns()
  const data = Rows(payments, sendOverdueEmail)

  const onTextSearch = ({ searchTerm }) => {
    updateSearchProps({
      searchTerm,
    })
  }

  if (!hasFirstFetchFulfilled) {
    return (
      <div style={s('flex justify-center items-center h-full w-full')}>
        <DottedCircleLoader size="lg" />
      </div>
    )
  }

  return (
    <Root>
      <Row>
        <Title className="sohne">Payment Centre</Title>
        <PaymentDataButtonsContainer>
          <ExportButton onClick={() => setIsBulkExportModalOpened(true)}>
            <DownloadIcon style={s('w-1 h-auto')} />
            Bulk export payments data
          </ExportButton>

          <ImportButton>
            {' '}
            <UploadIcon style={s('w-1 h-auto')} />
            Bulk import payments data
          </ImportButton>
        </PaymentDataButtonsContainer>
      </Row>

      <SearchAndFiltersContainer>
        <FormProvider
          enableReinitialize={false}
          initialValues={{ searchTerm: '' }}
          onSubmit={onTextSearch}
        >
          {({ values, handleChange }) => {
            return (
              <SearchForm>
                <SearchBar
                  placeholder="Search customer name or anything"
                  onInputChange={handleChange}
                  value={values.searchTerm}
                  name="searchTerm"
                  id="searchTerm"
                />
              </SearchForm>
            )
          }}
        </FormProvider>

        <FiltersBar
          updateSearchProps={updateSearchProps}
          filters={searchParams.filters}
        />
      </SearchAndFiltersContainer>
      <TableContainer>
        <Table
          columns={columns}
          data={data}
          tBodyStyle={s('bg-white')}
          containerStyle={s('bg-white rounded-lg shadow-md flex-1')}
          paginationProps={paginationProps}
          style={s('pb-0 m-0')}
          tableHeaderRowStyles={s(
            'bg-white border-solid border-0 border-b-1 border-grey-200'
          )}
          loaderLabel="Fetching payments"
          loading={isFetchingData}
        />
      </TableContainer>

      <BulkExport
        isOpen={isBulkExportModalOpened}
        setIsOpen={setIsBulkExportModalOpened}
      />
    </Root>
  )
}

export default Overview
