/* eslint-disable complexity */
import React, { useState } from 'react'

import { styled, s } from '@vega/styled/v2'
import { ReactComponent as CheckIconBase } from '@vega/components/src/assets/images/check-circle.svg'

import { formatDateString } from 'utils/date'
import { formatCurrency } from 'utils/formatter'

import {
  PaymentTypeDisplayNameMapper,
  StatusDisplayNameMapper,
} from '../../displayNameMappers'

import { ExpandedListItem } from './ExpandedListItem'

const Row = styled.div(
  s(
    'flex flex-row border-solid border-0 border-b-1 border-grey-200 py-4 px-5 justify-between'
  )
)

const Col = styled.div(s('flex-column self-center'))

const DateText = styled.div(s('text-xs text-grey-700', { lineHeight: 1.5 }))

const TransactionType = styled.div(
  s('text-base font-medium text-grey-900 my-1', { lineHeight: 1.5 })
)

const Status = styled.div(
  s('flex flex-row items-center text-sm font-semibold text-grey-700', {
    lineHeight: 1.5,
  })
)

const Amount = styled.div(s('text-lg font-semibold text-grey-700', { lineHeight: 1.5 }))

const CheckIcon = styled(CheckIconBase)({
  path: { stroke: s('text-secondary-green').color },
  marginRight: 6,
})

const ListItem = ({ transaction }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { recordedAt, paymentType, status, amount } = transaction ?? {}

  return (
    <>
      {isExpanded ? (
        <ExpandedListItem
          transaction={transaction}
          onClick={() => setIsExpanded(false)}
        />
      ) : (
        <Row onClick={() => setIsExpanded(true)}>
          <Col>
            <DateText>{formatDateString(recordedAt)}</DateText>

            <TransactionType>
              {PaymentTypeDisplayNameMapper[paymentType] || '-'}
            </TransactionType>

            <Status>
              {transaction?.status === 'paid' && <CheckIcon width={16} height={16} />}
              {StatusDisplayNameMapper[status] || '-'}
            </Status>
          </Col>

          <Col>
            <Amount>{formatCurrency(amount, 2)}</Amount>
          </Col>
        </Row>
      )}
    </>
  )
}

export { ListItem }
