/* eslint-disable camelcase */

export const PaymentTypeDisplayNameMapper = {
  // TODO: Recurring payment can be monthly, weekly etc.
  recurring_payment: 'Monthly payment',
  late_fee: 'Late fee',
  lump_sum: 'Lump sum',
  other: 'Other',
  interest: 'Interest',
}

export const InterestLoanTypeDisplayNameMapper = {
  interest_only: 'Interest Only',
  principal_and_interest: 'Principal & Interest',
  interest_in_advance: 'Interest In Advance',
  line_of_credit: 'Line Of Credit',
}

export const StatusDisplayNameMapper = {
  paid: 'Paid',
  accumulated: 'Accumulated',
}
