const routes = /** @type {const} */ ({
  admin: {
    root: '/admin',
    homepage: { root: '/admin/homepage' },
    products: {
      root: '/admin/products',
      overview: '/admin/products/overview',
      newProduct: '/admin/products/new-product',
      productDetails: '/admin/products/:id',
    },
    customers: {
      root: '/admin/customers',
      overview: '/admin/customers/overview',
      customerDetails: '/admin/customers/:id',
      loanAccounts: '/admin/customers/:id/loans',
      paymentDetails: '/admin/customers/:id/payment-details',
      accountLink: '/admin/customers/:id/account-link',
    },
    paymentCenter: {
      root: '/admin/payment-center',
      overview: '/admin/payment-center/overview',
    },
    loans: {
      root: '/admin/loans',
      overview: '/admin/loans/overview',
      accountDetails: '/admin/loans/:id',
      securitiesDetails: '/admin/loans/:id/security-details',
      entityDetails: {
        root: '/admin/loans/:id/entities',
        individuals: '/admin/loans/:id/entities/individuals/:entityId',
        trusts: '/admin/loans/:id/entities/trusts',
        businesses: '/admin/loans/:id/entities/businesses',
      },
      propertyDetails: '/admin/loans/:id/property-details',
      newLedgerEntry: '/admin/loans/:id/new-transaction',
    },
    reconciledPaymentsCenter: {
      root: '/admin/reconciled-payment-center',
      overview: '/admin/reconciled-payment-center/overview',
    },
    reports: {
      root: '/admin/reports',
    },
    account: { root: '/admin/account' },
  },

  customer: {
    root: '/customer',
    homepage: '/customer/homepage',
    myLoans: {
      root: '/customer/my-loans',
      loanOverview: '/customer/my-loans/:id',
      groupOverview: '/customer/my-loans/groups/:id',
    },
    paymentSchedule: {
      root: '/customer/payment-centre',
      allLoans: '/customer/payment-centre/all-loans',
      loanPaymentDetail: '/customer/payment-centre/:id',
      upcomingPayments: '/customer/payment-centre/upcoming-payments',
      paidPayments: '/customer/payment-centre/paid-payments',
    },
    account: {
      root: '/customer/account',
      personalDetails: '/customer/account/personal-details',
      paymentDetails: '/customer/account/payment-details',
      editPaymentDetails: '/customer/account/edit-payment-details',
    },
  },
})

export { routes }
