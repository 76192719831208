import React from 'react'
import { useSelector } from 'react-redux'
import { s } from '@vega/styled/v2'
import { selectCustomerBroker } from 'modules/customer'

import { insertSeparatorsToString } from 'utils/formatter'

export const BrokerInfoCard = () => {
  const broker = useSelector(selectCustomerBroker)

  return (
    <div style={s('bg-white rounded-lg p-6 mb-12')}>
      <h2 style={s('m-0 block text-primary font-bold text-xl mb-2')}>
        {broker?.name || '-'}
      </h2>

      <span style={s('block text-primary mb-2')}>
        {insertSeparatorsToString(broker?.mobile, ' ', '### ### ####')}
      </span>

      <span style={s('block text-primary')}>
        {broker?.email || '-'}
      </span>
    </div>
  )
}
