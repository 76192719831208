import React, { useEffect } from 'react'
import { isNotNilOrEmpty, isNilOrEmpty } from '@solta/ramda-extra'
import { selectIsMortgageAccountUpdated } from 'modules/mortgageAccounts'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { s } from '@vega/styled/v2'
import {
  fetchCustomerLoans,
  selectIsFetchCustomerLoansPending,
  selectCustomerLoans,
  selectCurrentUserCustomerId,
} from 'modules/customer'
import { LoanOverview } from './LoanOverview'

import { routes } from 'routes'
import { Loading } from '@vega/components'

const myLoansRoute = routes.customer.myLoans

const MyLoansRouter = () => {
  const dispatch = useDispatch()

  const customerId = useSelector(selectCurrentUserCustomerId)
  const loans = useSelector(selectCustomerLoans)
  const isMortgageAccountUpdated = useSelector(selectIsMortgageAccountUpdated)

  const isFetchingCustomerLoans = useSelector(selectIsFetchCustomerLoansPending)

  useEffect(() => {
    if (isNotNilOrEmpty(customerId)) {
      dispatch(fetchCustomerLoans({ customerId }))
    }
  }, [dispatch, customerId, isMortgageAccountUpdated])

  const defaultDisplayLoan = loans?.[0] || {}
  const isPageReadyToBeDisplayed = isNotNilOrEmpty(defaultDisplayLoan)

  // TODO: Handle situation where defaultDisplayLoanGroup is nil or undefined (no loan group)
  // Then we should display error page
  if (isNilOrEmpty(customerId) || isFetchingCustomerLoans || !isPageReadyToBeDisplayed) {
    return <Loading />
  }

  return (
    <div
      style={s(
        'relative flex flex-column h-full w-full items-center',
        {overflowY: 'auto'}
      )}
    >
      <Switch>
        <Route exact path={myLoansRoute.loanOverview} component={LoanOverview} />
        <Redirect to={`${myLoansRoute.root}/${defaultDisplayLoan?._id}`} />
      </Switch>
    </div>
  )
}

export default MyLoansRouter
