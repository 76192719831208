import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ReactComponent as HamburgerIcon } from '@vega/components/src/assets/images/hamburger.svg'
// import { ReactComponent as DoubleLeftArrowsIcon } from '@vega/components/src/assets/images/double-left-arrows.svg'
import neoLogo from '@vega/components/src/assets/images/neo-logo.png'
import { styled, s } from '@vega/styled/v2'
import { setTemporaryNavDrawerState, selectTopBar } from 'modules/ui'
import { Button as ButtonBase, handleSignOut } from '@vega/components'
import { ReactComponent as Exit } from '@vega/components/src/assets/images/exit.svg'

import { mobileTopBarHeight } from 'styles'

import { routes } from 'routes'

const CustomerRoutes = routes.customer

const LoggedOutBtn = styled((props) => (
  <ButtonBase
    containerStyle={s(
      'bg-white border-transparent rounded-lg text-primary font-semibold',
      { borderRadius: 6 }
    )}
    {...props}
  />
))()

const VerticalLine = styled.div(s('h-2 bg-grey-200', { width: 1 }))

const ImageContainer = styled.div(s('', { width: 55, transform: 'translateY(3px)' }))

const LeftContainer = styled.div(s('flex items-center justify-between', { width: 110 }))
const Container = styled.div(
  s(
    'fixed pin-tl-0 w-full z-2 flex items-center justify-between bg-blue-200 px-6 bg-green-50'
  ),
  {
    height: mobileTopBarHeight,
  },
  ({ isHomepage, customerColour }) =>
    isHomepage
      ? {
          backgroundColor: customerColour,
        }
      : s('bg-primary-background')
)

export const TopBar = ({ homeRoute = '/', customerColour }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentLocation = useLocation()
  const { isOpen } = useSelector(selectTopBar)
  let isHomepage = false
  if (currentLocation.pathname === CustomerRoutes.homepage) {
    isHomepage = true
  }

  const openTemporaryNavDrawer = () =>
    dispatch(setTemporaryNavDrawerState({ isOpen: true }))

  const toHomePage = () => history.push(homeRoute)

  if (!isOpen) return null

  return (
    <Container isHomepage={isHomepage} customerColour={customerColour}>
      <LeftContainer>
        <HamburgerIcon onClick={openTemporaryNavDrawer} style={{ cursor: 'pointer' }} />

        <VerticalLine />

        <ImageContainer onClick={toHomePage} style={{ cursor: 'pointer' }}>
          <img src={neoLogo} style={s('w-full')} />
        </ImageContainer>
      </LeftContainer>

      <LoggedOutBtn onClick={handleSignOut}>
        <Exit />
      </LoggedOutBtn>
    </Container>
  )
}
