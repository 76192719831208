import React from 'react'

import { styled, s } from '@vega/styled'
import { Link, useLocation } from 'react-router-dom'
import { prop, includes } from '@solta/ramda-extra'

const Container = styled('div', { shouldForwardProp: (p) => p !== 'containerStyle' })(
  s(
    'px-4 flex justify-between items-center bg-green-50 border-0 border-b-1 border-solid border-grey-200'
  ),
  prop('containerStyle')
)

const TabsContainer = styled.div(s('flex'))
const Tab = styled(Link)(
  s(
    'relative flex justify-center mx-4 py-1 pb-2 no-decoration text-grey-400 text-base',
    {
      cursor: 'pointer',
    }
  ),
  ({ selected }) => selected && s('text-grey-800')
)
const BottomLine = styled.div(
  s('absolute pin-bl-0 bg-green-700 w-full', {
    height: '3px',
    borderRadius: '1px 0px 1px 1px',
  })
)

function TopTabNavigator({ routes, containerStyle, testId }) {
  const { pathname } = useLocation()
  const isSelected = (path) => includes(path, pathname)

  return (
    <Container containerStyle={containerStyle}>
      <TabsContainer data-test-id={testId}>
        {routes.map(({ name, path }) => (
          <Tab key={name} to={path} selected={isSelected(path)}>
            {name}
            {isSelected(path) && <BottomLine />}
          </Tab>
        ))}
      </TabsContainer>
    </Container>
  )
}

export default TopTabNavigator
