import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { reportService } from 'apiService'
import { normalizeError } from '@vega/services'

export const fetchReportData = createAsyncThunk(
  'reportData/fetchReportData',
  async (_, { rejectWithValue }) => {
    try {
      return await reportService.fetchBankingDashboardReportData()
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

const reportingSlice = createSlice({
  name: 'reportData',
  initialState: { report: {} },
  extraReducers: {
    [fetchReportData.fulfilled]: (state, action) => {
      state.report = action.payload
    },
  },
})

const { reducer: reportingReducer } = reportingSlice

export { reportingReducer }
