import React from 'react'
import { Container } from '@vega/styled'
import { PaymentDetails } from './PaymentDetails'

const PaymentDetailsView = ({ details }) => {
  // TODO: investigate how payment could be unlinked
  return (
    <Container>
      <PaymentDetails details={details} />
    </Container>
  )
}

export { PaymentDetailsView }
