import React from 'react'
import { mergeDeepRight } from 'ramda'
import { larger } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { DottedCircleLoader } from '@vega/components'
import { CardContainer } from '../common'
import { PreviewDataBlock } from '../PreviewDataBlock'
import { insertSeparatorsToString } from 'utils/formatter'

const BlockContainer = styled.div(
  s('flex flex-column'),
  larger('xl')(s('flex-row justify-between'))
)
const EmailPreviewDataBlock = styled(PreviewDataBlock)(
  s('mb-6'),
  larger('xl')(s('m-0 w-1/2'))
)

const PhoneNumberPreviewDataBlock = styled(PreviewDataBlock)(
  s('w-full'),
  larger('xl')(s('w-1/2'))
)

export const MyBrokerDetailsCard = ({
  isLoading,
  broker = {},
  style,
  ...otherProps
}) => {
  if (isLoading) {
    return (
      <CardContainer
        style={mergeDeepRight(style, s('flex items-center justify-center h-8'))}
        {...otherProps}
      >
        <DottedCircleLoader size="lg" />
      </CardContainer>
    )
  }

  return (
    <CardContainer style={style} {...otherProps}>
      <PreviewDataBlock label="Broker Legal Name" style={s('mb-6')}>
        {broker?.name || '-'}
      </PreviewDataBlock>

      <BlockContainer>
        <EmailPreviewDataBlock label="Email">
          {broker?.email || '-'}
        </EmailPreviewDataBlock>

        <PhoneNumberPreviewDataBlock label="Phone Number">
          {insertSeparatorsToString(broker?.mobile, ' ', '### ### ####')}
        </PhoneNumberPreviewDataBlock>
      </BlockContainer>
    </CardContainer>
  )
}
