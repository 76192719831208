import { prop } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

const selectReportData = prop('reportData')

export const selectReport = createSelector(selectReportData, prop('report'))

export const {
  selectIsPending: selectIsFetchReportDataPending,
} = createAsyncThunkSelectors('reportData/fetchReportData')

const selectdashBoardData = prop('dashBoardData')

export const selectdashBoard = createSelector(selectdashBoardData, prop('dashboard'))

export const {
  selectIsPending: selectIsFetchDashboardDataPending,
} = createAsyncThunkSelectors('reportData/fetchDashboardData')
