import { useState, useCallback, useRef } from 'react'
import { mergeDeepRight, noop } from '@solta/ramda-extra'

const defaultSearchProps = {
  searchParams: {
    filters: {},
    sorting: {},
    searchTerm: '',
    limit: 20,
  },
  pageIndex: 0,
}

// This hook depends on redux tool kit & the async thunk underneath
// and the fetchFunc to have dispatch() returned, as we need the promise
// https://redux-toolkit.js.org/api/createAsyncThunk#canceling-while-running
export const usePagination = (
  totalItems,
  searchParams = {},
  fetchFunc = noop,
  initialPageIndex = 0
) => {
  const [pageIndex, setPageIndex] = useState(initialPageIndex)
  const [isChangingPage, setIsChangingPage] = useState(false)
  const prevPromise = useRef()

  const pageCount = Math.ceil(totalItems / searchParams.limit)
  const canNextPage = pageIndex + 1 < pageCount
  const canPreviousPage = pageIndex > 0

  const attemptToFetchData = useCallback(
    async (newPageIndex) => {
      const promise = fetchFunc({ searchParams, pageIndex: newPageIndex })
      // Intended to NOT use the async state pending status but the custom isLoading state, because async state pending status only shows the pending of a request when it sent out and got response in async thunk but we want to cover the mapping finished in extra reducer etc.
      if (isChangingPage) prevPromise.current.abort()
      prevPromise.current = promise

      const { meta } = await promise
      if (meta.aborted) {
        // Don't set loading to false here as the loading state is shared across all the calls and we don't want aborted calls break the current call loading
        return true
      }

      return false
    },
    [fetchFunc, isChangingPage, searchParams]
  )

  const nextPage = useCallback(async () => {
    setIsChangingPage(true)

    const isAborted = await attemptToFetchData(pageIndex + 1)
    if (isAborted) return

    setPageIndex((prev) => prev + 1)
    setIsChangingPage(false)
  }, [attemptToFetchData, pageIndex])

  const previousPage = useCallback(async () => {
    setIsChangingPage(true)

    const isAborted = await attemptToFetchData(pageIndex - 1)
    if (isAborted) return

    setPageIndex((prev) => prev - 1)
    setIsChangingPage(false)
  }, [attemptToFetchData, pageIndex])

  const gotoPage = useCallback(
    async (newPageIndex) => {
      setIsChangingPage(true)

      const isAborted = await attemptToFetchData(newPageIndex)
      if (isAborted) return

      setPageIndex(newPageIndex)
      setIsChangingPage(false)
    },
    [attemptToFetchData]
  )

  return {
    pageIndex,
    nextPage,
    setPageIndex,
    previousPage,
    gotoPage,
    pageCount,
    canNextPage,
    canPreviousPage,
    isChangingPage,
  }
}

const useSearchParams = (initialSearchParams = {}) => {
  const [searchParams, setSearchParams] = useState(
    mergeDeepRight(defaultSearchProps.searchParams, initialSearchParams)
  )

  const updateSearchParams = useCallback(
    (searchParamsUpdateModel) => {
      const newSearchParams = mergeDeepRight(searchParams, searchParamsUpdateModel)
      setSearchParams(newSearchParams)
      return newSearchParams
    },
    [searchParams, setSearchParams]
  )

  return {
    searchParams,
    updateSearchParams,
  }
}

// Please ensure fetchFunc will return a promise
export const useTableFetching = (
  totalItems,
  fetchFunc = noop,
  initialSearchProps = defaultSearchProps
) => {
  const [isSearching, setIsSearching] = useState()
  const { searchParams, updateSearchParams } = useSearchParams(
    initialSearchProps.searchParams
  )
  const paginationProps = usePagination(
    totalItems,
    searchParams,
    fetchFunc,
    initialSearchProps.pageIndex
  )

  const searchProps = {
    searchParams,
    pageIndex: paginationProps.pageIndex,
  }
  const updateSearchProps = useCallback(
    async (searchParamsUpdateModel, newPageIndex = 0) => {
      setIsSearching(true)

      const newSearchParams = updateSearchParams(searchParamsUpdateModel)
      paginationProps.setPageIndex(newPageIndex)

      await fetchFunc({
        searchParams: newSearchParams,
        pageIndex: newPageIndex,
      })

      setIsSearching(false)
    },
    [fetchFunc, paginationProps, updateSearchParams]
  )

  return {
    searchProps,
    updateSearchProps,
    paginationProps,
    isFetchingData: isSearching || paginationProps.isChangingPage,
  }
}
