import { prop } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'

const selectUiState = prop('ui')

const selectPersistentNavDrawer = createSelector(
  selectUiState,
  prop('persistentNavDrawer')
)
const selectIsPersistentNavDrawerOpen = createSelector(
  selectPersistentNavDrawer,
  prop('isOpen')
)

const selectTemporaryNavDrawer = createSelector(
  selectUiState,
  prop('temporaryNavDrawer')
)
const selectIsTemporaryNavDrawerOpen = createSelector(
  selectTemporaryNavDrawer,
  prop('isOpen')
)

const selectTopBar = createSelector(selectUiState, prop('topBar'))

const selectIsSideAccountNavOpen = createSelector(
  selectUiState,
  prop('isSideAccountNavOpen')
)

export {
  selectPersistentNavDrawer,
  selectIsPersistentNavDrawerOpen,
  selectIsTemporaryNavDrawerOpen,
  selectTemporaryNavDrawer,
  selectTopBar,
  selectIsSideAccountNavOpen,
}
