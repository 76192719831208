import React from 'react'
import { s } from '@vega/styled/v2'

import { HelpLinkCard } from './HelpLinkCard'
import { BrokerInfoCard } from './BrokerInfoCard'

export const InfoSideContainer = () => {
  return (
    <div style={s('w-full')}>
      <h3 style={s('text-primary text-xl mb-3', {'font-family': 'Sohne'})}>
        Your Mortgage Adviser
      </h3>
      <BrokerInfoCard />

      <h3 style={s('text-primary text-xl mb-3', {'font-family': 'Sohne'})}>
        Help
      </h3>
      <HelpLinkCard />
    </div>
  )
}
