/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useMemo } from 'react'
import { styled, s } from '@vega/styled/v2'
import { Table } from '@vega/components/src/v2'
import { Tooltip } from '@mui/material'

import { formatDateString } from 'utils/date'
import {
  PaymentTypeDisplayNameMapper,
  StatusDisplayNameMapper,
} from '../../../customer/myAccount/displayNameMappers'
import { formatCurrency } from 'utils/formatter'
import DateRangePicker from 'features/common/OverlayDateRangePicker'

const textEllipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const headerStyleBase = (customStyles) =>
  s('px-4 inline-block text-left text-sm font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s('px-4 inline-block text-sm text-left flex items-center font-semibold border-b-0', {
    lineHeight: '150%',
    ...textEllipsis,
    ...customStyles,
  })

const TextOverflowEllipsisBox = ({ children }) => {
  return (
    <div
      style={s('flex items-center', {
        overflow: 'hidden',
      })}
    >
      <span className="textEllipsis">{children}</span>
    </div>
  )
}

const Title = styled.div(s('text-xl font-semibold text-primary', { lineHeight: 1.5 }))
const TitleContainer = styled.div(s('flex justify-between items-center mt-9 '), {
  verticalAlign: 'top',
})

const TextOverflowEllipsisBoxTooltip = ({ note, children }) => {
  return note ? (
    <Tooltip title={note}>
      <div
        style={s('flex items-center h-full', {
          overflow: 'hidden',
          userSelect: 'none',
        })}
      >
        <span
          style={{
            flexGrow: '1',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {children}
        </span>
      </div>
    </Tooltip>
  ) : (
    <TextOverflowEllipsisBox>{children}</TextOverflowEllipsisBox>
  )
}

function TransactionHistoryTable({ isLoading, transactions = [] }) {
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (row) => formatDateString(row.recordedAt),
        tableHeaderStyles: headerStyleBase({
          width: '20%',
        }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Transaction type',
        accessor: (row) => ({
          value: PaymentTypeDisplayNameMapper[row.paymentType] || 'Loan drawdown',
          note: row.notes,
        }),
        tableHeaderStyles: headerStyleBase({
          width: '25%',
        }),
        tableCellStyles: cellStyleBase({ width: '25%' }),
        Cell: ({ value }) => (
          <TextOverflowEllipsisBoxTooltip note={value?.note}>
            {value.value}
          </TextOverflowEllipsisBoxTooltip>
        ),
      },
      {
        Header: 'Reference',
        accessor: (row) => row.reference || '-',
        tableHeaderStyles: headerStyleBase(
          s('text-right', {
            width: '20%',
          })
        ),
        tableCellStyles: cellStyleBase(s('justify-end', { width: '20%' })),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Amount',
        accessor: (row) => formatCurrency(row.amount, 2),
        tableHeaderStyles: headerStyleBase(
          s('text-right', {
            width: '20%',
          })
        ),
        tableCellStyles: cellStyleBase(s('justify-end', { width: '20%' })),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Status',
        accessor: (row) => StatusDisplayNameMapper[row.status] || '-',
        tableHeaderStyles: headerStyleBase(
          s('text-center', {
            width: '15%',
          })
        ),
        tableCellStyles: cellStyleBase(s('justify-center', { width: '15%' })),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
    ],
    []
  )

  const rows = useMemo(
    () =>
      transactions.map((customer) => {
        const customRowStyle = s('border-solid border-0 border-b-1 border-grey-200', {
          height: 50,
        })
        return { ...customer, customRowStyle }
      }),
    [transactions]
  )

  return (
    <>
      <TitleContainer style={s('mb-2')}>
        <div>
          <Title className="sohne">Transaction History</Title>
        </div>
        <DateRangePicker />
      </TitleContainer>

      <Table
        loading={isLoading}
        loaderLabel="Fetching Transactions"
        data={rows}
        columns={columns}
        tBodyStyle={s('bg-white', { height: 450 })}
        containerStyle={s('bg-white h-a shadow-md')}
        tableHeaderRowStyles={s(
          'bg-white border-solid border-0 border-b-1 border-grey-200'
        )}
        placeholderText={'No Transactions Yet'}
        rootStyle={s('m-0 mb-8 rounded-lg shadow-sm')}
      />
    </>
  )
}

export { TransactionHistoryTable }
