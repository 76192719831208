import React, { useEffect } from 'react'
import {
  useHistory,
  useParams,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as ChevronLeft } from '@vega/components/src/assets/images/chevron-left.svg'
import { Container, s, styled, Row, Col } from '@vega/styled'
import { SectionTitle as SectionTitleBase } from '@vega/components/src/v2'
import { LEGAL_ENTITY } from '@neo/constants'
import {
  fetchLoan,
  selectLoanDetails,
  selectIsFetchMortgageAccountPending,
} from 'modules/mortgageAccounts'
import {
  fetchLegalEntitiesByLoanId,
  selectAllLegalEntities,
  selectIsFetchLegalEntitiesByLoanIdPending,
} from 'modules/legalEntity'
import { routes } from 'routes'
import { IndividualDetails } from './individualDetails'
import { TrustsDetails } from './trustsDetails'
import { BusinessesDetails } from './businessesDetails'
import LegalEntitiesNavigation from './LegalEntitieNavigation'
import { Button, DottedCircleLoader } from '@vega/components'

const {
  TYPE: { COMPANY, TRUST },
} = LEGAL_ENTITY

const loanRoutes = routes.admin.loans

const CustomerName = styled(SectionTitleBase)(s('text-3xl mt-5'))

const BackButton = styled(Button)(
  s('border-transparent bg-transparent p-0 flex flex-row', { cursor: 'pointer' })
)
const BackButtonText = styled(SectionTitleBase)(s('mt-1 text-sm'))

const Scrollable = styled.div(s('flex-1', { overflowY: 'auto', overflowX: 'hidden' }))
const Header = styled.div(s('mt-8 ml-8'))
const ContentContainer = styled.div(s('flex'))

const addRoleToLegalEntity = (entity, loan) => {
  const matchingEntity = loan?.entities?.find(
    ({ legalEntityId }) => legalEntityId === entity.id
  )
  return { ...entity, role: matchingEntity?.role }
}
const enrichLegalEntities = (loan, loanLegalEntities) => {
  return loanLegalEntities.map((entity) => addRoleToLegalEntity(entity, loan))
}

const getUrlByEntityType = (entityType, loanId, legalEntityId) => {
  switch (entityType) {
    case COMPANY:
      return loanRoutes.entityDetails.businesses

    case TRUST:
      return loanRoutes.entityDetails.trusts

    default:
      return loanRoutes.entityDetails.individuals
        ?.replace(':id', loanId)
        ?.replace(':entityId', legalEntityId)
  }
}
const getRedirectUrl = (loanId, entityIdInPathParam, legalEntities = []) => {
  if (entityIdInPathParam) {
    const targetEntity = legalEntities?.find((le) => le?.id === entityIdInPathParam)
    return getUrlByEntityType(targetEntity?.type, loanId, entityIdInPathParam)
  }

  // Get default redirect url
  const defaultEntity = legalEntities?.[0]
  return getUrlByEntityType(defaultEntity?.type, loanId, defaultEntity?.legalEntityId)
}

export function CustomerDetailsRouter() {
  const dispatch = useDispatch()
  const { id: loanId } = useParams()
  const history = useHistory()
  const location = useLocation()

  const loan = useSelector(selectLoanDetails)
  const legalEntities = useSelector(selectAllLegalEntities) || []

  const isFetchingLegalEntities = useSelector(selectIsFetchLegalEntitiesByLoanIdPending)
  const isFetchingLoan = useSelector(selectIsFetchMortgageAccountPending)

  const enrichedTrusts = enrichLegalEntities(
    loan,
    legalEntities.filter((le) => le.type === TRUST)
  )
  const enrichedBusinesses = enrichLegalEntities(
    loan,
    legalEntities.filter((le) => le.type === COMPANY)
  )

  useEffect(() => {
    dispatch(fetchLoan(loanId))
    dispatch(fetchLegalEntitiesByLoanId(loanId))
  }, [dispatch, loanId])

  if (isFetchingLegalEntities || isFetchingLoan)
    return (
      <Container style={s('flex items-center justify-center h-full')}>
        <DottedCircleLoader size="lg" />
      </Container>
    )

  return (
    <Scrollable>
      <Container>
        <Row style={{ marginBottom: 32 }}>
          <Col span={24}>
            <Header>
              <BackButton
                variant="text"
                onClick={() =>
                  history.push(routes.admin.loans.accountDetails.replace(':id', loanId))
                }
              >
                <ChevronLeft />
                <BackButtonText title="Back" />
              </BackButton>

              <CustomerName title="Entities Details" />
            </Header>
          </Col>
        </Row>

        <Row justify="center">
          <Col span={16}>
            <ContentContainer>
              <LegalEntitiesNavigation style={s('mr-4')} />

              <Switch>
                <Route path={loanRoutes.entityDetails.individuals}>
                  <IndividualDetails loan={loan} />
                </Route>

                <Route path={loanRoutes.entityDetails.trusts}>
                  <TrustsDetails trusts={enrichedTrusts} loan={loan} />
                </Route>

                <Route path={loanRoutes.entityDetails.businesses}>
                  <BusinessesDetails businesses={enrichedBusinesses} loan={loan} />
                </Route>

                <Redirect
                  to={getRedirectUrl(loanId, location?.state?.entityId, legalEntities)}
                />
              </Switch>
            </ContentContainer>
          </Col>
        </Row>
      </Container>
    </Scrollable>
  )
}
