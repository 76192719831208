import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Link as LinkBase } from 'react-router-dom'
import { useLocation, useParams } from 'react-router'
import { routes } from 'routes'

const customerRoutes = routes.admin.customers

const NavItemList = styled.ol(
  s(
    'sticky pin-tl-0 text-sm m-0 py-3 px-0 rounded-lg border-1 border-solid border-grey-200 bg-white',
    {
      minWidth: 202,
      listStyleType: 'none',
    }
  )
)

const NavItemTitle = styled.li(
  s('text-sm py-3 pl-4 text-grey-800 bg-white w-full', {
    height: 40,
  }),
  ({ isActive }) => isActive && s('bg-secondary font-semibold ')
)

const Link = styled(LinkBase)(s('no-decoration'))

function CustomerDetailNavigation() {
  const { id } = useParams()
  const { pathname } = useLocation()

  const checkIsActive = (route) => {
    const isTheSameRoute = pathname === route
    return isTheSameRoute
  }

  return (
    <NavItemList>
      <Link to={`${customerRoutes.root}/${id}`}>
        <NavItemTitle isActive={checkIsActive(`${customerRoutes.root}/${id}`)}>
          Personal Details
        </NavItemTitle>
      </Link>

      <Link to={`${customerRoutes.root}/${id}/loans`}>
        <NavItemTitle isActive={checkIsActive(`${customerRoutes.root}/${id}/loans`)}>
          Loan account(s)
        </NavItemTitle>
      </Link>

      <Link to={`${customerRoutes.root}/${id}/payment-details`}>
        <NavItemTitle
          isActive={checkIsActive(`${customerRoutes.root}/${id}/payment-details`)}
        >
          Payment Details
        </NavItemTitle>
      </Link>
    </NavItemList>
  )
}
export default CustomerDetailNavigation
