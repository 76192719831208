import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Row, Col, useLargeMediaQuery } from '@vega/styled'
import PaymentCard from './PaymentCard'
import { safelyParseDate } from 'utils/date'
import { Loading } from '@vega/components'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { isAfter } from 'date-fns'

const Root = styled.div(
  s('bg-white rounded-b-lg flex flex-column px-1 pb-6 py-1 w-full '),
  ({ isLargeScreen }) => (isLargeScreen ? s('mt-6') : s('mt-0'))
)

const PaymentScheduleContainer = styled.div(
  s('bg-white flex flex-column px-5 pb-6 py-2'),
  ({ isLargeScreen }) => (isLargeScreen ? s('mt-8') : s('mt-12'))
)

const DateHeaderContainer = styled.div(
  s('flex-row bg-grey-100 py-2 pl-4 mt-6 font-semibold', { borderRadius: 6 }),
  ({ hidden }) => hidden && s('hidden')
)

const ViewMoreButton = styled.button(
  s('border-transparent bg-secondary py-1 font-semibold text-sm', { borderRadius: 6 }),
  ({ hidden }) => hidden && s('hidden')
)

const NoPaymentsText = styled.div(
  s('text-center text-grey-700 font-normal text-base my-16')
)
const Title = styled.div(s('text-3xl text-black font-semibold '))

const isFuturePayment = (paymentDateString) => {
  return (
    isAfter(new Date().getMonth, new Date(paymentDateString).getMonth) &&
    isAfter(new Date().getFullYear, new Date(paymentDateString).getFullYear)
  )
}

const isSameAsPreviousMonth = (currentDateString, previousDateString) => {
  const currentMonth = new Date(currentDateString).getMonth()
  const previousMonth = new Date(previousDateString).getMonth()
  const currentYear = new Date(currentDateString).getFullYear()
  const previousYear = new Date(previousDateString).getFullYear()
  return (
    currentMonth === previousMonth &&
    currentYear === previousYear &&
    !isFuturePayment(previousDateString)
  )
}

// eslint-disable-next-line complexity
export function UpcomingPaymentsContainer({
  loans,
  payments,
  getMorePayments,
  hasNoMorePayments,
  isLoading,
}) {
  payments.sort((p1, p2) => {
    return new Date(p1?.dueDate) - new Date(p2?.dueDate)
  })

  const aboveLargeScreenSize = useLargeMediaQuery('larger')
  if (isLoading)
    return (
      <Root isLargeScreen={aboveLargeScreenSize} style={s('py-16')}>
        <Loading />
      </Root>
    )

  if (isNilOrEmpty(payments))
    return (
      <Root isLargeScreen={aboveLargeScreenSize} className="poppins">
        <NoPaymentsText>No upcoming payments</NoPaymentsText>
      </Root>
    )
  return (
    <Root isLargeScreen={aboveLargeScreenSize}>
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <PaymentScheduleContainer isLargeScreen={aboveLargeScreenSize}>
            <Title>Payment Schedule</Title>
            {payments.map((payment, index) => (
              <div key={index}>
                {!isSameAsPreviousMonth(
                  payment?.dueDate,
                  payments[index - 1]?.dueDate
                ) && (
                  <DateHeaderContainer
                    key={index}
                    hidden={isFuturePayment(payment?.dueDate)}
                  >
                    {safelyParseDate(payment?.dueDate, 'MMMM')}{' '}
                    {safelyParseDate(payment?.dueDate, 'yyyy')}
                  </DateHeaderContainer>
                )}
                <PaymentCard
                  loan={loans}
                  payment={payment}
                  hidden={isFuturePayment(payment?.dueDate)}
                />
              </div>
            ))}

            <ViewMoreButton
              onClick={() => {
                getMorePayments(payments.at(-1)?.dueDate)
              }}
              hidden={hasNoMorePayments}
            >
              Load More Payments
            </ViewMoreButton>
          </PaymentScheduleContainer>
        </Col>
      </Row>
    </Root>
  )
}
