import React from 'react'
import { Row } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { REPAYMENT_TYPES } from '@neo/constants'
import { isNaN } from '@solta/ramda-extra'

const { INTEREST_ONLY, PRINCIPAL_AND_INTEREST } = REPAYMENT_TYPES

const Root = styled.div(
  s(
    'bg-white flex flex-row rounded-lg justify-between w-full border-0 border-b-1 border-solid border-grey-200 mt-4 mb-4',
    {
      boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    }
  )
)
const Container = styled.div(s(' ml-2 flex flex-column items-start'), {
  width: '95%',
})
const Title = styled.div(s('mt-2 ml-4 text-grey-600 text-sm mb-1'))
const LoanInfo = styled.div(s('mt-2 mb-2 ml-4 font-bold '))

// eslint-disable-next-line complexity
function LoanInfoCard({
  interestRate = '-',
  initialLoanType = '-',
  initialTerm = '-',
  ongoingLoanType = '-',
  ongoingTerm = '-',
  ...props
}) {
  let formattedInitialLoanType = '-'
  if (initialLoanType === INTEREST_ONLY) {
    formattedInitialLoanType = 'Interest Only'
  } else if (initialLoanType === PRINCIPAL_AND_INTEREST) {
    formattedInitialLoanType = 'Principal and Interest'
  }

  let formattedOngoingLoanType = '-'
  if (ongoingLoanType === INTEREST_ONLY) {
    formattedOngoingLoanType = 'Interest Only'
  } else if (ongoingLoanType === PRINCIPAL_AND_INTEREST) {
    formattedOngoingLoanType = 'Principal and Interest'
  }
  let formattedOngoingTerm = '-'
  if (!isNaN(ongoingTerm / 12)) {
    formattedOngoingTerm = ongoingTerm / 12
  }

  return (
    <Root {...props}>
      <Row>
        <Container style={s('border-solid border-x-0 border-t-0 border-grey-200')}>
          <Title>Interest Rate</Title>
          <LoanInfo className="sohne">{interestRate}</LoanInfo>
        </Container>
        <Container style={s('border-solid border-x-0 border-t-0 border-grey-200')}>
          <Title style={{ marginTop: -2 }}>Initial Loan Type</Title>
          <LoanInfo className="sohne">{formattedInitialLoanType}</LoanInfo>
        </Container>
        <Container style={s('border-solid border-x-0 border-t-0 border-grey-200')}>
          <Title style={{ marginTop: -2 }}>Initial Interest Only Term</Title>
          <LoanInfo className="sohne">{initialTerm}</LoanInfo>
        </Container>
        <Container style={s('border-solid border-x-0 border-t-0 border-grey-200')}>
          <Title style={{ marginTop: -2 }}>Ongoing Loan Type</Title>
          <LoanInfo className="sohne">{formattedOngoingLoanType}</LoanInfo>
        </Container>
        <Container>
          <Title style={{ marginTop: -2 }}>Ongoing Term</Title>
          <LoanInfo className="sohne">{formattedOngoingTerm}</LoanInfo>
        </Container>
      </Row>
    </Root>
  )
}

export default LoanInfoCard
