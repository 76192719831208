import React from 'react'
import { prop } from '@solta/ramda-extra'
import { useFormikContext, useField } from 'formik'
import { useRadioGroup } from '@react-aria/radio'
import { useRadioGroupState } from '@react-stately/radio'
import { styled, s } from '@vega/styled'
import { FormMessage } from '../FormInput'

const Container = styled.div(s('flex flex-column'), prop('containerStyle'))

const OptionsContainer = styled.div(
  s('flex'),
  ({ hasError }) =>
    hasError && s('show-box rounded-b-lg rounded-tl-lg border-error-400')
)

const Label = styled.label(
  s('text-base font-normal text-grey-700 mb-4', {
    pointerEvents: 'none',
  })
)

const RadioContext = React.createContext()

export const useRadioContext = () => React.useContext(RadioContext)

const RadioButtonField = ({ containerStyle, testId, label, ...props }) => {
  const { children, name } = props

  const { setFieldValue } = useFormikContext()
  const onChange = (value) => setFieldValue(name, value)
  const [{ value }, { touched, error }] = useField({ name })

  const state = useRadioGroupState({
    ...props,
    value,
    onChange,
  })
  const { radioGroupProps } = useRadioGroup(props, state)

  return (
    <Container {...radioGroupProps} containerStyle={containerStyle}>
      <Label>{label}</Label>

      <RadioContext.Provider value={state}>
        <OptionsContainer data-test-id={testId} hasError={touched && error}>
          {children}
        </OptionsContainer>
      </RadioContext.Provider>
      <FormMessage message={error} visible={Boolean(touched && error)} id={name} />
    </Container>
  )
}

export default RadioButtonField
