import React from 'react'
import { Container } from '@vega/styled'
import { useSelector } from 'react-redux'
import { DottedCircleLoader } from '@vega/components'
import { ContentCard } from '@vega/components/src/v2'
import { LoanAccounts } from './LoanAccounts'
import { selectCustomerLoanGroups } from 'modules/customer'
import { styled, s } from '@vega/styled/v2'

const LoaderContainer = styled.div(s('flex justify-center items-center h-8'))

const LoanAccountsView = ({ isLoading }) => {
  const loanGroups = useSelector(selectCustomerLoanGroups)

  if (isLoading) {
    return (
      <Container>
        <ContentCard>
          <LoaderContainer>
            <DottedCircleLoader size="md" />
          </LoaderContainer>
        </ContentCard>
      </Container>
    )
  }

  return (
    <Container gutter={0}>
      {loanGroups.map((loanGroup, index) => (
        <LoanAccounts loanGroup={loanGroup} key={index} loanAccountOrder={index + 1} />
      ))}
    </Container>
  )
}

export { LoanAccountsView }
