import React from 'react'
import { forEach, prop, pipe, isNumber, all } from '@solta/ramda-extra'
import { PieChartBase } from './PieChartBase'
import { styled, s } from '@vega/styled'

const Container = styled.div(s('relative'))

const pickPropFromObjectArray = (keyToBePicked, objArray = []) => {
  const newObjArray = []

  forEach((obj) => {
    const picked = prop(keyToBePicked, obj)
    newObjArray.push(picked)
  }, objArray)

  return newObjArray
}

const validIfAllNumbers = (frequencies) => {
  const areAllNumbers = all(isNumber, frequencies)

  const isValid = areAllNumbers
  return { frequencies, previousResult: isValid }
}

const validIfNotAllZero = ({ frequencies, previousResult }) => {
  if (previousResult === false) return false

  const isValid = !all((f) => f === 0, frequencies)
  return isValid
}

function PieChart({ data, ExtraComponent, ...otherProps }) {
  const frequencies = pickPropFromObjectArray('frequency', data)

  const isFrequenciesValid = pipe(validIfAllNumbers, validIfNotAllZero)(frequencies)

  return (
    <Container>
      <PieChartBase
        data={data}
        shouldRenderBlankPie={!isFrequenciesValid}
        {...otherProps}
      />

      {ExtraComponent && <ExtraComponent />}
    </Container>
  )
}

export { PieChart }
