import { getHttpClient, getPublicHttpClient } from './httpClient'

class BaseService {
  /**
   * @param {import('index').ClientOption} options
   */
  constructor(options) {
    this.client = getHttpClient(options)
    this.publicClient = getPublicHttpClient(options)
  }
}

export default BaseService
