/* eslint-disable complexity */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import * as Validator from 'yup'
import { Formik as FormProvider, Form as FormBase } from 'formik'
import moment from 'moment'
import { useHidePersistentNavDrawer } from 'hooks'
import { ASYNC_STATUSES, PAYMENT_TYPE } from '@neo/constants'
import { styled, s } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import {
  Loading,
  DateField as DateFieldBase,
  FormTextarea,
  NumberField as NumberFieldBase,
  Button as ButtonBase,
  SelectField as SelectFieldBase,
  ErrorPage,
  toast,
} from '@vega/components'
import { ReactComponent as XIconBase } from '@vega/components/src/assets/images/x.svg'
import neoLogo from '@vega/components/src/assets/images/neo-logo.png'
import { formatDateString } from 'utils/date'
import { routes } from 'routes'
import {
  fetchLoan,
  selectFetchMortgageAccountsStatus,
  selectIsFetchMortgageAccountPending,
  selectLoanDetails,
} from 'modules/mortgageAccounts'
import {
  settlePayment,
  fetchPayments,
  selectAllPayments,
  selectIsFetchingPayments,
} from 'modules/payment'
import { fetchTransactions, setIsFetchingTransactions } from 'modules/transactions'

// Everything relating to transaction status and transaction date has been commented out until further clarification on their necessity
// const TRANSACTION_STATUS_DISPLAY = {
//   [TRANSACTION_STATUS.PAID]: 'Paid',
//   [TRANSACTION_STATUS.FAILED]: 'Failed',
// }

const PAYMENT_TYPE_DISPLAY = {
  [PAYMENT_TYPE.RECURRING_PAYMENT]: 'Recurring payment',
  [PAYMENT_TYPE.LUMP_SUM]: 'Lump sum',
  [PAYMENT_TYPE.LATE_FEE]: 'Late fee',
  [PAYMENT_TYPE.OTHER]: 'Other',
}

const Root = styled.div(
  s('flex flex-column flex-1 py-8 bg-white', {
    overflow: 'hidden',
  })
)

const Column = styled.div(s('flex flex-column'))

const Header = styled.div(s('items-center px-16 mb-4'))
const XIcon = styled(XIconBase)({ '&:hover': { cursor: 'pointer' } })
const Img = styled.img(s('w-5 ml-10'))

const Title = styled.h3(s('font-semibold text-2xl'))
const LoanNumber = styled.h6(s('mt-0 font-semibold text-base font-normal'))

const Scrollable = styled(Row)(s('flex-1 justify-center px-16', { overflow: 'auto' }))
const Form = styled(FormBase)(s('flex flex-column flex-1', { maxWidth: '680px' }))

const YSeparator = styled.div({ minHeight: 20 })

const NumberField = styled((props) => (
  <NumberFieldBase containerStyle={s('flex-1', { lineHeight: 1.2 })} {...props} />
))()

const DateField = styled((props) => (
  <DateFieldBase containerProps={{ style: s('flex flex-column flex-1') }} {...props} />
))()

const SelectField = styled(SelectFieldBase)(s('flex-1'))

const Button = styled(ButtonBase)({ height: 56 })

const parseDateStringToJsDateFormat = (dateString, originalFormat = 'DD/MM/YYYY') =>
  moment(dateString, originalFormat).toDate()

const convertToIsoString = (dateString, originalFormat = 'DD/MM/YYYY') =>
  moment(dateString, originalFormat).toDate().toISOString()

const { object, string, number } = Validator

const requiredNumber = number().required('This is a required field')

const validationSchema = object({
  transactionType: string()
    .oneOf([
      PAYMENT_TYPE.RECURRING_PAYMENT,
      PAYMENT_TYPE.LUMP_SUM,
      PAYMENT_TYPE.LATE_FEE,
      PAYMENT_TYPE.OTHER,
    ])
    .required('This is a required field'),
  amount: requiredNumber,
  transactionDate: string()
    .required('This is a required')
    .test(
      'is-before-today',
      'The date of transaction can not be in the future',
      (value) => {
        const transactionDate = parseDateStringToJsDateFormat(value)
        const today = new Date()

        const differenceInTime = today - transactionDate
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))

        return differenceInDays > 0
      }
    ),
  paymentId: string().when('transactionType', {
    is: PAYMENT_TYPE.RECURRING_PAYMENT,
    then: string().required('This field is required for recurring payments'),
  }),
})

const NewLedgerEntry = () => {
  useHidePersistentNavDrawer()

  const dispatch = useDispatch()
  const history = useHistory()
  const { id: loanId } = useParams()

  const payments = useSelector(selectAllPayments)
  const isFetchingPayments = useSelector(selectIsFetchingPayments)
  const isFetchingLoan = useSelector(selectIsFetchMortgageAccountPending)

  useEffect(() => {
    dispatch(
      fetchPayments({
        searchParams:{
        filters: {
          loanId,
          status: 'pending',
          type: 'recurring_payment',
        },
        limit: 20,
      }
      })
    )
  }, [dispatch, loanId])

  const fetchStatus = useSelector(selectFetchMortgageAccountsStatus)
  const loan = useSelector(selectLoanDetails)
  const handleExit = () => {
    history.push(`${routes.admin.loans.root}/${loanId}`)
  }

  useEffect(() => {
    dispatch(fetchLoan(loanId))
  }, [dispatch, loanId])

  const handleSubmit = async (values) => {
    try {
      const formattedValues = {
        ...values,
        transactionDate: convertToIsoString(values.transactionDate),
      }
      const result = await dispatch(settlePayment(formattedValues))
      await dispatch(setIsFetchingTransactions(true))
      unwrapResult(result)

      toast('New ledger entry was added')

      setTimeout(async () => {
        await dispatch(fetchTransactions(loanId))
        await dispatch(setIsFetchingTransactions(false))
      }, 2500)

      handleExit()
    } catch (error) {
      toast(error?.description ?? 'something goes wrong')
    }
  }
  const loanNumber = loan?.loanNumber

  if (isFetchingLoan) {
    return (
      <Root>
        <Loading />
      </Root>
    )
  }

  if (fetchStatus === ASYNC_STATUSES.REJECTED)
    return (
      <Root>
        <ErrorPage />
      </Root>
    )
  if (fetchStatus === ASYNC_STATUSES.PENDING)
    return (
      <Root>
        <Loading />
      </Root>
    )

  if (isFetchingPayments) {
    return (
      <Root>
        <Loading />
      </Root>
    )
  }

  return (
    <Root>
      <Header>
        <XIcon onMouseDown={handleExit} />
        <Img src={neoLogo} />
      </Header>
      <Scrollable>
        <FormProvider
          initialValues={{
            loanId,
            loanNumber,
            transactionType: '',
            transactionDate: '',
            amount: undefined,
            notes: '',
            paymentId: undefined,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <Title>New Entry</Title>
              <LoanNumber>Loan No: {loanNumber}</LoanNumber>
              {/*
              <Row>
                <Col span={12}>
                  <SelectField
                    name="transactionStatus"
                    label="Transaction status"
                    labelContainerStyle={s('mb-1')}
                    options={Object.entries(TRANSACTION_STATUS).map(([, value]) => ({
                      label: TRANSACTION_STATUS_DISPLAY[value],
                      value,
                    }))}
                  />
                </Col>
                <XSeparator />
              </Row>
              <YSeparator /> */}
              <Row>
                <Col span={12}>
                  <DateField
                    name="transactionDate"
                    label="Transaction date"
                    placeholder="DD/MM/YYYY"
                  />
                </Col>

                <Col span={12}>
                  <SelectField
                    name="transactionType"
                    label="Transaction type"
                    labelContainerStyle={s('mb-1')}
                    options={Object.entries(PAYMENT_TYPE).map(([, value]) => ({
                      label: PAYMENT_TYPE_DISPLAY[value],
                      value,
                    }))}
                  />
                </Col>
              </Row>
              <YSeparator />

              {values.transactionType === PAYMENT_TYPE.RECURRING_PAYMENT ? (
                <div>
                  <SelectField
                    name="paymentId"
                    label="Which Payment is it for?"
                    placeholder="Please select an unpaid payment"
                    options={payments.map((payment) => ({
                      label: `${payment.amount} due at ${formatDateString(
                        new Date(payment.dueDate)
                      )}`,
                      value: payment.id,
                    }))}
                    onSelectionChange={(value) => {
                      const desiredPayment = payments.find((p) => p.id === value)
                      setFieldValue('paymentId', value)
                      setFieldValue('amount', desiredPayment?.amount)
                    }}
                  />
                  <YSeparator />
                  <NumberField
                    isReadOnly={true}
                    name="amount"
                    label="Amount"
                    prefix="$"
                    style={s('w-full')}
                  />
                  <YSeparator />
                </div>
              ) : (
                <>
                  <YSeparator />
                  <NumberField
                    name="amount"
                    label="Amount"
                    prefix="$"
                    style={s('w-full')}
                  />
                  <YSeparator />
                </>
              )}
              <FormTextarea
                name="notes"
                label="Notes"
                placeholder="Leave note here"
                style={s('w-full')}
              />
              <Column style={s('flex-1 my-12')}>
                <Button type="submit" loading={isSubmitting}>
                  Add new entry
                </Button>
                <YSeparator />

                <Button
                  variant="outlined"
                  style={s('border-grey-400')}
                  onClick={handleExit}
                >
                  Cancel
                </Button>
              </Column>
            </Form>
          )}
        </FormProvider>
      </Scrollable>
    </Root>
  )
}

export { NewLedgerEntry }
