import { range } from '@solta/ramda-extra'

export const displayAllPageNumbersThresholdInPageNumber = 8

// from = 1, to = 5 -> [1, 2, 3, 4, 5]
const inclusiveRange = (from, to) => range(from, to + 1)

const getAllPageNumbers = (totalPages) => inclusiveRange(1, totalPages)

const getLeftTiltPageNumbers = (
  totalPages,
  maxNumOfLeadingBlocks,
  minNumOfTrailingBlocks
) => [
  ...inclusiveRange(1, maxNumOfLeadingBlocks),
  '...',
  // Adding 1 to ensure the length of the below trailing range will not exceed maxNumOfTrailingBlocks
  ...inclusiveRange(totalPages - minNumOfTrailingBlocks + 1, totalPages),
]

const getRightTiltPageNumbers = (
  minNumOfLeadingBlocks,
  totalPages,
  maxNumOfTrailingBlocks
) => [
  ...inclusiveRange(1, minNumOfLeadingBlocks),
  '...',
  ...inclusiveRange(totalPages - maxNumOfTrailingBlocks + 1, totalPages),
]

const getBalancedPageNumbers = (
  minNumOfLeadingBlocks,
  currentPageNumber,
  totalPages,
  minNumOfTrailingBlocks
) => [
  ...inclusiveRange(1, minNumOfLeadingBlocks),
  '...',
  ...inclusiveRange(currentPageNumber - 2, currentPageNumber + 2),
  '...',
  ...inclusiveRange(totalPages - minNumOfTrailingBlocks + 1, totalPages),
]

export const calcPageNumbers = (totalPages, pageIndex) => {
  const currentPageNumber = pageIndex + 1
  const pagesLeft = totalPages - currentPageNumber

  const minAmountOfLeadingPageNumbers = 2
  const maxAmountOfLeadingPageNumbers = 6

  const minAmountOfTrailingPageNumbers = 2
  const maxAmountOfTrailingPageNumbers = 6

  switch (true) {
    case totalPages <= displayAllPageNumbersThresholdInPageNumber:
      return getAllPageNumbers(totalPages)

    case currentPageNumber <= maxAmountOfLeadingPageNumbers:
      return getLeftTiltPageNumbers(
        totalPages,
        maxAmountOfLeadingPageNumbers,
        minAmountOfTrailingPageNumbers
      )

    case pagesLeft < maxAmountOfTrailingPageNumbers:
      return getRightTiltPageNumbers(
        minAmountOfLeadingPageNumbers,
        totalPages,
        maxAmountOfTrailingPageNumbers
      )

    default:
      return getBalancedPageNumbers(
        minAmountOfLeadingPageNumbers,
        currentPageNumber,
        totalPages,
        minAmountOfTrailingPageNumbers
      )
  }
}
