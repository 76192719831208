/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from 'react'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { useMenuTriggerState } from '@react-stately/menu'
import { useButton } from '@react-aria/button'
import { useMenuTrigger } from '@react-aria/menu'
import { FormMessage } from '../../FormInput'

import { MenuPopup } from './MenuPopup'

import { ReactComponent as DropdownIcon } from '@vega/components/src/assets/images/arrow_drop_down-24px.svg'

import { s, styled } from '@vega/styled/v2'

const Button = styled.button(
  s(
    'flex items-center justify-between bg-white border-solid border-1 border-grey-400 py-3 px-4 rounded-lg h-full w-full',
    {
      minHeight: 52,
      whiteSpace: 'nowrap',
      outline: 'none',
      '&:hover': s('border-1 border-primary', { cursor: 'pointer' }),
      ':focus-within': s('border-1 border-primary', {
        outline: '2px solid',
        outlineColor: s('text-primary').color,
      }),
    }
  ),
  ({ hasError }) =>
    hasError &&
    s('border-error-400 text-grey-500', {
      outline: 'none !important',
      '&:hover, &:focus-within': s('border-1 border-error-400 text-grey-500'),
    }),
  ({ disabled }) =>
    disabled &&
    s('bg-grey-200 text-grey-400 border-1 border-transparent', {
      pointerEvents: 'none',
    }),
  ({ readonly }) =>
    readonly &&
    s('bg-grey-200 text-grey-800 border-1 border-transparent', {
      pointerEvents: 'none',
    })
)

const ButtonPlaceholder = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isDisplayingValue',
})(
  s('text-base', {
    letterSpacing: '0.01em',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  ({ isDisplayingValue }) =>
    isDisplayingValue ? s('text-grey-900') : s('text-grey-500')
)

const DefaultButton = React.forwardRef(
  ({ readonly, label, displayText, hasError, ...otherProps }, ref) => {
    const hasSelectedValidValue = typeof displayText === 'string'

    return (
      <Button readonly={readonly} ref={ref} hasError={hasError} {...otherProps}>
        <ButtonPlaceholder isDisplayingValue={hasSelectedValidValue}>
          {hasSelectedValidValue ? displayText : label}
        </ButtonPlaceholder>
        <DropdownIcon
          style={s('ml-1', { width: 30, height: 25 })}
          fill={s('text-grey-700').color}
        />
      </Button>
    )
  }
)

const MenuContainer = styled.div(s('relative'))

function Menu(props) {
  const {
    readonly,
    selectedValue,
    label,
    name,
    error,
    ButtonComponent = DefaultButton,
    hasError,
    selectionMode,
    displayTextSelector = (v) => v,
    children,
    ...otherProps
  } = props

  const menuTriggerState = useMenuTriggerState(props)

  const buttonRef = React.useRef(null)
  const { menuTriggerProps, menuProps } = useMenuTrigger(
    {},
    menuTriggerState,
    buttonRef
  )

  const { buttonProps } = useButton(menuTriggerProps, buttonRef)

  return (
    <MenuContainer {...otherProps}>
      <ButtonComponent
        readonly={readonly}
        displayText={displayTextSelector(selectedValue)}
        label={label}
        hasError={hasError}
        {...buttonProps}
        ref={buttonRef}
      />

      {menuTriggerState.isOpen && (
        <MenuPopup
          {...props}
          selectionMode={selectionMode}
          domProps={menuProps}
          autoFocus={menuTriggerState.focusStrategy}
          onClose={() => menuTriggerState.close()}
        />
      )}

      <FormMessage
        id={name}
        message={error}
        visible={hasError ?? isNotNilOrEmpty(error)}
      />
    </MenuContainer>
  )
}

export { Menu }
