import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { Loading } from '@vega/components'
import { styled, s } from '@vega/styled/v2'
import { useLargeMediaQuery, larger } from '@vega/styled'
import { useDispatch, useSelector } from 'react-redux'

import {
  fetchCustomerByProfileId,
  selectIsFetchCustomerByProfileIdPending,
} from 'modules/customer'
import AppSwitcher from 'features/common/AppSwitcher'
import { TopBar } from 'features/common/TopBar'
import { CustomerNavigationDrawer } from './CustomerNavigationDrawer/CustomerNavigationDrawer'
import Homepage from './homepage'
import MyLoansRouter from './myAccount'
import AccountSettingsRouter from './accountSettings'
import PaymentScheduleRouter from './paymentSchedule'
import { selectTopBar } from 'modules/ui'
import { mobileTopBarHeight } from 'styles'
import { routes } from 'routes'
import { fetchPreference, selectPreferencesByProfileId } from 'modules/preference'
import { selectProfile } from '@vega/redux.profile'
import { pickColor } from 'utils/themes/themeConfig'
import LoanPaymentDetails from './paymentSchedule/loanPaymentDetail'

const Root = styled.div(s('relative flex h-full', { overflow: 'hidden' }))

const CustomerRoutes = routes.customer

const Core = styled.div(
  s('flex flex-column flex-1 h-full w-full'),
  s('bg-primary-background'),
  ({ isTopBarOpen }) => (isTopBarOpen ? { paddingTop: mobileTopBarHeight } : s('pt-0')),
  larger('lg')(s('pt-0'))
)

const CustomerRouter = () => {
  const dispatch = useDispatch()

  const profile = useSelector(selectProfile)
  const themePreference = useSelector((state) =>
    selectPreferencesByProfileId(state, profile.id)
  )

  const currentLocation = useLocation()
  let isLocationHomepage = false
  if (currentLocation.pathname === CustomerRoutes.homepage) {
    isLocationHomepage = true
  }

  const isLargeScreenSize = useLargeMediaQuery('larger')

  const { isOpen: isTopBarOpen } = useSelector(selectTopBar)
  const isFetchCustomerByProfileIdPending = useSelector(
    selectIsFetchCustomerByProfileIdPending
  )

  useEffect(() => {
    dispatch(fetchCustomerByProfileId())
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchPreference(profile.id))
  }, [dispatch, profile.id])

  if (isFetchCustomerByProfileIdPending) return <Loading />

  return (
    <Root>
      {!isLargeScreenSize && (
        <TopBar
          customerColour={pickColor(
            themePreference?.theme?.banking?.customerPrimaryColor
          )}
        />
      )}
      <CustomerNavigationDrawer />
      <AppSwitcher />

      <Core
        isTopBarOpen={isTopBarOpen}
        isHomepage={isLocationHomepage}
        themePreference={themePreference}
      >
        <Switch>
          <Route path={CustomerRoutes.homepage}>
            <Homepage />
          </Route>
          <Route path={CustomerRoutes.myLoans.root} component={MyLoansRouter} />
          <Route
            path={CustomerRoutes.paymentSchedule.root}
            component={PaymentScheduleRouter}
          />
          <Route
            path={CustomerRoutes.paymentSchedule.loanPaymentDetail}
            component={LoanPaymentDetails}
          />
          <Route path={CustomerRoutes.account.root} component={AccountSettingsRouter} />

          <Redirect to={CustomerRoutes.homepage} />
        </Switch>
      </Core>
    </Root>
  )
}

export default CustomerRouter
