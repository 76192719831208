import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { routes } from 'routes'
import AllLoans from './allLoans'
import LoanPaymentDetails from './loanPaymentDetail'
import UpcomingPayments from './upcomingPayments'
import PaidPayments from './paidPayments'

const paymentScheduleRoutes = routes.customer.paymentSchedule

const PaymentScheduleRouter = () => {
  return (
    <Switch>
      <Route exact path={paymentScheduleRoutes.allLoans} component={AllLoans} />

      <Route
        exact
        path={paymentScheduleRoutes.upcomingPayments}
        component={UpcomingPayments}
      />

      <Route exact path={paymentScheduleRoutes.paidPayments} component={PaidPayments} />

      <Route
        path={paymentScheduleRoutes.loanPaymentDetail}
        component={LoanPaymentDetails}
      />

      <Redirect to={paymentScheduleRoutes.allLoans} />
    </Switch>
  )
}

export default PaymentScheduleRouter
