import React, { useEffect } from 'react'
import { styled, s, Col, Row } from '@vega/styled'
import { Modal, Button, handleSignOut } from '@vega/components'
import { storeUserForceSignedOutFlag } from '@neo/app-access-controller'
import { USER_ACTIVITY } from '@neo/constants'

const { INACTIVITY_TIME_AUTO_SIGNOUT_IN_MS } = USER_ACTIVITY

const Container = styled.div(
  s('p-6 bg-white', {
    borderRadius: 12,
    boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
    maxWidth: 490,
  })
)
const Message = styled.div(s('text-center text-grey-600 text-sm mb-4'))
const ButtonContainer = styled.div(s('flex justify-around'))
const LogOutButton = styled(Button)(
  s('w-full bg-transparent border-1 border-solid border-black')
)

const forceSignOut = () => {
  storeUserForceSignedOutFlag()
  handleSignOut()
}
function InactivityModal({ isOpen, setIsOpen }) {
  useEffect(() => {
    let logOutTimer
    if (isOpen) {
      logOutTimer = setInterval(forceSignOut, INACTIVITY_TIME_AUTO_SIGNOUT_IN_MS)
    }

    return () => clearInterval(logOutTimer)
  }, [isOpen])

  return (
    <Modal isOpen={isOpen}>
      <Container>
        <Message>
          <span>
            For security reasons you’ll be logged out in <strong>60 seconds</strong> due
            to inactivity.
          </span>
        </Message>
        <Row style={s('mt-9')}>
          <Col span={24} lg={12}>
            <ButtonContainer>
              <Button onClick={() => setIsOpen(false)} style={s('w-full')}>
                Stay Logged In
              </Button>
            </ButtonContainer>
          </Col>
          <Col span={24} lg={12}>
            <ButtonContainer>
              <LogOutButton onClick={handleSignOut}>Log Out</LogOutButton>
            </ButtonContainer>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

export default InactivityModal
