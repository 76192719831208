import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import { ContentCard as ContentCardBase, DataDisplay } from '@vega/components/src/v2'
import { formatCurrency } from 'utils/formatter'
import { PropertyOverview } from './PropertyOverview'
import { Loading } from '@vega/components'

const ContentCard = styled(ContentCardBase)(s('pt-6 pl-6'))

const getLatestValue = (security) => {
  const sortedValuations = security?.property?.valuations?.sort(
    (firstValuation, secondValuation) => {
      const firstDateString = firstValuation?.date
      const secondDateString = secondValuation?.date
      const firstDate = new Date(firstDateString)
      const secondDate = new Date(secondDateString)
      return secondDate - firstDate
    }
  )
  return sortedValuations[0]?.value
}

const getTotalSecuritiesValue = (securities) => {
  const totalValue = securities?.reduce((accumulator, security) => {
    const sortedValuations = security?.property?.valuations?.sort(
      (firstValuation, secondValuation) => {
        const firstDate = new Date(firstValuation?.date)
        const secondDate = new Date(secondValuation?.date)

        return secondDate - firstDate
      }
    )
    return accumulator + sortedValuations[0]?.value
  }, 0)
  return totalValue
}

export function SecuritiesContainer({ securities = [], isLoading }) {
  const numberOfSecurities = securities?.length || '-'

  const totalSecuritiesValue = getTotalSecuritiesValue(securities) || '-'

  if (isLoading)
    return (
      <ContentCard>
        <div style={s('my-5')}>
          <Loading />
        </div>
      </ContentCard>
    )

  return (
    <ContentCard>
      <Row gutter={[0, 0]}>
        <Col span={8}>
          <DataDisplay
            label="Total Securities Value"
            value={formatCurrency(totalSecuritiesValue)}
            labelStyle={s('mb-1')}
          />
        </Col>
        <Col span={16}>
          <DataDisplay
            label="# of Securities"
            value={numberOfSecurities}
            labelStyle={s('mb-1 ml-2')}
            valueStyle={s('ml-2')}
          />
        </Col>

        {securities.map((security, index) => (
          <Col key={index} span={24}>
            <PropertyOverview
              security={security}
              propertyNumber={index + 1}
              latestValue={formatCurrency(getLatestValue(security))}
              isLastChild={index + 1 === securities.length}
            />
          </Col>
        ))}
      </Row>
    </ContentCard>
  )
}
