import React, { useEffect } from 'react'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchCustomer,
  fetchCustomerBroker,
  selectCurrentUserCustomerId,
} from 'modules/customer'

import { PersonalDetails } from './personalDetails'

const AccountSettingRouter = () => {
  const dispatch = useDispatch()
  const customerId = useSelector(selectCurrentUserCustomerId)

  useEffect(() => {
    if (isNotNilOrEmpty(customerId)) {
      dispatch(fetchCustomer({ customerId }))
      dispatch(fetchCustomerBroker(customerId))
    }
  }, [customerId, dispatch])

  return (
    <PersonalDetails />
  )
}

export default AccountSettingRouter
