import React from 'react'
import { larger, between } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { DottedCircleLoader } from '@vega/components'

import { safelyParseDate } from 'utils/date'
import { formatCurrency } from 'utils/formatter'

const Container = styled.div(
  s('p-4 bg-white shadow-sm rounded-lg'),
  larger('lg')({ paddingTop: 17.5, paddingBottom: 17.5 })
)

const TitleContainer = styled.div(s('flex justify-between items-center mt-9'))
const Title = styled.div(s('text-xl font-semibold text-primary', { lineHeight: 1.5 }))

const FlexRowBase = styled.div(s('flex flex-row items-center'))

const SubText = styled.div(
  s('font-semibold text-grey-800 mt-1 text-xl', { whiteSpace: 'nowrap' }),
  larger('lg')(s('text-sm', { lineHeight: 1.5 }))
)

const PaymentSubText = styled(SubText)()

const Label = styled.div(
  s('text-sm text-grey-600 font-normal', { lineHeight: 1.5, whiteSpace: 'nowrap' }),
  between('sm', 'md')(s('text-base')),
  larger('lg')(s('text-xs'))
)

export const PaymentCard = ({ isLoading = true, amount = '-', dueDate = '-' }) => {
  if (isLoading)
    return (
      <>
        <TitleContainer>
          <Title className="sohne">Upcoming Payments</Title>
        </TitleContainer>

        <Container style={s('mt-3 h-12')}>
          <div style={s('flex items-center justify-center h-full pb-4')}>
            <DottedCircleLoader size="md" />
          </div>
        </Container>
      </>
    )

  return (
    <>
      <TitleContainer>
        <Title className="sohne">Upcoming Payments</Title>
      </TitleContainer>
      <Container style={s('mt-3')}>
        {/* <PaymentTitleContainer>
        <PaymentTitle>Upcoming payment</PaymentTitle>

        {!isLargeScreen && (
          <Button onClick={toPaymentSchedulePage}>
            View all
            <ChevronRightIcon width={16} height={16} />
          </Button>
        )}
      </PaymentTitleContainer> */}

        <FlexRowBase style={s('justify-between')}>
          <div>
            <Label>Repayment amount</Label>
            <PaymentSubText>{formatCurrency(amount, 2)}</PaymentSubText>
          </div>

          <div>
            <Label>Due date</Label>
            <PaymentSubText>{safelyParseDate(dueDate, 'dd/MM/yyyy')}</PaymentSubText>
          </div>
        </FlexRowBase>

        {/* {isLargeScreen && (
          <FlexRowBase style={s('justify-end mt-3')}>
            <Button onClick={toPaymentSchedulePage}>
              View all
              <ChevronRightIcon width={14} height={14} />
            </Button>
          </FlexRowBase>
        )} */}
      </Container>
    </>
  )
}
