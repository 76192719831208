import React from 'react'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { shortenLargeNumbers } from './shortenLargeNumbers'
import PropTypes, { arrayOf } from 'prop-types'
import { Group } from '@visx/group'
import { Bar } from '@visx/shape'
import { scaleLinear, scaleBand } from '@visx/scale'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { useTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip'
import { GridRows } from '@visx/grid'
import { localPoint } from '@visx/event'

import { styled, s } from '@vega/styled'

const { object, number, func, string } = PropTypes

const BackgroundRect = styled.rect(s(''))

// We'll make some helpers to get at the data we want
const labelSelector = (d) => d.label
const valueSelector = (d) => +d.value

const distanceBetweenTallestBarAndMaxHeight = 10

const calculateChartDimensions = (width, height, margin, data) => {
  // Then we'll create some bounds
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  // And then scale the graph by our data
  const xScale = scaleBand({
    range: [0, xMax], // maxWidth
    round: true,
    domain: data.map(labelSelector), // X axis data range
    padding: 0.5, // padding of each bar
  })
  const yScale = scaleLinear({
    range: [yMax, 0], // maxHeight
    round: true,
    domain: [
      0,
      Math.max(...data.map(valueSelector)) + distanceBetweenTallestBarAndMaxHeight,
    ], // Y axis-data range
  })

  // Compose together the scale and accessor functions to get point functions
  // calculate the position of each Bar by the scale and the data item
  const compose = (scale, selector) => (data) => scale(selector(data))
  const xPoint = compose(xScale, labelSelector)
  const yPoint = compose(yScale, valueSelector)

  return { xMax, yMax, xScale, yScale, xPoint, yPoint }
}

const defaultMargin = { top: 20, bottom: 20, left: 0, right: 0 }

function BarChartBase({
  data,
  width,
  height,
  margin = defaultMargin,
  numOfTicksOnAxisY = 6,
  tooltipOffsetX = 50,
  tooltipOffsetY = 60,
  barOffsetX = 0,
  barWidth = 32,
  chartBackgroundColor = 'white',
  barColor = '#3CAFA5',
  tooltipContainerStyle,
  xAxisLabelStyle,
  yAxisLabelStyle,
  xAxisProps,
  yAxisProps,
  gridRowsProps,
  XAxisTickComponent,
  TooltipChildren,
}) {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip()

  const { xMax, yMax, xScale, yScale, xPoint, yPoint } = calculateChartDimensions(
    width,
    height,
    margin,
    data
  )

  const handleMouseOverOnBar = (event, tooltipData) => {
    const coords = localPoint(event.target.ownerSVGElement, event)

    if (coords !== null)
      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData,
      })
  }

  return (
    <div style={s('relative')}>
      <svg width={width} height={height} overflow="visible">
        <BackgroundRect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={chartBackgroundColor}
          rx={14}
        />
        <GridRows
          scale={yScale}
          width={xMax}
          height={yMax}
          numTicks={numOfTicksOnAxisY}
          stroke="#e0e0e0"
          {...gridRowsProps}
        />

        {data.map((d) => {
          const { label, value } = d
          const barHeight = yMax - yPoint(d)

          return (
            <Group key={`bar-${label}-${value}`}>
              <Bar
                x={xPoint(d) + barOffsetX} // adjust rect size by x (0.5) and width (1)
                y={yMax - barHeight}
                height={barHeight}
                width={barWidth || xScale.bandwidth()}
                fill={barColor}
                onPointerMove={(event) => handleMouseOverOnBar(event, { label, value })}
                onPointerLeave={hideTooltip}
              />

              <XAxisTickComponent
                barPositionX={xPoint(d) + barOffsetX}
                barOffsetX={barOffsetX}
                barData={d}
                yMax={yMax}
              />
            </Group>
          )
        })}
        <AxisLeft
          scale={yScale}
          stroke={'red'}
          tickStroke={'blue'}
          orientation="left"
          hideAxisLine
          numTicks={numOfTicksOnAxisY}
          strokeWidth={0}
          tickLabelProps={() => ({
            fill: s('text-grey-500').color,
            fontSize: 12,
            textAnchor: 'middle',
            ...xAxisLabelStyle,
          })}
          tickFormat={(d) => shortenLargeNumbers(d)}
          {...xAxisProps}
        />
        <AxisBottom
          top={yMax}
          scale={xScale}
          stroke={'red'}
          tickStroke={'blue'}
          hideTicks
          tickLabelProps={() => ({
            fontSize: 12,
            textAnchor: 'middle',
            ...yAxisLabelStyle,
          })}
          tickComponent={({ x, y, formattedValue }) => {
            if (isNotNilOrEmpty(XAxisTickComponent)) return undefined

            return (
              <text x={x} y={y}>
                {formattedValue}
              </text>
            )
          }}
          {...yAxisProps}
        />
      </svg>

      {tooltipOpen && (
        <TooltipWithBounds
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop + tooltipOffsetY}
          left={tooltipLeft + tooltipOffsetX}
          style={{ ...defaultStyles, ...tooltipContainerStyle, zIndex: 2 }}
        >
          {TooltipChildren ? (
            <TooltipChildren tooltipData={tooltipData} />
          ) : (
            <strong>{tooltipData?.label}</strong>
          )}
        </TooltipWithBounds>
      )}
    </div>
  )
}

BarChartBase.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  numOfTicksOnAxisY: number,
  chartBackgroundColor: string,
  data: arrayOf(
    PropTypes.shape({
      label: string,
      value: number,
    })
  ).isRequired,
  margin: PropTypes.shape({
    top: number,
    right: number,
    bottom: number,
    left: number,
  }),
  tooltipOffsetX: number,
  tooltipOffsetY: number,
  barOffsetX: number,
  barWidth: number,
  TooltipChildren: func,
  tooltipContainerStyle: object,
  XAxisTickComponent: func,
  barColor: string,
  xAxisLabelStyle: object,
  yAxisLabelStyle: object,
  xAxisProps: object,
  yAxisProps: object,
  gridRowsProps: object,
}

export { BarChartBase }
