export const searchOptions = [
  {
    key: 'reference',
    label: 'Reference',
    searchLabel: 'Search Reference',
  },
  {
    key: 'paymentId',
    label: 'Payment Id',
    searchLabel: 'Search Payment Id',
  },
  {
    key: 'amount',
    label: 'Amount (exact)',
    searchLabel: 'Search Amount',
  },
  {
    key: 'transactionType',
    label: 'Transaction Type',
    searchLabel: 'Search Transaction Type',
  },
  {
    key: 'note',
    label: 'Note',
    searchLabel: 'Search Notes',
  },
]

export const reconciledPaymentsOptions = [
  {
    key: '',
    label: 'All',
  },
  {
    key: 'paid',
    label: 'Paid',
  },
  {
    key: 'duplicated_payment',
    label: 'Duplicated Payment',
  },
  {
    key: 'unmatched_amount',
    label: 'Unmatched Amount',
  },
  {
    key: 'unmatched_reference',
    label: 'Unmatched Reference',
  },
  {
    key: 'payment_error',
    label: 'Payment Error',
  },
]
