import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { LoggedOutButton, handleSignOut, UpdatePassword } from '@vega/components'
import { styled, s } from '@vega/styled/v2'
import { larger, smaller, Row, Col, useLargeMediaQuery } from '@vega/styled'
import { PersonalDetailsCard } from './PersonalDetailsCard'
import { MyBrokerDetailsCard } from './MyBrokerDetailsCard'
import { ContactDetails } from './contactDetails'
import { PaymentInfoCard } from './PaymentInfoCard'
import { Title, Subtitle, Header } from '../common'
import {
  selectCustomerById,
  selectIsFetchCustomerPending,
  selectCurrentUserCustomerId,
  selectCustomerBroker,
  selectIsFetchCustomerBrokerPending,
} from 'modules/customer'

const SupportInfo = styled.div(s('text-grey-600 text-sm mb-4'))
const Container = styled.div(
  s('flex flex-column bg-primary-background h-full w-full pb-12 pt-4', {
    overflowY: 'auto',
  }),
  smaller('md')(s('px-6'))
)

const Body = styled.div(larger('lg')(s('px-8')))

/* eslint-disable complexity */
export const PersonalDetails = () => {
  const aboveLargeScreenSize = useLargeMediaQuery('larger')

  const customerId = useSelector(selectCurrentUserCustomerId)

  const isFetchingCustomerDetails = useSelector(selectIsFetchCustomerPending)
  const isFetchingBrokerDetails = useSelector(selectIsFetchCustomerBrokerPending)

  const customerDetails = useSelector((state) => selectCustomerById(state, customerId))
  const broker = useSelector(selectCustomerBroker)
  const profile = useSelector((state) => state.profile)
  const { gender = '-' } = profile

  const [customerData, setCustomerData] = useState(customerDetails || '')

  const { payment } = customerDetails || {
    payment: {
      accountHolder: '',
      bsb: '',
      accountNumber: '',
      status: '',
    },
  }

  // added useEffect to ensure form updates on change (phone number)
  useEffect(() => {
    const updateCustomerData = async () => {
      setCustomerData(customerDetails || '')
    }
    updateCustomerData()
  }, [customerDetails])

  return (
    <Container isLargeScreen={aboveLargeScreenSize}>
      <Header>
        <Row justify="center" style={s('items-center')}>
          <Col span={24} lg={16}>
            {aboveLargeScreenSize ? (
              <Title>Account Settings</Title>
            ) : (
              <Title>Personal Details</Title>
            )}
          </Col>
          <Col span={0} lg={5} />
        </Row>
      </Header>

      <Body>
        <Row justify="center" style={s('items-center')}>
          <Col span={24} lg={16}>
            <Subtitle className="sohne">My Personal Details</Subtitle>
            <SupportInfo>
              To update your personal details, please contact Neo supports at{' '}
              <strong>support@neo.nz</strong>
            </SupportInfo>
            <PersonalDetailsCard
              isLoading={isFetchingCustomerDetails}
              fullName={customerDetails?.name}
              dob={customerDetails?.dob}
              gender={gender}
              style={s('mb-10')}
            />

            <Subtitle className="sohne">My Broker Details</Subtitle>
            <MyBrokerDetailsCard
              broker={broker}
              isLoading={isFetchingBrokerDetails}
              style={s('mb-8')}
            />

            <Subtitle className="sohne">Contact Details</Subtitle>
            <ContactDetails
              isLoading={isFetchingCustomerDetails}
              setCustomerData={setCustomerData}
              customerData={customerData}
              style={s('mb-10')}
            />

            <Subtitle className="sohne">Password</Subtitle>
            <UpdatePassword email={customerDetails?.email} />

            <Subtitle className="sohne" style={s('mt-8')}>Payment Details </Subtitle>

            <SupportInfo>
              To update your direct debits payment details, please contact Neo supports at{' '}
              <strong>support@neo.nz</strong>
            </SupportInfo>

            <PaymentInfoCard isLoading={isFetchingCustomerDetails} {...payment} />

            {!aboveLargeScreenSize && (
              <LoggedOutButton style={s('mt-8 py-3 w-full')} onClick={handleSignOut}>
                Log Out
              </LoggedOutButton>
            )}
          </Col>
          <Col span={0} lg={5} />
        </Row>
      </Body>
    </Container>
  )
}
