import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Overview from './Overview/index'
import { routes } from 'routes'

const paymentCenterRoutes = routes.admin.paymentCenter

function PaymentCenterRouter() {
  return (
    <Switch>
      <Route exact path={paymentCenterRoutes.overview} component={Overview} />
      <Redirect to={paymentCenterRoutes.overview} />
    </Switch>
  )
}

export { PaymentCenterRouter }
