import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { styled, s } from '@vega/styled/v2'
import { Formik as FormProvider, Form } from 'formik'
import {
  Button as ButtonBase,
  SearchBar as SearchBarBase,
  DottedCircleLoader,
} from '@vega/components'
import { Table } from '@vega/components/src/v2'
import { ReactComponent as PlusIcon } from '@vega/components/src/assets/images/plus.svg'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes'
import { Columns, Rows } from './TableFormat'
import { fetchProducts, selectAllProducts, selectTotal } from 'modules/product'
import { useTableFetching } from 'hooks'
import FiltersBar from './FiltersBar'

const Root = styled.div(s('flex flex-column flex-1 mx-8'))
const Row = styled.div(s('flex flex-row pb-4 pt-8'))
const Title = styled.h3(s('p-0 m-0 text-primary font-semibold', { fontSize: 28 }))

const Button = styled(ButtonBase)(s('h-3 font-semibold text-sm text-grey-900'))

const SearchAndFiltersContainer = styled.div(
  s('flex my-4 justify-between', { gap: 16 })
)

const SearchForm = styled(Form)(s('w-full'))

const SearchBar = styled(SearchBarBase)(s('bg-white rounded-lg'))

const TableContainer = styled.div(s('h-full mt-4', { overflow: 'hidden' }))

function Overview() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [hasFirstFetchFulfilled, setHasFirstFetchFulfilled] = useState(false)
  const total = useSelector(selectTotal)
  const products = useSelector(selectAllProducts)

  const columns = Columns()
  const data = Rows({ products })

  function handleCreateProduct() {
    history.push(routes.admin.products.newProduct)
  }

  const fetchData = useCallback(
    ({ searchParams = {}, pageIndex = 0 }) =>
      dispatch(fetchProducts({ searchParams, pageIndex })),
    [dispatch]
  )

  const {
    searchProps: { searchParams },
    updateSearchProps,
    paginationProps,
    isFetchingData,
  } = useTableFetching(total, fetchData)

  React.useEffect(() => {
    async function fetchLoanProductsOnMount() {
      await fetchData({
        searchParams: { limit: searchParams.limit },
        pageIndex: 0,
      })
      setHasFirstFetchFulfilled(true)
    }

    fetchLoanProductsOnMount()
  }, [dispatch, fetchData, searchParams.limit])

  const onTextSearch = ({ searchTerm }) => {
    updateSearchProps({
      searchTerm,
    })
  }

  if (!hasFirstFetchFulfilled) {
    return (
      <div style={s('flex justify-center items-center h-full w-full')}>
        <DottedCircleLoader size="lg" />
      </div>
    )
  }

  return (
    <Root>
      <Row style={s('items-center justify-between')}>
        <Title className="sohne">Product Management</Title>

        <Button
          StartIcon={<PlusIcon style={s('w-1 h-1 text-grey-900')} />}
          onClick={handleCreateProduct}
        >
          <span style={s('ml-1')}>Create a product</span>
        </Button>
      </Row>

      <SearchAndFiltersContainer>
        <FormProvider
          enableReinitialize={false}
          initialValues={{ searchTerm: '' }}
          onSubmit={onTextSearch}
        >
          {({ values, handleChange }) => {
            return (
              <SearchForm>
                <SearchBar
                  placeholder="Search product name or anything"
                  onInputChange={handleChange}
                  value={values.searchTerm}
                  name="searchTerm"
                  id="searchTerm"
                />
              </SearchForm>
            )
          }}
        </FormProvider>

        <FiltersBar
          updateSearchProps={updateSearchProps}
          filters={searchParams.filters}
        />
      </SearchAndFiltersContainer>

      <TableContainer>
        <Table
          columns={columns}
          data={data}
          tBodyStyle={s('bg-white')}
          containerStyle={s('bg-white rounded-lg shadow-md flex-1')}
          paginationProps={paginationProps}
          style={s('pb-0 m-0')}
          tableHeaderRowStyles={s(
            'bg-white border-solid border-0 border-b-1 border-grey-200'
          )}
          loaderLabel="Fetching products"
          loading={isFetchingData}
        />
      </TableContainer>
    </Root>
  )
}

export default Overview
