import React from 'react'
import { DottedCircleLoader } from '@vega/components'
import NavItem from './NavItem'
import { s, styled } from '@vega/styled/v2'
import { routes } from 'routes'

const myLoansRouteRoot = routes.customer.myLoans.root

const Container = styled.div(s('flex flex-column h-full'))

const AccountListContainer = styled.div({
  overflowY: 'auto',
  overflowX: 'hidden',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },
})

const SideNav = ({ loans = [], onItemClick, isLoading = true }) => {
  const loanItems = loans.map((loan) => (
    <NavItem
      key={loan._id}
      path={`${myLoansRouteRoot}/${loan._id}`}
      loan={loan}
      onItemClick={onItemClick}
    />
  ))

  return (
    <Container>
      {
        isLoading ?
          (
            <div style={s('flex items-center justify-center')}>
              <DottedCircleLoader size="md" />
            </div>
          ) : (
            <AccountListContainer>{loanItems}</AccountListContainer>
          )
      }
    </Container>
  )
}

export default SideNav
