import BaseService from '../baseService'

import { isNil } from '@solta/ramda-extra'

export class PreferenceService extends BaseService {
  fetchPreference(profileId) {
    if (isNil(profileId)) throw new Error('Please provide an user id')

    return this.client.get(`preferences/?profileId=${profileId}`).json()
  }

  updatePreference({ ...updatingProps }) {
    const config = {
      json: updatingProps,
    }
    return this.client.put(`preferences`, config).json()
  }
}
