export const accounts = {
  items: [
    {
      id: '61631b8232c25038f8d2135b',
      name: 'Neo Mortgage',
      bsb: '000000',
      accountNumber: '11112222',
      loanBalance: 498000,
      loanLimit: 500000,
      summary: {
        loanLimit: 500000,
        monthlyPayment: 2000,
        loanTerm: 25,
        repaymentTypes: 'interest',
        paymentTypes: 'direct_debit',
        interestRate: 0.0254,
      },
      payment: {
        dueAmount: 2000,
        dueDate: '2021-08-18T16:57:38.030Z',
      },
      transactions: [
        {
          date: '2021-07-18T16:57:38.030Z',
          beginningBalance: 550000.17,
          scheduledPayment: 2000,
          principal: 1800,
          interest: 200,
          remainingBalance: 498000.0,
          cumulativeInterest: 200,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-06-18T16:57:38.030Z',
          beginningBalance: 498000.0,
          scheduledPayment: 2000,
          principal: 1805,
          interest: 195,
          remainingBalance: 496000.0,
          cumulativeInterest: 395,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-05-18T16:57:38.030Z',
          beginningBalance: 496000.0,
          scheduledPayment: 2000,
          principal: 1810,
          interest: 190,
          remainingBalance: 494000.0,
          cumulativeInterest: 395,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-18T16:57:38.030Z',
          beginningBalance: 0,
          scheduledPayment: 20,
          principal: 0,
          interest: 0,
          remainingBalance: 0,
          cumulativeInterest: 0,
          lateFee: 20,
          transactionType: 'late',
          status: 'paid',
        },
        {
          date: '2021-04-17T16:57:38.030Z',
          beginningBalance: 550000.0,
          scheduledPayment: 2000,
          principal: 1800,
          interest: 200,
          remainingBalance: 498000.0,
          cumulativeInterest: 200,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-16T16:57:38.030Z',
          beginningBalance: 498000.0,
          scheduledPayment: 2000,
          principal: 1805,
          interest: 195,
          remainingBalance: 496000.0,
          cumulativeInterest: 395,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-15T16:57:38.030Z',
          beginningBalance: 496000.0,
          scheduledPayment: 2000,
          principal: 1810,
          interest: 190,
          remainingBalance: 494000.0,
          cumulativeInterest: 395,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-14T16:57:38.030Z',
          beginningBalance: 0,
          scheduledPayment: 20,
          principal: 0,
          interest: 0,
          remainingBalance: 0,
          cumulativeInterest: 0,
          lateFee: 20,
          transactionType: 'late',
          status: 'paid',
        },
        {
          date: '2021-04-13T16:57:38.030Z',
          beginningBalance: 550000.0,
          scheduledPayment: 2000,
          principal: 1800,
          interest: 200,
          remainingBalance: 498000.0,
          cumulativeInterest: 200,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-12T16:57:38.030Z',
          beginningBalance: 498000.0,
          scheduledPayment: 2000,
          principal: 1805,
          interest: 195,
          remainingBalance: 496000.0,
          cumulativeInterest: 395,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-11T16:57:38.030Z',
          beginningBalance: 496000.0,
          scheduledPayment: 2000,
          principal: 1810,
          interest: 190,
          remainingBalance: 494000.0,
          cumulativeInterest: 395,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-10T16:57:38.030Z',
          beginningBalance: 0,
          scheduledPayment: 20,
          principal: 0,
          interest: 0,
          remainingBalance: 0,
          cumulativeInterest: 0,
          lateFee: 20,
          transactionType: 'late',
          status: 'paid',
        },
        {
          date: '2021-03-10T16:57:38.030Z',
          beginningBalance: 0,
          scheduledPayment: 50000,
          principal: 0,
          interest: 0,
          remainingBalance: 0,
          cumulativeInterest: 0,
          lateFee: 0,
          transactionType: 'settlement',
          status: 'paid',
        },
        {
          date: '2021-02-10T16:57:38.030Z',
          beginningBalance: 0,
          scheduledPayment: 50000,
          principal: 0,
          interest: 0,
          remainingBalance: 0,
          cumulativeInterest: 0,
          lateFee: 0,
          transactionType: 'settlement',
          status: 'paid',
        },
      ],
    },
    {
      id: '61631b1d32c25038f8d2134v',
      name: 'Neo Car Loan',
      bsb: '000000',
      accountNumber: '11112222',
      loanBalance: -500000,
      loanLimit: 500000,
      summary: {
        loanLimit: 500000,
        monthlyPayment: 2000,
        loanTerm: 25,
        repaymentTypes: 'interest',
        paymentTypes: 'direct_debit',
        interestRate: 0.0254,
      },
      payment: {
        dueAmount: 2000,
        dueDate: '2021-08-18T16:57:38.030Z',
      },
      transactions: [
        {
          date: '2021-07-18T16:57:38.030Z',
          beginningBalance: 550000.17,
          scheduledPayment: 2000,
          principal: 1800,
          interest: 200,
          remainingBalance: 498000.0,
          cumulativeInterest: 200,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-06-18T16:57:38.030Z',
          beginningBalance: 498000.0,
          scheduledPayment: 2000,
          principal: 1805,
          interest: 195,
          remainingBalance: 496000.0,
          cumulativeInterest: 395,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-05-18T16:57:38.030Z',
          beginningBalance: 496000.0,
          scheduledPayment: 2000,
          principal: 1810,
          interest: 190,
          remainingBalance: 494000.0,
          cumulativeInterest: 395,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-18T16:57:38.030Z',
          beginningBalance: 0,
          scheduledPayment: 20,
          principal: 0,
          interest: 0,
          remainingBalance: 0,
          cumulativeInterest: 0,
          lateFee: 20,
          transactionType: 'late',
          status: 'paid',
        },
        {
          date: '2021-04-17T16:57:38.030Z',
          beginningBalance: 550000.0,
          scheduledPayment: 2000,
          principal: 1800,
          interest: 200,
          remainingBalance: 498000.0,
          cumulativeInterest: 200,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-16T16:57:38.030Z',
          beginningBalance: 498000.0,
          scheduledPayment: 2000,
          principal: 1805,
          interest: 195,
          remainingBalance: 496000.0,
          cumulativeInterest: 395,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-15T16:57:38.030Z',
          beginningBalance: 496000.0,
          scheduledPayment: 2000,
          principal: 1810,
          interest: 190,
          remainingBalance: 494000.0,
          cumulativeInterest: 395,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-14T16:57:38.030Z',
          beginningBalance: 0,
          scheduledPayment: 20,
          principal: 0,
          interest: 0,
          remainingBalance: 0,
          cumulativeInterest: 0,
          lateFee: 20,
          transactionType: 'late',
          status: 'paid',
        },
        {
          date: '2021-04-13T16:57:38.030Z',
          beginningBalance: 550000.0,
          scheduledPayment: 2000,
          principal: 1800,
          interest: 200,
          remainingBalance: 498000.0,
          cumulativeInterest: 200,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-12T16:57:38.030Z',
          beginningBalance: 498000.0,
          scheduledPayment: 2000,
          principal: 1805,
          interest: 195,
          remainingBalance: 496000.0,
          cumulativeInterest: 395,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-11T16:57:38.030Z',
          beginningBalance: 496000.0,
          scheduledPayment: 2000,
          principal: 1810,
          interest: 190,
          remainingBalance: 494000.0,
          cumulativeInterest: 395,
          lateFee: 0,
          transactionType: 'monthly',
          status: 'paid',
        },
        {
          date: '2021-04-10T16:57:38.030Z',
          beginningBalance: 0,
          scheduledPayment: 20,
          principal: 0,
          interest: 0,
          remainingBalance: 0,
          cumulativeInterest: 0,
          lateFee: 20,
          transactionType: 'late',
          status: 'paid',
        },
        {
          date: '2021-03-10T16:57:38.030Z',
          beginningBalance: 0,
          scheduledPayment: 50000,
          principal: 0,
          interest: 0,
          remainingBalance: 0,
          cumulativeInterest: 0,
          lateFee: 0,
          transactionType: 'settlement',
          status: 'paid',
        },
        {
          date: '2021-02-10T16:57:38.030Z',
          beginningBalance: 0,
          scheduledPayment: 50000,
          principal: 0,
          interest: 0,
          remainingBalance: 0,
          cumulativeInterest: 0,
          lateFee: 0,
          transactionType: 'settlement',
          status: 'paid',
        },
      ],
    },
  ],
}
