import React from 'react'
import { Item } from '@react-stately/collections'
import { s, styled } from '@vega/styled/v2'
import { Menu } from '@vega/components'
import { loanOptions } from './filterOptions'

const Container = styled.div(s('flex justify-between', { gap: 10 }))
function FiltersBar({ updateSearchProps, statusFilterLabel, setStatusFilterLabel }) {
  const handleFilterAction = (targetFilter) => (value) => {
    updateSearchProps({ filters: { [targetFilter]: value } })
    const updateStatusFilterObject = loanOptions.find((filter) => filter.key === value)
    setStatusFilterLabel(updateStatusFilterObject)
  }

  const StatusFilter = styled(Menu)(
    s('', {
      minWidth: '195px',
      maxWidth: '195px',
    })
  )

  return (
    <Container>
      <StatusFilter
        label={statusFilterLabel.label || 'Status'}
        aria-label="status"
        onAction={handleFilterAction('status')}
      >
        {loanOptions.map((option) => (
          <Item key={option.key} value={option.key}>
            {option.label}
          </Item>
        ))}
      </StatusFilter>
    </Container>
  )
}

export default FiltersBar
