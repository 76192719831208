import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import {
  DataDisplay as DataDisplayBase,
  Divider as DividerBase,
} from '@vega/components/src/v2'
import formatters from 'utils/formatters'

const DataDisplay = styled(DataDisplayBase)(s('mb-4'))

const PropertyNumber = styled.div(s('mt-5 mb-2 font-semibold text-xs text-primary'))

const Divider = styled(DividerBase)(({ isLastChild }) => isLastChild && s('border-0'))

export function PropertyOverview({
  security,
  propertyNumber,
  latestValue,
  isLastChild,
}) {
  const { securityPercentage } = security || '-'

  return (
    <Row gutter={[0, 0]}>
      <Col span={24}>
        <PropertyNumber>PROPERTY {propertyNumber}</PropertyNumber>
      </Col>
      <Col span={24}>
        <DataDisplay
          label="Address"
          value={formatters.buildAddress(
            {
              streetName: security?.property?.address?.streetName,
              streetNumber: security?.property?.address?.streetNumber,
              streetType: security?.property?.address?.streetType,
            },
            security?.property?.address?.suburb,
            security?.property?.address?.territorialAuthorityName,
            security?.property?.address?.countryISO,
            security?.property?.address?.streetUnitNumber,
            security?.property?.address?.postCode,
            security?.property?.address?.fullAddress
          )}
          labelStyle={s('mb-1 ')}
        />
      </Col>
      <Col span={8}>
        <DataDisplay
          label="Property Value"
          value={latestValue}
          labelStyle={s('mb-1 ')}
        />
      </Col>
      <Col span={16}>
        <DataDisplay
          label="Allocated Loan"
          value={`${securityPercentage}%`}
          labelStyle={s('mb-1 ml-2')}
          valueStyle={s('ml-2')}
        />
      </Col>
      <Col span={24}>
        <Divider isLastChild={isLastChild} />
      </Col>
    </Row>
  )
}
