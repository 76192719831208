import { PAYMENT_TYPE, PAYMENT_METHOD_TYPE } from '@neo/constants'

export const paymentTypeFilterOptions = [
  { key: PAYMENT_TYPE.RECURRING_PAYMENT, label: 'Recurring payment' },
  { key: PAYMENT_TYPE.LUMP_SUM, label: 'Lump sum payment' },
  { key: PAYMENT_TYPE.LATE_FEE, label: 'Late fee' },
  { key: PAYMENT_TYPE.OTHER, label: 'Other' },
]

export const methodFilterOptions = [
  { key: PAYMENT_METHOD_TYPE.DIRECT_DEBIT, label: 'Direct debit' },
]

export const statusFilterOptions = [
  { key: 'paid', label: 'Paid' },
  { key: 'pending', label: 'Pending' },
  { key: 'cancelled', label: 'Cancelled' },
  { key: 'unpaid', label: 'Unpaid' },
]

export const overdueFilterOptions = [
  { key: 'lessThanAMonth', label: '<30 days' },
  { key: 'moreThanAMonth', label: '31-60 days' },
  { key: 'moreThanTwoMonths', label: '61-90 days' },
  { key: 'moreThanThreeMonths', label: '>90 days' },
]
