import { always, ifElse, isNilOrEmpty, isNotNilOrEmpty } from '@solta/ramda-extra'
import moment from 'moment'

const STRING_VALUE_PLACEHOLDER = '-'
const EMPTY_AMOUNT_PLACEHOLDER = '$ -'

const capitalize = (str = '') => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

const formatCurrency = (value, digits = 0) =>
  new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: digits,
  }).format(value)

const convertDate = (date) => moment(date).format('DD/MM/YYYY')
const formatDate = ifElse(isNilOrEmpty, always(STRING_VALUE_PLACEHOLDER), convertDate)

const formatMonetaryAmount = ifElse(
  isNilOrEmpty,
  always(EMPTY_AMOUNT_PLACEHOLDER),
  formatCurrency
)

const formatMillionsOrLess = (val) => {
  if (isNilOrEmpty(val)) return EMPTY_AMOUNT_PLACEHOLDER

  if (val < 1_000_000) return formatMonetaryAmount(val)

  const valueInMil = val / 1_000_000
  const unit = valueInMil >= 2 ? 'mils' : 'mil'

  return `${formatMonetaryAmount(valueInMil, 1)} ${unit}`
}

const withEmptyPlaceholder = (value, placeholder) => {
  const emptyPlaceholder = placeholder || STRING_VALUE_PLACEHOLDER

  return ifElse(isNilOrEmpty, always(emptyPlaceholder), always(value))(value)
}

const capitaliseFirstLetter = (string) => {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1)
  } catch (error) {
    return '-'
  }
}

const throwIfInvalidHashFormat = (hashFormat) => {
  const notConsistWithOnlyHashAndSpace = /(?!(#|\s))./g.test(hashFormat)

  if (notConsistWithOnlyHashAndSpace) {
    throw new Error(
      'Invalid hash format passed into insertSeparatorsToString: It should be a string consisted with hashes and spaces'
    )
  }
}

// eslint-disable-next-line complexity
const insertSeparatorsToString = (
  value,
  separator = ' ',
  hashFormat = '#### ####',
  placeholder = undefined
) => {
  try {
    if (isNilOrEmpty(value)) return placeholder

    throwIfInvalidHashFormat(hashFormat)

    let formattedString = ''
    const hashFormatArr = hashFormat.toString().split('')

    let inputValuePointer = 0
    const stopForLoopAtIdx = value.length
    for (
      let hashValuePointer = 0;
      hashValuePointer < hashFormatArr.length;
      hashValuePointer += 1
    ) {
      if (inputValuePointer === stopForLoopAtIdx) break

      if (hashFormatArr[hashValuePointer] === '#') {
        formattedString += value.charAt(inputValuePointer)
        inputValuePointer += 1
        // eslint-disable-next-line no-continue
        continue
      }

      formattedString += `${separator}${value.charAt(inputValuePointer)}`
      inputValuePointer += 1
      hashValuePointer += 1
    }

    return formattedString
  } catch (error) {
    return placeholder
  }
}

const formatPhoneNumber = (phoneNumber) => {
  if (isNilOrEmpty(insertSeparatorsToString(phoneNumber, ' ', `### ### ####`))) {
    return '-'
  }
  if (phoneNumber.length > 10) {
    return insertSeparatorsToString(phoneNumber, ' ', `## ## ### #######`)
  }
  return insertSeparatorsToString(phoneNumber, ' ', `### ### ####`)
}

const capitaliseFirstLetterOfAllWords = (sentence) => {
  try {
    return sentence
      .toLowerCase()
      .split(' ')
      .map((word) => capitaliseFirstLetter(word))
      .join(' ')
  } catch (error) {
    return '-'
  }
}

// eslint-disable-next-line complexity
const buildAddress = (
  streetDetails = {},
  suburb,
  city,
  countryISO,
  unit,
  postCode,
  fullAddress,
  fallback = '-'
) => {
  function getStreetAddress(unit, streetNumber, streetName, streetType) {
    let enrichedAddress = `${streetName}`
    if (streetNumber) enrichedAddress = `${streetNumber} ${enrichedAddress}`
    if (streetNumber && unit) enrichedAddress = `${unit}/${enrichedAddress}`
    if (streetType) enrichedAddress = `${enrichedAddress} ${streetType}`

    return capitaliseFirstLetterOfAllWords(enrichedAddress)
  }

  function concatOtherAddressFields(streetAddress, suburb, city, postCode, countryISO) {
    // The order of the fields determined how the address parts are organized
    let address = [streetAddress, suburb, city, postCode]
      .map((field) => field?.trim())
      .filter((field) => isNotNilOrEmpty(field))
      .map((field) => capitaliseFirstLetterOfAllWords(field))
      .join(', ')

    if (countryISO) address += `, ${countryISO?.toUpperCase()}`

    return address
  }

  try {
    if (isNotNilOrEmpty(fullAddress))
      return capitaliseFirstLetterOfAllWords(fullAddress)

    const { streetNumber, streetName, streetType } = streetDetails
    if (isNilOrEmpty(streetName))
      return (
        concatOtherAddressFields('', suburb, city, postCode, countryISO) || fallback
      )

    const streetAddress = getStreetAddress(unit, streetNumber, streetName, streetType)
    return (
      concatOtherAddressFields(streetAddress, suburb, city, postCode, countryISO) ||
      fallback
    )
  } catch (error) {
    return fallback
  }
}

export default {
  capitalize,
  formatDate,
  formatMonetaryAmount,
  formatMillionsOrLess,
  withEmptyPlaceholder,
  capitaliseFirstLetter,
  formatPhoneNumber,
  buildAddress,
}
