import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { legalEntityService, mortgageAccountService } from 'apiService'
import { normalizeError } from '@vega/services'

export const fetchLegalEntitiesByLoanId = createAsyncThunk(
  'legalEntities/fetchLegalEntitiesByLoanId',
  async (loanId, { rejectWithValue }) => {
    try {
      return await mortgageAccountService.fetchLoanEntities(loanId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchLegalEntity = createAsyncThunk(
  'legalEntities/fetchLegalEntity',
  async (id, { rejectWithValue }) => {
    try {
      return await legalEntityService.fetchLegalEntity(id)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const searchLegalEntities = createAsyncThunk(
  'legalEntities/searchLegalEntities',
  async ({ searchParams, pageIndex }, { rejectWithValue, signal }) => {
    try {
      const { searchTerm: q, filters = {}, sorting = {}, limit = 20 } = searchParams
      return await legalEntityService.search(
        {
          q,
          filters,
          start: limit * pageIndex,
          sorting,
          limit,
        },
        signal
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const legalEntitiesAdapter = createEntityAdapter()

const initialState = legalEntitiesAdapter.getInitialState()

const legalEntitiesSlice = createSlice({
  name: 'legalEntities',
  initialState,
  extraReducers: {
    [fetchLegalEntitiesByLoanId.fulfilled]: (state, { payload }) => {
      legalEntitiesAdapter.setAll(state, payload)
    },

    [fetchLegalEntity.fulfilled]: (state, action) => {
      legalEntitiesAdapter.upsertOne(state, action.payload)
    },

    [searchLegalEntities.fulfilled]: (state, action) => {
      const { items: legalEntities, pagination } = action.payload

      legalEntitiesAdapter.setAll(state, legalEntities)
      state.total = pagination.total
    },
  },
})

const { reducer: legalEntitiesReducer } = legalEntitiesSlice

export { legalEntitiesReducer }
