import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setPersistentNavDrawerState } from 'modules/ui'

const useHidePersistentNavDrawer = () => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(setPersistentNavDrawerState({ isOpen: false }))

    return () => dispatch(setPersistentNavDrawerState({ isOpen: true }))
  }, [dispatch])
}
export default useHidePersistentNavDrawer
