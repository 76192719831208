import React from 'react'
import { Row, Col, Container } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import {
  ContentCard as ContentCardBase,
  SectionTitle as SectionTitleBase,
  DataDisplay,
} from '@vega/components/src/v2'

const UpdateButton = styled(SectionTitleBase)(s('text-xs'))

const ContentCard = styled(ContentCardBase)(s('mb-9 p-5'))

const SectionTitle = styled(SectionTitleBase)(s('mb-4'))

export function PaymentDetails({ details }) {
  return (
    <Container style={s('px-5')}>
      <Row>
        <Col span={24}>
          <SectionTitle title="Payment details" />
          <ContentCard>
            <Row>
              <Col span={21}>
                <DataDisplay label="Bank Institution" value={details?.bankName} />
              </Col>

              <Col span={3}>
                <UpdateButton title="Update" />
              </Col>

              <Col span={12}>
                <DataDisplay label="Account Holder" value={details?.accountHolder} />
              </Col>

              <Col span={6}>
                <DataDisplay label="BSB" value={details?.bsb} />
              </Col>

              <Col span={6}>
                <DataDisplay label="Account Number" value={details?.accountNumber} />
              </Col>
            </Row>
          </ContentCard>
        </Col>
      </Row>
    </Container>
  )
}
