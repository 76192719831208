import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { ReactComponent as X } from '@vega/components/src/assets/images/x.svg'

import { Modal, NumberField } from '@vega/components'

import { CardContainer, OutlinedBtn as OutlinedBtnBase, Subtitle } from '../../common'

const OutlinedBtn = styled(OutlinedBtnBase)(
  s('text-primary bg-secondary text-base font-semibold')
)

const Header = styled.div(s('flex flex-row mb-6'))
const CloseButton = styled.button(
  s('text-grey-700 border-transparent text-base pb-2 bg-transparent')
)

const ContactDetailsMobile = ({
  isOpen,
  setIsOpen,
  style,
  isLoading,
  ...otherProps
}) => {
  const onClose = () => setIsOpen(false)

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overlayStyle={s('bg-primary-background')}
      contentStyle={s('relative flex flex-column h-full w-full py-12 px-6')}
    >
      <Header>
        <CloseButton onClick={onClose}>
          <X style={s('text-primary')} />
        </CloseButton>
        <Subtitle>Update Phone Number </Subtitle>
      </Header>
      <CardContainer style={style} {...otherProps}>
        <NumberField
          name="phoneNumber"
          aria-label="phone number"
          label="Phone Number"
          format="### #### ####"
          placeholder="000 0000 0000"
          inputContainerStyle={s('bg-white')}
        />

        <OutlinedBtn type="button" style={s('mt-6 w-full')}>
          Update Phone Number
        </OutlinedBtn>
      </CardContainer>
    </Modal>
  )
}
export { ContactDetailsMobile }
