import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import { DataDisplay } from '@vega/components/src/v2'
import { PaymentProgressBar } from './PaymentProgressBar'
import { formatCurrency } from 'utils/formatter'
import { ReactComponent as ChevronRight } from '@vega/components/src/assets/images/chevron-right.svg'
import { routes } from 'routes'
import { useHistory } from 'react-router-dom'
import { REPAYMENT_TYPE_DISPLAY_TEXT } from '@neo/constants'

// TODO: add an onclick for this div once loan details is finished
const Root = styled.div(
  s('px-5 py-4'),
  ({ isSmallScreen }) => isSmallScreen && { '&:hover': s('bg-secondary rounded-lg') }
)

const LoanBalance = styled.div(
  s('text-base font-semibold pt-1 text-right'),
  ({ isLargeScreen }) => isLargeScreen && s('text-center')
)

const ViewLoanButton = styled.button(
  s(
    'bg-white border-1 border-primary rounded-lg text-primary font-semibold self-end text-center pt-1',
    { '&:hover': { cursor: 'pointer' } }
  )
)

export function LoanOverview({ loan = {}, isLargeScreen }) {
  const { displayName, repaymentType } = loan?.product || {}
  const { loanNumber, balance, loanAmount, _id } = loan
  const loanTermInYears = loan?.loanTermInMonths / 12 || '-'
  const history = useHistory()
  const handleMobileClick = (isSmallScreen) => {
    if (isSmallScreen) {
      return () => history.push(`${routes.customer.paymentSchedule.root}/${_id}`)
    }
    return null
  }
  return (
    <Root isSmallScreen={!isLargeScreen} onClick={handleMobileClick(!isLargeScreen)}>
      <Row gutter={[0, 0]} justify="center">
        <Col span={12} lg={8} xl={8}>
          <DataDisplay
            label={`${displayName || '-'} · ${loanTermInYears} years · ${
              REPAYMENT_TYPE_DISPLAY_TEXT[repaymentType]
            }`}
            value={loanNumber}
            labelStyle={s('text-black font-semibold')}
            valueStyle={s('text-grey-600 text-sm font-normal')}
          />
        </Col>

        <Col span={0} lg={6} xl={9}>
          <PaymentProgressBar loanBalance={balance} loanAmount={loanAmount} />
        </Col>

        <Col span={12} lg={8} xl={6}>
          <LoanBalance isLargeScreen={isLargeScreen}>
            {formatCurrency(balance, 2)}
          </LoanBalance>
        </Col>

        <Col span={0} lg={2} xl={1}>
          <div style={s('flex flex-row justify-end')}>
            <ViewLoanButton
              onClick={() =>
                history.push(`${routes.customer.paymentSchedule.root}/${_id}`)
              }
            >
              <ChevronRight />
            </ViewLoanButton>
          </div>
        </Col>
      </Row>
    </Root>
  )
}
