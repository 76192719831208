import { pipe, map, sum, unionWith, pluck } from '@solta/ramda-extra'

const generateDefaultMapData = map((location) => ({
  location,
  count: 0,
  percentage: 0,
}))

/**
 * ramda's unionWith is left bias, but we supply the data from the server last so we need to make it right bias so that the server data overrides the default one
 */
const rightUnionWith = (isSame, leftList) => (rightList) =>
  unionWith(isSame, rightList, leftList)

const roundToDecimalPlace = (numOfDecimal = 0, num) =>
  numOfDecimal === 0
    ? Math.round(num)
    : Math.round(num * 10 * numOfDecimal) / (numOfDecimal * 10)

export const generateMapData = (stat = [], citiesAndDistricts = []) => {
  const total = sum(Object.values(stat))

  return pipe(
    Object.entries,
    map(([location, count]) => ({
      location,
      count,
      percentage: total === 0 ? 0 : roundToDecimalPlace(1, (count / total) * 100),
    })),
    rightUnionWith(
      (a, b) => a.location === b.location,
      generateDefaultMapData(citiesAndDistricts)
    )
  )(stat)
}

export const useMortgageMapData = (
  mortgageCountByLocation = [],
  citiesAndDistricts = []
) => {
  const data = generateMapData(mortgageCountByLocation, citiesAndDistricts)
  const allCounts = pluck('count', data)
  const minValue = Math.min(...allCounts)
  const maxValue = Math.max(...allCounts)

  return { data, minValue, maxValue }
}
