import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import NewProduct from './NewProduct'
import ProductDetails from './ProductDetails'
import Overview from './Overview'
import { routes } from 'routes'

const productRoutes = routes.admin.products

function ProductRouter() {
  return (
    <Switch>
      <Route exact path={productRoutes.overview} component={Overview} />
      <Route exact path={productRoutes.newProduct} component={NewProduct} />
      <Route path={productRoutes.productDetails} component={ProductDetails} />
      <Redirect to={productRoutes.overview} />
    </Switch>
  )
}

export default ProductRouter
