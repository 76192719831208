import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { styled, s } from '@vega/styled/v2'
import { Link as LinkBase } from 'react-router-dom'
import { useLocation, useParams } from 'react-router'
import { LEGAL_ENTITY } from '@neo/constants'
import { fetchLoan, selectLoanDetails } from 'modules/mortgageAccounts'
import { selectAllLegalEntities } from 'modules/legalEntity'
import { routes } from 'routes'
import { isGuarantorEntity } from './common'
import { dispatch } from '@solta/ramda-extra'

const {
  TYPE: { INDIVIDUAL },
} = LEGAL_ENTITY

const loanRoutes = routes.admin.loans

const NavItemList = styled.ol(
  s('text-sm m-0 py-3 px-0 rounded-lg border-1 border-solid border-grey-200 bg-white', {
    minWidth: 202,
    overflow: 'hidden',
    listStyleType: 'none',
  })
)

const NavItemTitle = styled.li(
  s('text-sm py-3 pl-4 text-grey-800 bg-white w-full', { height: 40 }),
  ({ isActive }) => isActive && s('bg-secondary font-semibold ')
)

const Link = styled(LinkBase)(s('no-decoration'))

function LegalEntitiesNavigation() {
  const { id } = useParams()
  const { pathname } = useLocation()

  const legalEntities = useSelector(selectAllLegalEntities) ?? []
  const loanDetails = useSelector(selectLoanDetails) ?? {}
  const individualEntities = legalEntities.filter(
    (entity) =>
      entity?.type === INDIVIDUAL && !isGuarantorEntity(loanDetails, entity?.id)
  )

  const navListHeight = individualEntities.length * 40 + 104

  const checkIsActive = (route) => {
    const isTheSameRoute = pathname === route
    return isTheSameRoute
  }

  const insertId = (url = '') => url.replace(':id', id)

  useEffect(() => {
    dispatch(fetchLoan(id))
  }, [id])

  return (
    <NavItemList style={{ maxHeight: navListHeight }}>
      {individualEntities.map((entity, index) => (
        <Link
          key={index}
          to={insertId(loanRoutes.entityDetails.individuals).replace(
            ':entityId',
            entity.id
          )}
        >
          <NavItemTitle
            isActive={checkIsActive(
              insertId(loanRoutes.entityDetails.individuals).replace(
                ':entityId',
                entity.id
              )
            )}
          >
            {entity.name}
          </NavItemTitle>
        </Link>
      ))}

      <Link to={insertId(loanRoutes.entityDetails.trusts)}>
        <NavItemTitle
          isActive={checkIsActive(insertId(loanRoutes.entityDetails.trusts))}
        >
          Trust(s)
        </NavItemTitle>
      </Link>

      <Link to={insertId(loanRoutes.entityDetails.businesses)}>
        <NavItemTitle
          isActive={checkIsActive(insertId(loanRoutes.entityDetails.businesses))}
        >
          Business(es)
        </NavItemTitle>
      </Link>
    </NavItemList>
  )
}

export default LegalEntitiesNavigation
