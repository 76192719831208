import React from 'react'
import { useLargeMediaQuery } from '@vega/styled'
import { DataDisplay, DateBox as DueDateBox } from '@vega/components/src/v2'
import { s } from '@vega/styled/v2'
import { formatCurrency } from 'utils/formatter'
import { REPAYMENT_TYPE_DISPLAY_TEXT } from '@neo/constants'

const getRepaymentTypeDisplay = (repaymentType) => {
  return REPAYMENT_TYPE_DISPLAY_TEXT[repaymentType]
}

// TODO: this component appears to be duplicated with the PaymentCard in
// the payment centre section
/* eslint-disable */
function PaymentCard({ loan, payment }) {
  const { displayName, repaymentType } = loan?.product || {}
  const { dueDate, amount } = payment || {}
  const loanTermInYears = loan?.loanTermInMonths / 12
  const isLargeScreen = useLargeMediaQuery('larger')

  return (
    <div style={s('flex my-3')}>
      <DueDateBox date={new Date(dueDate)} />
      <div style={{flexGrow: 1}}>
        {
          !isLargeScreen && (
            <div style={s('ml-3')}>{formatCurrency(amount, 2)}</div>
          )
        }
        <DataDisplay
          label={`${
            displayName || '-'
          } · ${loanTermInYears} years · ${getRepaymentTypeDisplay(repaymentType)}`}
          value={loan?.loanNumber}
          labelStyle={s('text-black font-semibold ml-3')}
          valueStyle={s('text-grey-600 text-sm font-normal ml-3')}
        />
      </div>

      {
        isLargeScreen && (
          <div style={s('text-right')}>{formatCurrency(amount, 2)}</div>
        )
      }
    </div>
  )
}

export default PaymentCard
