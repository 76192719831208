import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class ActivityLogService extends BaseService {
  search({ refId, limit, start }, signal) {
    const queryParams = QueryBuilder()
      .setParam('refId', refId)
      .setPaging({ start, limit })
      .build()

    return this.client.get(`activity-logs?${queryParams}`, { signal }).json()
  }
}
