export const productFilterOptions = [
  { key: 'mortgage', label: 'Mortgage' },
  { key: 'car', label: 'Car' },
  { key: 'personal', label: 'Personal' },
]

export const interestFilterOptions = [
  { key: 'floating', label: 'Floating' },
  { key: 'fixed', label: 'Fixed' },
  { key: 'combo', label: 'Combo' },
]

export const statusFilterOptions = [
  {
    key: 'active',
    label: 'Active',
  },
  {
    key: 'archived',
    label: 'Archived',
  },
]

export const interestRateFilterOptions = [
  { key: 'rate1', label: '1.00% - 1.99%' },
  { key: 'rate2', label: '2.00% - 2.99%' },
  { key: 'rate3', label: '3.00% - 3.99%' },
  { key: 'rate4', label: '4.00% - 4.99%' },
  { key: 'rate5', label: '5.00% - 5.99%' },
  { key: 'rate6', label: '6.00% - 6.99%' },
  { key: 'rate7', label: '7.00% - 7.99%' },
  { key: 'rate8', label: '8.00% - 8.99%' },
  { key: 'rate9', label: '9.00% and above' },
]
