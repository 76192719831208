export const searchOptions = [
  {
    key: 'entitiesDetails.name',
    label: 'Customer',
    searchLabel: 'Search Customers',
  },
  {
    key: 'loanNumber',
    label: 'Loan No.',
    searchLabel: 'Search loan number',
  },
  {
    key: 'loanGroup.groupNumber',
    label: 'Loan Group No.',
    searchLabel: 'Search loan group number',
  },
  {
    key: 'product.displayName',
    label: 'Loan Product',
    searchLabel: 'Search loan product',
  },
]

export const loanOptions = [
  {
    key: '',
    label: 'All',
  },
  {
    key: 'requested',
    label: 'Requested',
  },
  {
    key: 'pending',
    label: 'Pending',
  },
  {
    key: 'approved',
    label: 'Approved',
  },
]
