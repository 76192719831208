import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { styled, s } from '@vega/styled/v2'

const isPrimaryEntity = (loan, entityId) => {
  const primaryEntities = loan?.entities?.filter(({ isPrimary }) => isPrimary === true)

  return !!primaryEntities?.find((en) => en.legalEntityId === entityId)
}

const isGuarantorEntity = (loan, entityId) => {
  const guarantorEntities = loan?.entities?.filter(({ role }) => role === 'guarantor')

  return !!guarantorEntities?.find((en) => en.legalEntityId === entityId)
}
const isBorrowerEntity = (loan, entityId) => {
  const borrowersEntities = loan?.entities?.filter(
    ({ role, isPrimary }) => role === 'borrower' && isPrimary === false
  )

  return !!borrowersEntities?.find((en) => en.legalEntityId === entityId)
}

const hasOnlineAccess = (invitation, individual) =>
  isNotNilOrEmpty(invitation) && isNotNilOrEmpty(individual?.customerNumber)

const AccessedPill = styled.div(
  s(
    'rounded-3xl bg-accent-red flex items-center px-2 text-grey-900 text-xs font-normal h-1',
    {
      letterSpacing: '0.015em',
      lineHeight: 1.5,
      paddingBottom: 2,
      paddingTop: 2,
    }
  ),
  ({ hasOnlineAccess }) => hasOnlineAccess && s('bg-secondary')
)
const PrimaryPill = styled.div(
  s(
    'rounded-3xl bg-secondary flex items-center px-2 text-grey-900 text-xs font-normal h-1 ml-4 ',
    {
      letterSpacing: '0.015em',
      lineHeight: 1.5,
      paddingBottom: 2,
      paddingTop: 2,
    }
  )
)
const InvitedPill = styled.div(
  s(
    'rounded-3xl bg-grey-100 flex items-center px-2 text-grey-900 text-xs font-normal h-1 ml-4',
    {
      letterSpacing: '0.015em',
      lineHeight: 1.5,
      paddingBottom: 2,
      paddingTop: 2,
    }
  )
)
const GuarantorPill = styled.div(
  s(
    'rounded-3xl bg-accent-greygreen flex items-center px-2 text-grey-900 text-xs font-normal h-1 ml-4 ',
    {
      letterSpacing: '0.015em',
      lineHeight: 1.5,
      paddingBottom: 2,
      paddingTop: 2,
    }
  )
)
const BorrowerPill = styled.div(
  s(
    'rounded-3xl bg-blue-lighter flex items-center px-2 text-grey-900 text-xs font-normal h-1 ml-4 ',
    {
      letterSpacing: '0.015em',
      lineHeight: 1.5,
      paddingBottom: 2,
      paddingTop: 2,
    }
  )
)

export {
  isPrimaryEntity,
  isGuarantorEntity,
  hasOnlineAccess,
  isBorrowerEntity,
  AccessedPill,
  PrimaryPill,
  InvitedPill,
  GuarantorPill,
  BorrowerPill,
}
