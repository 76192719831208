import React from 'react'
import { Formik as FormProvider, Form as FormBase } from 'formik'
import { Row, Col } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { useHidePersistentNavDrawer } from 'hooks'
import { ReactComponent as XIconBase } from '@vega/components/src/assets/images/x.svg'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes'
import {
  TextField as TextFieldBase,
  DateField as DateFieldBase,
  FormTextarea,
  NumberField as NumberFieldBase,
  CheckboxField,
  Button as ButtonBase,
  SelectField as SelectFieldBase,
  MultiSelectField,
  toast,
} from '@vega/components'
import { PRODUCT_CONSTANTS } from '@neo/constants'
import neoLogo from '@vega/components/src/assets/images/neo-logo.png'
import * as Validator from 'yup'
import { isNotNilOrEmpty, isNil } from '@solta/ramda-extra'
import { createProduct } from 'modules/product'
import { useDispatch } from 'react-redux'
import { parseDate } from 'utils/date'
import { isBefore } from 'date-fns'

const {
  ASSET_TYPE,
  OCCUPANCY_TYPE,
  DOCUMENTATION_LEVEL,
  INTEREST_TYPE,
  REPAYMENT_TYPE,
  REPAYMENT_FREQUENCY,
  OCCUPANCY_TYPE_DISPLAY_TEXT,
} = PRODUCT_CONSTANTS

const ASSET_TYPE_DISPLAY_VALUE = {
  [ASSET_TYPE.ESTABLISHED]: 'Established',
  [ASSET_TYPE.NEW_BUILDING]: 'New Building',
  [ASSET_TYPE.OFF_THE_PLAN]: 'Off the Plan',
  [ASSET_TYPE.VACANT_LAND]: 'Vacant Land',
}

const DOCUMENTATION_LEVEL_DISPLAY_VALUE = {
  [DOCUMENTATION_LEVEL.FULL]: 'Full Documentation',
  [DOCUMENTATION_LEVEL.LOW]: 'Low Documentation',
  [DOCUMENTATION_LEVEL.NONE]: 'No Documentation',
}

const INTEREST_TYPE_DISPLAY_VALUE = {
  [INTEREST_TYPE.FIXED]: 'Fixed',
  [INTEREST_TYPE.VARIABLE]: 'Variable',
  [INTEREST_TYPE.COMBO]: 'Combo',
}

const REPAYMENT_TYPE_DISPLAY_VALUE = {
  [REPAYMENT_TYPE.INTEREST_ONLY]: 'Interest Only',
  [REPAYMENT_TYPE.INTEREST_IN_ADVANCE]: 'Interest in Advance',
  [REPAYMENT_TYPE.PRINCIPAL_AND_INTEREST]: 'Principal and Interest',
  [REPAYMENT_TYPE.LINE_OF_CREDIT]: 'Line of Credit',
}

const REPAYMENT_FREQUENCY_DISPLAY_VALUE = {
  [REPAYMENT_FREQUENCY.ONE_OFF]: 'One-off',
  [REPAYMENT_FREQUENCY.DAILY]: 'Daily',
  [REPAYMENT_FREQUENCY.WEEKLY]: 'Weekly',
  [REPAYMENT_FREQUENCY.FORTNIGHTLY]: 'Fortnightly',
  [REPAYMENT_FREQUENCY.MONTHLY]: 'Monthly',
  [REPAYMENT_FREQUENCY.QUARTERLY]: 'Quarterly',
  [REPAYMENT_FREQUENCY.BIANNUALLY]: 'Biannually',
  [REPAYMENT_FREQUENCY.ANNUALLY]: 'Annually',
  [REPAYMENT_FREQUENCY.SEASONAL]: 'Seasonal',
}

const Root = styled.div(
  s('flex flex-column flex-1 py-8 bg-white', {
    overflow: 'hidden',
  })
)
const Header = styled(Row)(s('items-center px-16 mb-4'))
const XIcon = styled(XIconBase)({ '&:hover': { cursor: 'pointer' } })
const Img = styled.img(s('w-5 ml-10'))

const Scrollable = styled(Row)(s('flex-1 justify-center px-16', { overflow: 'auto' }))
const Form = styled(FormBase)(s('flex flex-column flex-1', { maxWidth: '912px' }))

const Title = styled.h3(s('font-medium text-2xl m-0 my-8 text-grey-800'), {
  fontSize: 28,
  letterSpacing: '-0.02em',
})
const SubTitle = styled.h6(s('font-medium text-xl my-6 text-grey-800'), {
  letterSpacing: '-O.02em',
})

const CheckboxLabel = styled.div(s('text-sm font-medium text-grey-800 mb-3'))

const YSeparator = styled.div({ minHeight: 20 })

const TextField = styled((props) => (
  <TextFieldBase containerStyle={s('flex-1', { lineHeight: 1.2 })} {...props} />
))()

const NumberField = styled((props) => (
  <NumberFieldBase containerStyle={s('flex-1 ', { lineHeight: 1.2 })} {...props} />
))()

const DateField = styled((props) => (
  <DateFieldBase containerProps={{ style: s('flex flex-column flex-1') }} {...props} />
))()

const SelectField = styled(SelectFieldBase)(s('flex-1'))

const Button = styled(ButtonBase)({ height: 56 })

const { object, array, string, number, boolean } = Validator

const requiredString = string().required('This is a required field')
const requiredNumber = number().required('This is a required field')

const validationSchema = object({
  name: requiredString,
  displayName: requiredString,
  code: requiredString,
  notes: string(),

  fields: object({
    assetType: requiredString,
    occupancyType: requiredString,
    documentationLevel: requiredString,
    validFrom: requiredString.when(['validTo'], {
      is: (validTo) => isNotNilOrEmpty(validTo),
      then: string().test(
        'is-before-endDate',
        'Must be before valid to date',
        (validFrom, context) => {
          if (isNotNilOrEmpty(validFrom)) {
            return isBefore(parseDate(validFrom), parseDate(context.parent.validTo))
          }
          return true
        }
      ),
    }),
    validTo: requiredString,

    annualPercentageRate: requiredNumber.min(0),
    availableRepaymentFrequencies: array()
      .of(string())
      .min(1, 'This is a required field'),
    initialRate: requiredNumber.min(0),
    initialRateType: requiredString,
    ongoingRate: requiredNumber.min(0),
    ongoingRateType: requiredString,
    initialRepaymentType: requiredString,
    ongoingRepaymentType: requiredString,

    minLoanAmount: requiredNumber.min(0),
    maxLoanAmount: requiredNumber.min(0),
    freeMonthlyTransactions: requiredNumber.min(0).integer(),
    minimumRedraw: requiredNumber.min(0),
    minLvr: requiredNumber.min(0).integer(),
    maxLvr: requiredNumber.min(0).max(100).integer(),
    minTerm: requiredNumber.min(0).integer(),
    maxTerm: requiredNumber.min(0).integer(),
    initialPeriod: requiredNumber.min(0).integer(),
    maxLoanSplits: requiredNumber.min(0).integer(),
    maxTermForInterestOnly: number().integer(),
    revertRateForInterestOnly: number().min(0),

    earlyRepaymentNotes: string(),
    breakConditionNotes: string(),
    genuineSavingsNotes: string(),
    lvrNotes: string(),

    isOffsetAccount: boolean(),
    isNonConfirming: boolean(),
    isRedrawAvailable: boolean(),
    isDirectSalaryCredit: boolean(),
    isChequeAccount: boolean(),
    isProfessionalPackAvailable: boolean(),
    isPortable: boolean(),
    isBPayOption: boolean(),
    isCreditCard: boolean(),
    isTelephoneBanking: boolean(),
    isInternetBanking: boolean(),
    isGenuineSavingsRequired: boolean(),
    isLMICapitalize: boolean(),
    isIntroRate: boolean(),

    totalUpfrontFees: number().min(0),
    otherBorrowingFees: number().min(0),
    upfrontFee: number().min(0),
    settlementFee: number().min(0),
    legalFee: number().min(0),
    onGoingAnnualFee: number().min(0),
    onGoingMonthlyFee: number().min(0),
    valuationFee: number().min(0),
    exitFee: number().min(0),
    applicationFee: number().min(0),
    switchToFixedFee: number().min(0),
    establishmentFee: number().min(0),
    loanRedrawFee: number().min(0),
    ongoingFeeNotes: string(),
    otherFeeNotes: string(),

    hasTargetMarketDetermination: boolean(),
    targetMarketDetermination: object({
      title: string(),
      url: string(),
    }),
    initialBaseRateReference: string(),
    ongoingBaseRateReference: string(),
  }),
})

function NewProduct() {
  const history = useHistory()
  const dispatch = useDispatch()

  useHidePersistentNavDrawer()

  function handleExit() {
    history.push(routes.admin.products.overview)
  }

  async function handleSubmit(values) {
    const { error } = await dispatch(createProduct(values))

    if (isNil(error)) {
      toast('New Product has been added.')

      handleExit()
    }
  }

  return (
    <Root>
      <Header>
        <XIcon onMouseDown={handleExit} />
        <Img src={neoLogo} />
      </Header>

      <Scrollable>
        <FormProvider
          initialValues={{
            name: '',
            displayName: '',
            code: '',
            notes: '',

            fields: {
              assetType: undefined,
              occupancyType: undefined,
              documentationLevel: undefined,
              validFrom: undefined,
              validTo: undefined,

              annualPercentageRate: undefined,
              availableRepaymentFrequencies: [],
              initialRate: undefined,
              initialRateType: undefined,
              ongoingRate: undefined,
              ongoingRateType: undefined,
              initialRepaymentType: undefined,
              ongoingRepaymentType: undefined,

              minLoanAmount: undefined,
              maxLoanAmount: undefined,
              freeMonthlyTransactions: undefined,
              minimumRedraw: undefined,
              minLvr: undefined,
              maxLvr: undefined,
              minTerm: undefined,
              maxTerm: undefined,
              initialPeriod: undefined,
              maxLoanSplits: undefined,
              maxTermForInterestOnly: undefined,
              revertRateForInterestOnly: undefined,

              earlyRepaymentNotes: '',
              breakConditionNotes: '',
              genuineSavingsNotes: '',
              lvrNotes: '',

              isOffsetAccount: false,
              isNonConfirming: false,
              isRedrawAvailable: false,
              isDirectSalaryCredit: false,
              isChequeAccount: false,
              isProfessionalPackAvailable: false,
              isPortable: false,
              isBPayOption: false,
              isCreditCard: false,
              isTelephoneBanking: false,
              isInternetBanking: false,
              isGenuineSavingsRequired: false,
              isLMICapitalize: false,
              isIntroRate: false,

              totalUpfrontFees: undefined,
              otherBorrowingFees: undefined,
              upfrontFee: undefined,
              settlementFee: undefined,
              legalFee: undefined,
              onGoingAnnualFee: undefined,
              onGoingMonthlyFee: undefined,
              valuationFee: undefined,
              exitFee: undefined,
              applicationFee: undefined,
              switchToFixedFee: undefined,
              establishmentFee: undefined,
              loanRedrawFee: undefined,
              ongoingFeeNotes: '',
              otherFeeNotes: '',
            },
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values: formValues }) => (
            <Form>
              <Title>Create a product</Title>

              <SubTitle style={s('mt-0')}>Product info</SubTitle>

              <Row>
                <Col span={12}>
                  <TextField
                    name="name"
                    label="Product name"
                    placeholder="e.g. Mortgage July 2021"
                  />
                </Col>

                <Col span={12}>
                  <TextField
                    name="displayName"
                    label="Display name"
                    placeholder="e.g. Mortgage July 2021"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <TextField
                    name="code"
                    label="Product code"
                    placeholder="e.g. MOR00001"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={24}>
                  <FormTextarea
                    name="notes"
                    label="Notes (optional)"
                    placeholder="Add your notes"
                  />
                </Col>
              </Row>

              <SubTitle>Product details</SubTitle>

              <Row>
                <Col span={12}>
                  <DateField
                    name="fields.validFrom"
                    label="When is this product valid from?"
                    placeholder="DD/MM/YYYY"
                  />
                </Col>

                <Col span={12}>
                  <DateField
                    name="fields.validTo"
                    label="When is this product valid to?"
                    placeholder="DD/MM/YYYY"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <SelectField
                    name="fields.assetType"
                    label="What type of asset is this product?"
                    labelContainerStyle={s('mb-1')}
                    options={Object.entries(ASSET_TYPE).map(([, value]) => ({
                      label: ASSET_TYPE_DISPLAY_VALUE[value],
                      value,
                    }))}
                  />
                </Col>

                <Col span={12}>
                  <SelectField
                    name="fields.occupancyType"
                    label="Who will occupy this product?"
                    labelContainerStyle={s('mb-1')}
                    options={Object.entries(OCCUPANCY_TYPE).map(([, value]) => ({
                      label: OCCUPANCY_TYPE_DISPLAY_TEXT[value],
                      value,
                    }))}
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <SelectField
                    name="fields.documentationLevel"
                    label="How comprehensive will the documentation need to be?"
                    labelContainerStyle={s('mb-1')}
                    options={Object.entries(DOCUMENTATION_LEVEL).map(([, value]) => ({
                      label: DOCUMENTATION_LEVEL_DISPLAY_VALUE[value],
                      value,
                    }))}
                  />
                </Col>

                <Col span={12} />
              </Row>

              <YSeparator />

              <SubTitle>Rates & repayments</SubTitle>

              <Row>
                <Col span={12}>
                  <NumberField
                    name="fields.initialRate"
                    label="What is the initial rate?"
                    placeholder="e.g. 2.40"
                    suffix="%"
                  />
                </Col>

                <Col span={12}>
                  <SelectField
                    name="fields.initialRateType"
                    label="What is the interest type for the initial rate?"
                    labelContainerStyle={s('mb-1')}
                    options={Object.entries(INTEREST_TYPE).map(([, value]) => ({
                      label: INTEREST_TYPE_DISPLAY_VALUE[value],
                      value,
                    }))}
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <NumberField
                    name="fields.ongoingRate"
                    label="What is the ongoing rate?"
                    placeholder="e.g. 2.40"
                    suffix="%"
                  />
                </Col>

                <Col span={12}>
                  <SelectField
                    name="fields.ongoingRateType"
                    label="What is the interest type for the ongoing rate?"
                    labelContainerStyle={s('mb-1')}
                    options={Object.entries(INTEREST_TYPE).map(([, value]) => ({
                      label: INTEREST_TYPE_DISPLAY_VALUE[value],
                      value,
                    }))}
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <SelectField
                    name="fields.initialRepaymentType"
                    label="What is the initial repayment type?"
                    labelContainerStyle={s('mb-1')}
                    options={Object.entries(REPAYMENT_TYPE).map(([, value]) => ({
                      label: REPAYMENT_TYPE_DISPLAY_VALUE[value],
                      value,
                    }))}
                  />
                </Col>

                <Col span={12}>
                  <SelectField
                    name="fields.ongoingRepaymentType"
                    label="What is the ongoing repayment type?"
                    labelContainerStyle={s('mb-1')}
                    options={Object.entries(REPAYMENT_TYPE).map(([, value]) => ({
                      label: REPAYMENT_TYPE_DISPLAY_VALUE[value],
                      value,
                    }))}
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <MultiSelectField
                    name="fields.availableRepaymentFrequencies"
                    items={Object.values(REPAYMENT_FREQUENCY).map((option) => ({
                      key: option,
                      label: REPAYMENT_FREQUENCY_DISPLAY_VALUE[option],
                    }))}
                    label="Available repayment frequencies"
                    placeholder="Please select"
                  />
                </Col>

                <Col span={12}>
                  <NumberField
                    name="fields.annualPercentageRate"
                    label="Annual percentage rate"
                    placeholder="e.g. 2.40"
                    suffix="%"
                  />
                </Col>
              </Row>

              <YSeparator />

              <SubTitle>Loan parameters</SubTitle>

              <Row>
                <Col span={12}>
                  <NumberField
                    name="fields.minLoanAmount"
                    label="Minimum loan amount?"
                    prefix="$"
                  />
                </Col>

                <Col span={12}>
                  <NumberField
                    name="fields.maxLoanAmount"
                    label="Maximum loan amount"
                    prefix="$"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <NumberField
                    name="fields.minLvr"
                    label="Minimum LVR"
                    placeholder="e.g. 20"
                    suffix="%"
                  />
                </Col>

                <Col span={12}>
                  <NumberField
                    name="fields.maxLvr"
                    label="Maximum LVR"
                    placeholder="e.g. 80"
                    suffix="%"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <NumberField
                    name="fields.minTerm"
                    label="Minimum loan term (months)"
                    placeholder="e.g. 30"
                  />
                </Col>

                <Col span={12}>
                  <NumberField
                    name="fields.maxTerm"
                    label="Maximum loan term (months)"
                    placeholder="e.g. 30"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <NumberField
                    name="fields.initialPeriod"
                    label="Initial period (months)"
                    placeholder="e.g. 10"
                  />
                </Col>

                <Col span={12}>
                  <NumberField
                    name="fields.maxLoanSplits"
                    label="Maximum loan splits"
                    placeholder="e.g. 50"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <NumberField
                    name="fields.freeMonthlyTransactions"
                    label="Free monthly transactions"
                    placeholder="e.g. 10"
                  />
                </Col>
                <Col span={12}>
                  <NumberField
                    name="fields.minimumRedraw"
                    label="Minimum redraw"
                    prefix="$"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <NumberField
                    name="fields.maxTermForInterestOnly"
                    label="Maximum term for interest-only repayments (months) (optional)"
                    placeholder="e.g. 10"
                  />
                </Col>

                <Col span={12}>
                  <NumberField
                    name="fields.revertRateForInterestOnly"
                    label="Revert rate for interest-only repayments"
                    placeholder="e.g. 2.0"
                    suffix="%"
                  />
                </Col>
              </Row>

              <YSeparator />

              <SubTitle>Notes</SubTitle>

              <Row>
                <Col span={24}>
                  <FormTextarea
                    name="fields.earlyRepaymentNotes"
                    label="Early repayment notes (optional)"
                    placeholder="Add your notes"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={24}>
                  <FormTextarea
                    name="fields.breakConditionNotes"
                    label="Break condition notes (optional)"
                    placeholder="Add your notes"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={24}>
                  <FormTextarea
                    name="fields.genuineSavingsNotes"
                    label="Genuine savings notes (optional)"
                    placeholder="Add your notes"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={24}>
                  <FormTextarea
                    name="fields.lvrNotes"
                    label="LVR notes (optional)"
                    placeholder="Add your notes"
                  />
                </Col>
              </Row>

              <YSeparator />

              <SubTitle>Options</SubTitle>

              <Row>
                <Col span={12}>
                  <CheckboxLabel>Allow offset account?</CheckboxLabel>
                  <CheckboxField name="fields.isOffsetAccount">
                    Yes, allow it
                  </CheckboxField>
                </Col>

                <Col span={12}>
                  <CheckboxLabel>Allow non confirming?</CheckboxLabel>
                  <CheckboxField name="fields.isNonConfirming">
                    Yes, allow it
                  </CheckboxField>
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <CheckboxLabel>Is redraw available?</CheckboxLabel>
                  <CheckboxField name="fields.isRedrawAvailable">
                    Yes, it is
                  </CheckboxField>
                </Col>

                <Col span={12}>
                  <CheckboxLabel>Allow direct salary credit?</CheckboxLabel>
                  <CheckboxField name="fields.isDirectSalaryCredit">
                    Yes, allow it
                  </CheckboxField>
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <CheckboxLabel>Allow cheque account?</CheckboxLabel>
                  <CheckboxField name="fields.isChequeAccount">
                    Yes, allow it
                  </CheckboxField>
                </Col>

                <Col span={12}>
                  <CheckboxLabel>Is professional pack available?</CheckboxLabel>
                  <CheckboxField name="fields.isProfessionalPackAvailable">
                    Yes, it is
                  </CheckboxField>
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <CheckboxLabel>Allow portable?</CheckboxLabel>
                  <CheckboxField name="fields.isPortable">Yes, allow it</CheckboxField>
                </Col>

                <Col span={12}>
                  <CheckboxLabel>Allow BPay option?</CheckboxLabel>
                  <CheckboxField name="fields.isBPayOption">
                    Yes, allow it
                  </CheckboxField>
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <CheckboxLabel>Allow credit card?</CheckboxLabel>
                  <CheckboxField name="fields.isCreditCard">
                    Yes, allow it
                  </CheckboxField>
                </Col>

                <Col span={12}>
                  <CheckboxLabel>Allow telephone banking?</CheckboxLabel>
                  <CheckboxField name="fields.isTelephoneBanking">
                    Yes, allow it
                  </CheckboxField>
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <CheckboxLabel>Allow internet banking?</CheckboxLabel>
                  <CheckboxField name="fields.isInternetBanking">
                    Yes, allow it
                  </CheckboxField>
                </Col>

                <Col span={12}>
                  <CheckboxLabel>Allow LMI capitalise?</CheckboxLabel>
                  <CheckboxField name="fields.isLMICapitalize">
                    Yes, allow it
                  </CheckboxField>
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <CheckboxLabel>Is genuine savings required?</CheckboxLabel>
                  <CheckboxField name="fields.isGenuineSavingsRequired">
                    Yes, it is
                  </CheckboxField>
                </Col>

                <Col span={12}>
                  <CheckboxLabel>Allow intro rate?</CheckboxLabel>
                  <CheckboxField name="fields.isIntroRate">Yes, allow it</CheckboxField>
                </Col>
              </Row>

              <YSeparator />

              <SubTitle>Fees</SubTitle>

              <Row>
                <Col span={12}>
                  <NumberField
                    name="fields.upfrontFee"
                    label="Upfront fee"
                    prefix="$"
                  />
                </Col>

                <Col span={12}>
                  <NumberField
                    name="fields.totalUpfrontFees"
                    label="Total upfront fees"
                    prefix="$"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <NumberField
                    name="fields.settlementFee"
                    label="Settlement fee"
                    placeholder="e.g. 2.0"
                    prefix="&"
                  />
                </Col>

                <Col span={12}>
                  <NumberField
                    name="fields.otherBorrowingFees"
                    label="Other borrowing fees"
                    prefix="$"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <NumberField name="fields.legalFee" label="Legal fee" prefix="$" />
                </Col>

                <Col span={12}>
                  <NumberField
                    name="fields.onGoingAnnualFee"
                    label="Ongoing annual fee"
                    prefix="$"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <NumberField
                    name="fields.onGoingMonthlyFee"
                    label="Ongoing monthly fee"
                    prefix="$"
                  />
                </Col>

                <Col span={12}>
                  <NumberField
                    name="fields.valuationFee"
                    label="Valuation fee"
                    prefix="$"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <NumberField name="fields.exitFee" label="Exit fee" prefix="$" />
                </Col>

                <Col span={12}>
                  <NumberField
                    name="fields.applicationFee"
                    label="Application fee"
                    prefix="$"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <NumberField
                    name="fields.switchToFixedFee"
                    label="Switch-to-fixed fee"
                    prefix="$"
                  />
                </Col>
                <Col span={12}>
                  <NumberField
                    name="fields.establishmentFee"
                    label="Establishment fee"
                    prefix="$"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <NumberField
                    name="fields.loanRedrawFee"
                    label="Loan redraw fee"
                    prefix="$"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={24}>
                  <FormTextarea
                    name="fields.ongoingFeeNotes"
                    label="Ongoing fee notes (optional)"
                    placeholder="Add your description"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={24}>
                  <FormTextarea
                    name="fields.otherFeeNotes"
                    label="Other fee notes (optional)"
                    placeholder="Add your description"
                  />
                </Col>
              </Row>

              <YSeparator />

              <SubTitle>Miscellaneous</SubTitle>

              <Row>
                <Col span={24}>
                  <CheckboxLabel>
                    Does this product have a target market determination?
                  </CheckboxLabel>

                  <CheckboxField name="fields.hasTargetMarketDetermination">
                    Yes, it does
                  </CheckboxField>
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <TextField
                    readOnly={!formValues.hasTargetMarketDetermination}
                    name="fields.targetMarketDetermination.title"
                    label="Target market determination title"
                    placeholder="e.g. Land Loan"
                  />
                </Col>

                <Col span={12}>
                  <TextField
                    readOnly={!formValues.hasTargetMarketDetermination}
                    name="fields.targetMarketDetermination.url"
                    label="Target market determination URL"
                    placeholder="https://google.com"
                  />
                </Col>
              </Row>

              <YSeparator />

              <Row>
                <Col span={12}>
                  <FormTextarea
                    name="fields.initialBaseRateReference"
                    label="Initial base rate reference"
                    placeholder="Add your notes"
                  />
                </Col>

                <Col span={12}>
                  <FormTextarea
                    name="fields.ongoingBaseRateReference"
                    label="Ongoing base rate reference"
                    placeholder="Add your notes"
                  />
                </Col>
              </Row>

              <Row style={s('mt-12')}>
                <Col span={24}>
                  <Button type="submit" loading={isSubmitting} style={s('w-full')}>
                    Create product
                  </Button>

                  <YSeparator />

                  <Button
                    variant="outlined"
                    style={s('w-full border-grey-400')}
                    onClick={handleExit}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </FormProvider>
      </Scrollable>
    </Root>
  )
}

export default NewProduct
