/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useHistory } from 'react-router-dom'
import { styled, s } from '@vega/styled/v2'
import { ReactComponent as QuestionMarkIcon } from '@vega/components/src/assets/images/question-mark-circle.svg'
import { ReactComponent as SettingIcon } from '@vega/components/src/assets/images/gear.svg'
import { ReactComponent as CardsIcon } from '@vega/components/src/assets/images/two-cards.svg'
import { ReactComponent as TalkIcon } from '@vega/components/src/assets/images/conversation.svg'
import { ReactComponent as NewLoanIcon } from '@vega/components/src/assets/images/new-doc.svg'
import { ReactComponent as FeedbackIcon } from '@vega/components/src/assets/images/feedback.svg'
import { routes } from 'routes'

const IconButton = styled.div(
  s('bg-primary inline-block rounded-lg flex items-center justify-center p-1')
)

const LinkItem = ({ label, Icon, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={s('flex items-center py-4', { cursor: 'pointer' })}
    >
      <IconButton style={s('mr-3')}>
        <Icon
          style={s('text-white', { pointerEvents: 'none' })}
          width={18}
          height={18}
        />
      </IconButton>

      <span style={s('text-primary font-md text-black')}>
        {label}
      </span>
    </div>
  )
}

const toExternalUrl = (url) => window.open(url, '_blank')

const Container = styled.div(
  s('bg-white rounded-lg px-6'),
  {
    '& > div:not(:last-child)': s('border-solid border-0 border-b-1', { borderColor: '#E4E6E6', }),
  }
)

export const HelpLinkCard = () => {
  const history = useHistory()

  return (
    <Container>
      <LinkItem
        label="FAQs"
        Icon={QuestionMarkIcon}
        onClick={() => toExternalUrl('https://www.neo.nz/faq/contact-us/#FAQ')}
      />
      <LinkItem
        label="How do I make a payment"
        Icon={CardsIcon}
        onClick={() => toExternalUrl('https://www.neo.nz/how-do-i-make-a-payment')}
      />
      <LinkItem
        label="Change my settings"
        Icon={SettingIcon}
        onClick={() => history.push(routes.customer.account.root)}
      />
      <LinkItem
        label="I need to talk to someone"
        Icon={TalkIcon}
        onClick={() => toExternalUrl('https://www.neo.nz/faq/contact-us')}
      />
      <LinkItem
        label="Apply for a new loan"
        Icon={NewLoanIcon}
        onClick={() => toExternalUrl('https://www.neo.nz/apply-for-a-new-loan')}
      />
      <LinkItem
        label="Feedback"
        Icon={FeedbackIcon}
        onClick={() => toExternalUrl('https://www.neo.nz/complaints')}
      />
    </Container>
  )
}
