import React from 'react'
import { isNotNilOrEmpty, all, isFalse } from '@solta/ramda-extra'
import { s, styled } from '@vega/styled'
import PropTypes from 'prop-types'

import { FormMessage } from './FormMessage'
import { useField } from 'formik'
import { Input } from './Input'

const { string, object, bool } = PropTypes

const InputField = styled(Input)(
  ({ isDefaultVariant }) => ({
    '&:hover, &:focus': isDefaultVariant && s('border-primary'),
  }),
  ({ isInputFilled }) => isInputFilled && s('border-green-darker bg-green-100'),
  ({ hasError }) => hasError && s('border-error-700 bg-error-50'),
  ({ hasError, isInputFilled }) =>
    hasError && !isInputFilled && s('border-red-400 bg-white'),
  ({ disabled }) => disabled && s('bg-grey-200 text-grey-400 border-0'),
  ({ readonly }) => readonly && s('bg-grey-100 text-grey-800 border-0')
)

function FormInput({
  id,
  name,
  label,
  type,
  children,
  disabled = false,
  readonly = false,
  Icon,
  inputProps,
  baseContainerStyle,
  testId,
  ...otherProps
}) {
  const [{ value, onChange }, { touched, error }] = useField({
    name,
    id,
  })

  const hasError = Boolean(touched && error)
  const isInputFilled = isNotNilOrEmpty(value)

  return (
    <div {...otherProps}>
      <InputField
        testId={testId}
        name={name}
        value={value}
        label={label}
        type={type}
        hasError={hasError}
        isInputFilled={isInputFilled}
        isDefaultVariant={all(isFalse)([hasError, isInputFilled, disabled, readonly])}
        disabled={disabled}
        readonly={readonly}
        onInputChange={onChange}
        Icon={Icon}
        inputProps={inputProps}
        containerStyle={{
          ...baseContainerStyle,
        }}
      >
        {children}
      </InputField>

      <FormMessage message={error} visible={hasError} />
    </div>
  )
}

FormInput.propTypes = {
  id: string,
  name: string,
  label: string,
  errorMessage: string,
  type: string,
  disabled: bool,
  readonly: bool,
  Icon: object,
  baseContainerStyle: object,
}

export { FormInput }
