import Cookies from 'js-cookie'

const FORCE_SIGNED_OUT_KEY = 'userForceSignedOut'
const VEGA_SUBDOMAIN = process.env.REACT_APP_VEGA_SUBDOMAIN

const isDev = process.env.NODE_ENV === 'development'

export const storeUserForceSignedOutFlag = () => {
  Cookies.set(FORCE_SIGNED_OUT_KEY, true, {
    domain: VEGA_SUBDOMAIN,
    secure: !isDev,
    sameSite: 'strict',
    path: '/',
  })
}

export const getUserForceSignedOutFlag = () => {
  return Cookies.get(FORCE_SIGNED_OUT_KEY)
}

export const removeUserForceSignedOutFlag = () => {
  return Cookies.remove(FORCE_SIGNED_OUT_KEY, { path: '/', domain: VEGA_SUBDOMAIN })
}
