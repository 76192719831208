import React from 'react'
import { mergeDeepRight } from '@solta/ramda-extra'
import { s } from '@vega/styled//v2'
import { DottedCircleLoader } from '@vega/components'
import { Row, CardContainer, Column } from '../common'
import { PreviewDataBlock } from '../PreviewDataBlock'
import { safelyParseDate } from 'utils/date'

export const PersonalDetailsCard = ({
  isLoading = false,
  fullName = '-',
  dob = '-',
  gender = '-',
  style,
  ...otherProps
}) => {
  if (isLoading) {
    return (
      <CardContainer
        style={mergeDeepRight(style, s('flex items-center justify-center h-8'))}
        {...otherProps}
      >
        <DottedCircleLoader size="lg" />
      </CardContainer>
    )
  }

  return (
    <CardContainer style={style} {...otherProps}>
      <Column style={s('mb-6')}>
        <PreviewDataBlock label="Full Legal Name">{fullName}</PreviewDataBlock>
      </Column>

      <Row style={s('justify-start')}>
        <PreviewDataBlock label="DOB">
          {safelyParseDate(dob, 'dd/MM/yyyy')}
        </PreviewDataBlock>
        <PreviewDataBlock label="Gender">{gender}</PreviewDataBlock>
      </Row>
    </CardContainer>
  )
}
