import React from 'react'
import { useSelector } from 'react-redux'
import { PersistentDrawer, PersistentNavItem } from 'features/common/NavigationDrawer'
import { ReactComponent as HomeIcon } from '@vega/components/src/assets/images/home.svg'
import { ReactComponent as ProductIcon } from '@vega/components/src/assets/images/gift.svg'
import { ReactComponent as CustomersIcon } from '@vega/components/src/assets/images/twoUsers.svg'
import { ReactComponent as CardIcon } from '@vega/components/src/assets/images/card.svg'
import { ReactComponent as ClipboardIcon } from '@vega/components/src/assets/images/clipboard.svg'
import { ReactComponent as AccountIcon } from '@vega/components/src/assets/images/user-circle.svg'
import { ReactComponent as MoneyIcon } from '@vega/components/src/assets/images/money.svg'
import { selectIsPersistentNavDrawerOpen } from 'modules/ui'
import { s } from '@vega/styled/v2'

import { routes } from 'routes'

const adminRoutes = routes.admin

function AdminNavigationDrawer() {
  const isNavDrawerOpen = useSelector(selectIsPersistentNavDrawerOpen)

  if (!isNavDrawerOpen) return null
  return (
    <PersistentDrawer homeRoute={routes.admin.root} style={s('relative z-2')}>
      <PersistentNavItem
        tooltipLabel="Home"
        route={adminRoutes.homepage.root}
        Icon={HomeIcon}
      />

      <PersistentNavItem
        tooltipLabel="Product"
        route={adminRoutes.products.root}
        Icon={ProductIcon}
      />

      <PersistentNavItem
        tooltipLabel="Entities"
        route={adminRoutes.customers.root}
        Icon={CustomersIcon}
      />

      <PersistentNavItem
        tooltipLabel="Payment centre"
        route={adminRoutes.paymentCenter.root}
        Icon={CardIcon}
      />

      <PersistentNavItem
        tooltipLabel="Loan Overview"
        route={adminRoutes.loans.root}
        Icon={ClipboardIcon}
      />

      <PersistentNavItem
        tooltipLabel="Reconciled Payments"
        route={adminRoutes.reconciledPaymentsCenter.root}
        Icon={MoneyIcon}
      />

      <PersistentNavItem
        tooltipLabel="Account"
        route={adminRoutes.account.root}
        Icon={AccountIcon}
        style={s('mt-a mb-2')}
      />
    </PersistentDrawer>
  )
}

export default AdminNavigationDrawer
