/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Tooltip } from '@mui/material'

const NoOnlineAccessPill = styled.div(
  s('rounded-2xl px-2 py-3 h-1 flex items-center justify-center bg-accent-red text-sm')
)

const textEllipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const headerStyleBase = (customStyles) =>
  s('px-4 inline-block text-grey-900 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s('px-4 inline-block text-grey-900 text-sm text-left flex items-center', {
    ...textEllipsis,
    ...customStyles,
  })

const entityTypeMap = {
  individual: 'Individual',
  trust: 'Trust',
  company: 'Business',
}

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Customer No.',
        accessor: (row) => row.customerNumber || '-',
        tableHeaderStyles: headerStyleBase({ width: '12%' }),
        tableCellStyles: cellStyleBase({ width: '12%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <div>{value}</div>
          </Tooltip>
        ),
      },
      {
        Header: 'Legal Full Name',
        accessor: (row) => row.name || '-',
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <div>{value}</div>
          </Tooltip>
        ),
      },

      {
        Header: 'Email Address',
        accessor: (row) => row.email || '-',
        tableHeaderStyles: headerStyleBase({ width: '31%' }),
        tableCellStyles: cellStyleBase({ width: '31%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <div>{value}</div>
          </Tooltip>
        ),
      },

      {
        Header: 'Type of Entity',
        accessor: (row) => row.type,
        tableHeaderStyles: headerStyleBase({ width: '8%' }),
        tableCellStyles: cellStyleBase({ width: '8%' }),
        Cell: ({ value }) => (
          <Tooltip title={entityTypeMap[value]}>
            <div>{entityTypeMap[value] || '-'}</div>
          </Tooltip>
        ),
      },
      {
        Header: '# of Loans',
        accessor: (row) => row.loan?.numberOfLoans || 0,
        tableHeaderStyles: headerStyleBase(s('text-center', { width: '8%' })),
        tableCellStyles: cellStyleBase(s('justify-center', { width: '8%' })),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <div>{value}</div>
          </Tooltip>
        ),
      },
      {
        Header: '# of Loan Groups',
        accessor: (row) => row.loan?.numberOfLoanGroups || 0,
        tableHeaderStyles: headerStyleBase(s('text-center', { width: '8%' })),
        tableCellStyles: cellStyleBase(s('justify-center', { width: '8%' })),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <div>{value}</div>
          </Tooltip>
        ),
      },
      {
        Header: ' ',
        accessor: (row) => row?.isCustomer,
        tableHeaderStyles: headerStyleBase({ width: '13%' }),
        tableCellStyles: cellStyleBase({ width: '13%' }),
        Cell: ({ value }) => {
          if (value === false) {
            return (
              <Tooltip title="NO ONLINE ACCESS">
                <div>
                  <NoOnlineAccessPill>{'NO ONLINE ACCESS'}</NoOnlineAccessPill>
                </div>
              </Tooltip>
            )
          }
          return null
        },
      },
    ],
    []
  )
}

export const Rows = ({ entities }) => {
  return React.useMemo(
    () =>
      entities.map((entity) => {
        return { ...entity }
      }),
    [entities]
  )
}
