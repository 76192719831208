import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Overview from './Overview'
import Details from './Details'
import { routes } from 'routes'

const customerRoutes = routes.admin.customers

function CustomerRouter() {
  return (
    <Switch>
      <Route exact path={customerRoutes.overview} component={Overview} />
      <Route path={customerRoutes.customerDetails} component={Details} />

      <Redirect to={customerRoutes.overview} />
    </Switch>
  )
}

export default CustomerRouter
