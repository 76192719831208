import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Row, Col, useLargeMediaQuery, larger } from '@vega/styled'
import { useHistory } from 'react-router-dom'
import { Button } from '@vega/components'
import PaymentCard from './PaymentCard'
import { routes } from 'routes'
import { safelyParseDate } from 'utils/date'
import { ReactComponent as ChevronRight } from '@vega/components/src/assets/images/chevron-right.svg'

const ViewAllButton = styled(Button)(
  s('bg-white border-1 border-primary rounded-lg text-primary text-sm font-semibold', {
    width: 196,
    height: 37,
  })
)

const Container = styled.div(
  s('', {
    overflowY: 'auto',
  }),
  larger('lg')(s('')),
  ({ isLargeScreen }) => (isLargeScreen ? s('mb-24') : s(''))
)

export function UpcomingPayments({ loans = [], payments = [], date }) {
  const history = useHistory()
  const aboveLargeScreenSize = useLargeMediaQuery('larger')

  return (
    <Container isLargeScreen={aboveLargeScreenSize}>
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <div style={s('bg-white rounded-lg flex flex-column pb-6')}>
            <div style={s('flex-row bg-grey-100 py-2 pl-4 mt-6 font-semibold rounded-md')}>
              {safelyParseDate(date, 'MMMM')} {safelyParseDate(date, 'yyyy')}
            </div>
            {payments.map((payment, index) => (
              <PaymentCard
                key={index}
                loan={loans?.find((loan) => loan._id === payment.loanId)}
                payment={payment}
              />
            ))}
            <Row gutter={[0, 0]}>
              <Col span={24}>
                <div style={s('flex flex-row justify-row justify-end mt-6')}>
                  <ViewAllButton
                    onClick={() =>
                      history.push(routes.customer.paymentSchedule.upcomingPayments)
                    }
                  >
                    <span>View All Payments</span>
                    <span style={s('text-right')}>
                      <ChevronRight
                        style={s('font-semibold ', {
                          height: 16,
                          marginTop: 6,
                        })}
                      />
                    </span>
                  </ViewAllButton>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
