import BaseService from '../baseService'
import { QueryBuilder } from '../utils'
import { formatDateString } from '../../../apps/banking/src/utils/date/formatDateString'

export class CustomerService extends BaseService {
  fetchCustomers({ q, filters, limit, sorting, start }) {
    const searchParams = QueryBuilder()
      .setQ(q)
      .setFilters(filters)
      .setPaging({ start, limit })
      .setSorting(sorting)
      .build()
    return this.client.get(`customers/search?${searchParams}`).json()
  }

  listCustomers({ filters }) {
    const searchParams = QueryBuilder().setFilters(filters).build()

    return this.client.get(`customers?${searchParams}`).json()
  }

  fetchCustomer(id) {
    return this.client.get(`customers/${id}`).json()
  }

  fetchCustomerBroker(customerId) {
    return this.client.get(`customers/${customerId}/broker`).json()
  }

  fetchCustomerMortgages(id) {
    return this.client.get(`customers/${id}/mortgages`).json()
  }

  fetchCustomerByProfileId() {
    return this.client.get('customers/by-profile-id').json()
  }

  fetchCustomerLoans(customerId) {
    return this.client.get(`customers/${customerId}/loans`).json()
  }

  fetchCustomerPayments({ customerId, filters, limit, sorting, start }) {
    const searchParams = QueryBuilder()
      .setFilters(filters)
      .setPaging({ start, limit })
      .setSorting(sorting)
      .build()

    return this.client.get(`customers/${customerId}/payments?${searchParams}`).json()
  }

  fetchCustomerTransactions({ customerId, filters, limit, sorting, start }) {
    const searchParams = QueryBuilder()
      .setFilters(filters)
      .setPaging({ start, limit })
      .setSorting(sorting)
      .build()

    return this.client.get(`customers/${customerId}/entries?${searchParams}`).json()
  }

  updateCustomer(id, payload) {
    const config = {
      json: payload,
    }

    return this.client.put(`customers/${id}`, config).json()
  }

  async downloadStatement(payload, loanId, loanNumber) {
    const config = {
      json: { payload },
      timeout: 60000,
    }

    const buffer = await this.client
      .post(`download-statement/${loanId}`, config)
      .arrayBuffer()
    convertAndSave(
      `${loanNumber}_Statement_${formatDateString(new Date())}.pdf`,
      buffer
    )
  }
}
function convertAndSave(reportName, contentInByte) {
  const blob = new Blob([contentInByte], {
    type: 'application/pdf',
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  const fileName = reportName
  link.download = fileName
  link.click()
}
