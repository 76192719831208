import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { accountService } from 'apiService'
import { normalizeError } from '@vega/services'

export const fetchAccounts = createAsyncThunk(
  'accounts/fetchAccounts',
  async ({ searchTerm: q, pageIndex, limit, filters }, { rejectWithValue }) => {
    try {
      return await accountService.fetchAccounts({
        q,
        filters,
        start: limit * pageIndex,
        limit,
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchAccount = createAsyncThunk(
  'accounts/fetchAccount',
  async (id, { rejectWithValue }) => {
    try {
      return await accountService.fetchAccount(id)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const accountsAdapter = createEntityAdapter({
  entities: {},
  ids: [],
})

const initialState = accountsAdapter.getInitialState()

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  extraReducers: {
    [fetchAccounts.fulfilled]: (state, action) => {
      const { items: accounts } = action.payload

      accountsAdapter.setAll(state, accounts)
    },
    [fetchAccount.fulfilled]: accountsAdapter.upsertOne,
  },
})

export const { reducer: accountsReducer } = accountsSlice
