import React from 'react'
import { mergeDeepRight } from 'ramda'
import { s, styled } from '@vega/styled/v2'
import { NumberField, DottedCircleLoader } from '@vega/components'
import {
  TextField,
  OutlinedBtn as OutlinedBtnBase,
  Row,
  CardContainer,
} from '../../common'

const OutlinedBtn = styled(OutlinedBtnBase)(
  s('border-primary text-primary text-base font-semibold')
)

export const ContactDetailsCard = ({
  isLoading,
  style,
  isFormEdited,
  resetForm,
  isMobileModalOpened = false,
  openMobileModal,
  ...otherProps
}) => {
  const onPhoneNumberFieldClicked = () => {
    if (window.innerWidth < 1024 && isMobileModalOpened !== true) {
      openMobileModal()
    }
  }

  if (isLoading) {
    return (
      <CardContainer
        style={mergeDeepRight(style, s('flex items-center justify-center h-8'))}
        {...otherProps}
      >
        <DottedCircleLoader size="lg" />
      </CardContainer>
    )
  }

  return (
    <CardContainer style={style} {...otherProps}>
      <TextField name="email" label="Email Address" style={s('mb-6')} isDisabled />

      <NumberField
        name="phoneNumber"
        aria-label="phone number"
        label="Phone Number"
        format="### #### ####"
        placeholder="000 0000 0000"
        onClick={onPhoneNumberFieldClicked}
      />

      {isFormEdited ? (
        <Row style={s('mt-6 justify-start')}>
          <OutlinedBtn variant="contained" style={s('mr-4')}>
            Save Changes
          </OutlinedBtn>

          <OutlinedBtn style={s('border-primary')} onClick={resetForm}>
            Cancel
          </OutlinedBtn>
        </Row>
      ) : (
        <OutlinedBtn type="button" style={s('mt-6 border-primary')}>
          Save Changes
        </OutlinedBtn>
      )}
    </CardContainer>
  )
}
