import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { transactionService } from 'apiService'
import { normalizeError } from '@vega/services'

export const fetchTransactions = createAsyncThunk(
  'transactions/fetchTransactions',
  async (loanId, { rejectWithValue }) => {
    try {
      return await transactionService.fetchTransactions(loanId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const transactionsAdapter = createEntityAdapter()

const initialState = transactionsAdapter.getInitialState({
  entities: {},
  ids: [],
  // Use for view to render spinner as create new entry is asynchronous, we need to use setTimeOut to refetch transaction when the entry is created
  // However a better solution can be a push model (etc. webhook)
  isFetchingTransactions: false,
})

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setIsFetchingTransactions: (state, action) => {
      state.isFetchingTransactions = action.payload
    },
  },
  extraReducers: {
    [fetchTransactions.fulfilled]: (state, action) => {
      transactionsAdapter.setAll(state, action.payload)
    },
  },
})

export const { setIsFetchingTransactions } = transactionsSlice.actions
const { reducer: transactionsReducer } = transactionsSlice

export { transactionsReducer }
