import React from 'react'
import { Route } from 'react-router-dom'
import { styled, s } from '@vega/styled/v2'

import Homepage from 'features/admin/homePage/index'
import { routes } from 'routes'

const Root = styled.div(s('flex h-full'), { overflow: 'scroll', width: '100%' })

const adminRoutes = routes.admin

const homePageRouter = () => {
  return (
    <Root>
      <Route path={adminRoutes.homepage.root} component={Homepage} />
    </Root>
  )
}

export default homePageRouter
