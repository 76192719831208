export const calculatePrevMonth = (year, month) => {
  let prevYear
  let prevMonth

  if (month === 1) {
    prevYear = year - 1
    prevMonth = 12
  } else {
    prevMonth = month - 1
  }

  return { prevYear, prevMonth }
}
