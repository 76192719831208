import React from 'react'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import { Container as ContainerBase, Row, Col } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { routes } from 'routes'
import { BreakdownItem } from './BreakdownItem'
import { ReactComponent as ChevronLeftBase } from '@vega/components/src/assets/images/chevron-left.svg'
import testImage from './testImage.jpeg'
import { formatCurrency } from 'utils/formatter'

const Container = styled(ContainerBase)(
  s('py-8', {
    msOverflowStyle: 'none',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  })
)
const ChevronLeft = styled(ChevronLeftBase)(
  s('mb-5 text-grey-700', { cursor: 'pointer' })
)
const Title = styled.div(
  s('mb-12 text-grey-800 font-semibold', {
    fontSize: 28,
    letterSpacing: '-0.02em',
    lineHeight: 1.21,
  })
)
const PropertyImage = styled.img(
  s('mb-6 min-w-full rounded-lg object-cover', { maxHeight: 320 })
)
const DetailsContainer = styled.div(
  s('border-1 border-solid border-grey-200 rounded-lg bg-white p-6')
)
const Address = styled.div(
  s('mb-3 text-grey-600 font-semibold', {
    fontSize: 22,
    letterSpacing: '-0.02em',
    lineHeight: 1.18,
  })
)
const ValueContainer = styled.div(
  s('flex items-end text-grey-800', { lineHeight: 1.2 })
)
const ValuedText = styled.div(s('mr-2 text-xl font-normal'))
const Value = styled.div(s('text-4xl font-semibold'))
const Divider = styled.div(s('mt-8 mb-8 border-1 border-solid border-grey-200'))
const Heading = styled.div(
  s('mb-5 text-grey-800 font-semibold', {
    fontSize: 22,
    letterSpacing: '-0.02em',
    lineHeight: 1.18,
  })
)

const {
  admin: {
    loans: { accountDetails },
  },
} = routes

const PropertyDetails = ({
  property = {
    address: '23 Franklin Street, Karaka, Auckland',
    value: '1200000',
    landValue: '500000',
    improvementValue: '480000',
    lastValued: new Date(),
    image: testImage,
  },
}) => {
  const history = useHistory()

  const goToAccountDetails = () => history.push(accountDetails)

  return (
    <Container>
      <ChevronLeft onClick={goToAccountDetails} />
      <Title>Property Details</Title>

      <Row justify="center">
        <Col span={16}>
          <PropertyImage src={property.image} />
        </Col>
      </Row>

      <Row justify="center">
        <Col span={16}>
          <DetailsContainer>
            <Address>{property.address?.fullAddress ?? '-'}</Address>

            <ValueContainer>
              <ValuedText>Valued</ValuedText>
              <Value>{formatCurrency(property.value)}</Value>
            </ValueContainer>

            <Divider />

            <Heading>Value breakdown</Heading>

            <Row>
              <Col span={6}>
                <BreakdownItem
                  label="Land value"
                  value={formatCurrency(property.landValue)}
                />
              </Col>
              <Col span={6}>
                <BreakdownItem
                  label="Improvement value"
                  value={formatCurrency(property.improvementValue)}
                />
              </Col>
              <Col span={6}>
                <BreakdownItem
                  label="Last valued"
                  value={format(property.lastValued, 'dd LLL y')}
                />
              </Col>
            </Row>
          </DetailsContainer>
        </Col>
      </Row>
    </Container>
  )
}

export { PropertyDetails }
