import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { larger, Row, Col } from '@vega/styled'
import { CardContainer } from '../common'
import { DottedCircleLoader } from '@vega/components'
import { PreviewDataBlock } from '../PreviewDataBlock'
import { isNilOrEmpty } from '@solta/ramda-extra'

const BSBPreviewDataBlock = styled(PreviewDataBlock)(
  s('mb-6 mr-16'),
  larger('sm')(s('mb-0'))
)

export const PaymentInfoCard = ({
  isLoading,
  accountHolder,
  bsb,
  accountNumber,
  status,
  ...otherProps
}) => {
  if (isLoading) {
    return (
      <CardContainer style={s('flex items-center justify-center h-8')} {...otherProps}>
        <DottedCircleLoader size="lg" />
      </CardContainer>
    )
  }

  if (isNilOrEmpty(accountHolder)) {
    return (
      <CardContainer
        style={s('flex items-center justify-center h-8 text-grey-600 text-sm')}
        {...otherProps}
      >
        No Payment Details linked to your account
      </CardContainer>
    )
  }

  return (
    <CardContainer {...otherProps}>
      <Row style={s('items-start mb-6')}>
        <PreviewDataBlock label="Bank Institution" style={s('max-w-4/5')}>
          {accountHolder}
        </PreviewDataBlock>
      </Row>

      <Row>
        <Col span={24}>
          <PreviewDataBlock label="Account Holder">{accountHolder}</PreviewDataBlock>
        </Col>

        <Col span={12}>
          <BSBPreviewDataBlock label="BSB">{bsb}</BSBPreviewDataBlock>
        </Col>

        <Col span={12}>
          <PreviewDataBlock label="Account number">{accountNumber}</PreviewDataBlock>
        </Col>
      </Row>
    </CardContainer>
  )
}
