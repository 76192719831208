import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class MortgageAccountService extends BaseService {
  fetchMortgageAccounts({ q, start, limit, sorting = {}, filters = {} }) {
    const searchParams = QueryBuilder()
      .setQ(q)
      .setFilters(filters)
      .setPaging({ start, limit })
      .setSorting(sorting)
      .build()

    return this.client.get(`mortgages?${searchParams}`).json()
  }

  fetchLoans({ q, start, limit, filters = {} }, signal) {
    const searchParams = QueryBuilder()
      .setQ(q)
      .setPaging({ start, limit })
      .setFilters(filters)
      .build()

    return this.client.get(`loans?${searchParams}`, { signal }).json()
  }

  fetchLoanEntities(id) {
    return this.client.get(`loans/${id}/legal-entities`).json()
  }

  fetchMortgageAccount(id) {
    return this.client.get(`mortgages/${id}`).json()
  }

  fetchLoan(id) {
    return this.client.get(`loans/${id}`).json()
  }

  fetchLoanSecurities(id) {
    return this.client.get(`loans/${id}/securities`).json()
  }

  updateLoan(id, loan) {
    const config = {
      json: loan,
    }

    return this.client.put(`loans/${id}`, config)
  }

  updateMortgageStatus(id) {
    return this.client.patch(`mortgages/${id}/approve`).json()
  }

  fetchCustomerMortgages(customerId) {
    return this.client.get(`customers/${customerId}/mortgages`).json()
  }

  addCustomerToMortgage(mortgageId, customerId) {
    const config = {
      json: { customerId, type: 'supporting' },
    }

    return this.client.post(`mortgages/${mortgageId}/customers`, config).json()
  }

  fetchLoanTransactions({ loanId, limit, sorting, start, filters = {} }) {
    const searchParams = QueryBuilder()
      .setPaging({ start, limit })
      .setSorting(sorting)
      .setFilters(filters)
      .build()

    return this.client.get(`loans/${loanId}/entries?${searchParams}`).json()
  }
}
