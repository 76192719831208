import { prop, isNilOrEmpty } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { invitationAdapter } from './module'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

export const selectInvitation = prop('invitations')

export const {
  selectAll: selectInvitations,
  selectById: selectInvitationById,
} = invitationAdapter.getSelectors(prop('invitations'))

export const selectInvitationByEmail = (email) =>
  createSelector(selectInvitations, (invitations = []) => {
    if (isNilOrEmpty(invitations)) return {}
    return invitations.find((invitation) => invitation?.email === email)
  })

export const {
  selectIsPending: selectIsFetchingInvitations,
} = createAsyncThunkSelectors('invitations/fetchInvitation')
