import { prop } from '@solta/ramda-extra'
import { preferenceAdapter } from './module'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

const selectPreferences = prop('preferences')

export const { selectById: selectProfilePreference } = preferenceAdapter.getSelectors(
  selectPreferences
)

export const {
  selectIsPending: selectIsFetchingPreferences,
} = createAsyncThunkSelectors('preferences/fetchPreferences')

export const {
  selectById: selectPreferencesByProfileId,
} = preferenceAdapter.getSelectors(prop('preferences'))
