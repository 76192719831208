import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { includes, equals, noop } from '@solta/ramda-extra'
import { useHistory, useLocation } from 'react-router-dom'
import { styled, s } from '@vega/styled'
import { setTemporaryNavDrawerState } from 'modules/ui'

const Container = styled.div(
  s('flex items-center rounded-lg mb-3', {
    transition: 'width 350ms ease-in-out',
    cursor: 'pointer',
    overflow: 'hidden',
  }),
  ({ isActive }) => ({
    backgroundColor: isActive && s('text-green-100').color,
  }),
  ({ leanMode }) => {
    if (leanMode) return s('items-center justify-center h-3 w-3')

    return s('h-3 px-3 w-full')
  }
)

const Label = styled.span(
  s('absolute text-grey-800 font-semibold text-sm', {
    whiteSpace: 'nowrap',
    left: s('ml-12').marginLeft,
  })
)

const IconBtn = styled.div(s('relative flex items-center'))

const iconStyle = ({ leanMode }) =>
  s('text-grey-700', {
    pointerEvents: 'none',
    marginRight: leanMode ? 0 : s('mr-3').marginRight,
  })

export const NavItem = ({
  route,
  isExactRoute = false,
  label,
  Icon,
  leanMode = false,
  onClick = noop,
  ...otherProps
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()

  const checkIsActive = (currentPath) => {
    const comparatorFn = isExactRoute ? equals : includes

    return comparatorFn(currentPath, pathname)
  }
  const closeNavDrawer = () => dispatch(setTemporaryNavDrawerState({ isOpen: false }))

  const handleIconBtnClicked = (toRoute) => {
    onClick()
    closeNavDrawer()
    history.push(toRoute)
  }

  return (
    <Container
      style={s('mt-4')}
      onClick={() => handleIconBtnClicked(route)}
      isActive={checkIsActive(route)}
      leanMode={leanMode}
      {...otherProps}
    >
      <IconBtn>
        <Icon
          stroke={s('text-grey-700').color}
          strokeWidth={2}
          width={21}
          height={21}
          style={iconStyle({ leanMode })}
        />

        <Label>{label}</Label>
      </IconBtn>
    </Container>
  )
}

const { string, bool, object } = PropTypes

NavItem.propTypes = {
  route: string,
  label: string,
  isExactRoute: bool,
  leanMode: bool,
  Icon: object,
}
