import React, { useState } from 'react'
import { Formik as FormProvider, Form } from 'formik'
import * as Validator from 'yup'
import { SOLTA_CONSTANTS } from '@neo/constants'
import { equals, isNotNilOrEmpty } from '@solta/ramda-extra'
import { styled, s } from '@vega/styled/v2'
import {
  PasswordField,
  Button,
  TextField as TextFieldBase,
  toast,
} from '@vega/components'
import { AuthSdk as Wallet } from '@midas/idkit'

const CardContainer = styled.div(
  s('bg-white rounded-lg p-6', {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
  })
)
const Row = styled.div(s('flex justify-between'))

export const OutlinedBtn = styled(({ ...otherProps }) => (
  <Button
    type="submit"
    variant="outlined"
    style={s(
      'border-grey-300 py-3 border-primary text-primary text-base font-semibold'
    )}
    {...otherProps}
  />
))()

export const TextField = styled((props) => (
  <TextFieldBase containerStyle={s('flex-1')} {...props} />
))()

const Sentence = styled.p(s('text-grey-700 m-0'))

const SendEmailButton = styled(Button)(
  s('mt-2 mb-3 p-1 w-full'),
  ({ isEmailButtonPressed }) =>
    isEmailButtonPressed && s('bg-grey', { '&:hover:not([disabled])': s('bg-grey') })
)
const Collapsable = styled.div(({ shouldCollapse }) => ({
  transition: 'all 250ms ease-in-out',
  minHeight: 0,
  height: 0,
  paddingBottom: shouldCollapse ? 0 : 620,
  overflow: 'hidden',
}))

function getSignInErrorMessage(signInResponse) {
  switch (signInResponse?.error?.message) {
    case 'Password attempts exceeded':
      toast(
        'Exceeded current password attempts, please wait up to 15 minutes and try again'
      )
      return
    case 'Incorrect username or password':
      toast('The current password entered is not correct')
      return
    default:
      toast(signInResponse?.error?.message)
  }
}

function getChangePasswordErrorMessage(changePasswordResponse) {
  switch (changePasswordResponse?.error?.message) {
    case 'Invalid code provided, please request a code again':
      toast('Invalid verification code. Please click cancel and redo form to try again')
      return
    case 'Attempt limit exceeded, please try after some time':
      toast(
        'Exceeded verification code attempts, please wait up to 15 minutes and try again'
      )
      return
    default:
      toast(changePasswordResponse?.error?.message)
  }
}

export const UpdatePassword = ({ email }) => {
  const [isEmailButtonPressed, setIsEmailButtonPressed] = useState(false)
  const [isFormOpened, setIsFormOpened] = useState(false)
  const [isChangingPassword, setIsChangingPassword] = useState(false)

  const handleEmailSent = async () => {
    if (isEmailButtonPressed) {
      toast('Email has already been sent, please click cancel and redo form for resend')
      return
    }
    const passwordCodeResponse = await Wallet.getChangePasswordCode(email)
    if (passwordCodeResponse?.error?.message) {
      toast(passwordCodeResponse?.error?.message)
      return
    }
    setIsEmailButtonPressed(true)
    toast('Email sent successfully')
  }

  const handleSubmit = async ({ verificationCode, currentPassword, newPassword }) => {
    setIsChangingPassword(true)
    const signInResponse = await Wallet.signIn({
      email,
      password: currentPassword,
    })
    if (signInResponse?.error?.message) {
      getSignInErrorMessage(signInResponse)
      setIsChangingPassword(false)
      return
    }

    const changePasswordResponse = await Wallet.changePassword(
      email,
      verificationCode,
      newPassword
    )
    if (changePasswordResponse?.error?.message) {
      getChangePasswordErrorMessage(changePasswordResponse)
      setIsChangingPassword(false)
      return
    }
    setIsFormOpened(false)
    setIsChangingPassword(false)

    toast('Password updated')
  }

  const { object, string } = Validator

  const validationSchema = object({
    verificationCode: string().required('The verification code is required'),
    currentPassword: string().required('Current password is required'),
    newPassword: string()
      .required('New password is required')
      .matches(
        SOLTA_CONSTANTS.REGEX.MIN_PASSWORD_REQ_STRONG,
        'Password must contain at least 8 characters, one uppercase, one number'
      ),

    confirmNewPassword: string()
      .required('Confirm new password is required')
      .when(['newPassword'], {
        is: (newPassword) => isNotNilOrEmpty(newPassword),
        then: string().test(
          'is-confirm-new-password-matches-new-password',
          'Confirm new password is invalid',
          (confirmNewPassword, context) =>
            equals(confirmNewPassword, context.parent.newPassword)
        ),
      }),
  })
  const initialValues = {
    verificationCode: '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }
  return (
    <FormProvider
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ resetForm }) => (
        <Form>
          <CardContainer style={s('px-0')}>
            <Sentence style={s('mb-6 px-6')}>
              Update a new password for your account
            </Sentence>

            <Collapsable style={s('px-6')} shouldCollapse={!isFormOpened}>
              <TextField name="verificationCode" label="Verification Code" />
              <SendEmailButton
                onClick={() => handleEmailSent()}
                isEmailButtonPressed={isEmailButtonPressed}
              >
                Send verification email
              </SendEmailButton>

              <TextField
                name="currentPassword"
                label="Current Password"
                style={s('mb-6')}
              />

              <PasswordField
                name="newPassword"
                label="New Password"
                style={s('mb-6')}
                fast={false}
              />

              <PasswordField
                name="confirmNewPassword"
                label="Retype New Password"
                style={s('mb-6')}
              />

              <Row style={s('justify-start mb-2')}>
                {/* // TODO: add setformclosed when there are no errors toasted */}
                <OutlinedBtn
                  style={s('mr-4 bg-secondary border-transparent')}
                  loading={isChangingPassword}
                >
                  Change Password
                </OutlinedBtn>

                <OutlinedBtn
                  style={s('border-primary')}
                  type="button"
                  onClick={() => {
                    resetForm()
                    setIsEmailButtonPressed(false)
                    setIsFormOpened(false)
                  }}
                >
                  Cancel
                </OutlinedBtn>
              </Row>
            </Collapsable>

            {!isFormOpened && (
              <div style={s('px-6')}>
                <p>
                  Upon clicking this button, an email containing your verification code
                  will be sent to your email
                </p>
                <OutlinedBtn
                  style={s('border-primary')}
                  type="button"
                  onClick={() => setIsFormOpened(true)}
                >
                  Change Password
                </OutlinedBtn>
              </div>
            )}
          </CardContainer>{' '}
        </Form>
      )}
    </FormProvider>
  )
}
