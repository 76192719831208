import { configureStore } from '@reduxjs/toolkit'
import { asyncStateReducer } from '@vega/redux.async'
import { productsReducer } from 'modules/product'
import { profileReducer } from '@vega/redux.profile'
import { customersReducer } from 'modules/customer'
import { mortgageAccountsReducer } from 'modules/mortgageAccounts'
import { paymentsReducer } from 'modules/payment'
import { accountsReducer } from 'modules/account'
import { transactionsReducer } from 'modules/transactions'
import { uiReducer } from 'modules/ui'
import { reportingReducer } from 'modules/dashboard'
import { preferenceReducer } from 'modules/preference'
import { legalEntitiesReducer } from 'modules/legalEntity'
import { invitationsReducer } from 'modules/entityInvitation'
import { reconciledPaymentsReducer } from 'modules/reconciledPayments'

export default configureStore({
  reducer: {
    asyncState: asyncStateReducer,
    profile: profileReducer,
    ui: uiReducer,
    products: productsReducer,
    customers: customersReducer,
    mortgageAccounts: mortgageAccountsReducer,
    payments: paymentsReducer,
    accounts: accountsReducer,
    transactions: transactionsReducer,
    reportData: reportingReducer,
    preferences: preferenceReducer,
    legalEntities: legalEntitiesReducer,
    invitations: invitationsReducer,
    reconciledPayments: reconciledPaymentsReducer,
  },
})
