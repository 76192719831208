import { prop } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { legalEntitiesAdapter } from './module'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

const selectLegalEntities = prop('legalEntities')

export const selectTotal = createSelector(selectLegalEntities, prop('total'))
export const {
  selectEntities: selectLegalEntityEntities,
  selectAll: selectAllLegalEntities,
  selectById: selectLegalEntityById,
} = legalEntitiesAdapter.getSelectors(selectLegalEntities)

export const {
  selectIsPending: selectIsFetchLegalEntitiesByLoanIdPending,
} = createAsyncThunkSelectors('legalEntities/fetchLegalEntitiesByLoanId')

export const {
  selectIsPending: selectIsSearchingLegalEntities,
} = createAsyncThunkSelectors('legalEntities/searchLegalEntities')

export const {
  selectIsPending: selectIsFetchingLegalEntity,
} = createAsyncThunkSelectors('legalEntities/fetchLegalEntity')
