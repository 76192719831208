import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { defaultTo } from '@solta/ramda-extra'
import { Row, Col } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { SectionTitle as SectionTitleBase } from '@vega/components/src/v2'
import { routes } from 'routes'
import { ReactComponent as ChevronLeftBase } from '@vega/components/src/assets/images/chevron-left.svg'
import { Loading } from '@vega/components'
import { ApproveLoanGroup } from './ApproveLoanGroup'
import { TransactionHistoryTable } from './TableFormat'
import { MORTGAGE_ACCOUNT_CONSTANTS, TEST_IDS, SORT_ORDER } from '@neo/constants'
import {
  fetchTransactions,
  selectIsFetchingTransactions as selectIsRefetchingTransactions,
} from 'modules/transactions'
import { fetchLegalEntitiesByLoanId } from 'modules/legalEntity'
import {
  fetchLoan,
  fetchLoans,
  selectAllMortgageAccounts,
  selectIsFetchMortgageAccountPending,
  selectIsFetchingMortgageAccount as selectIsRefetchingMortgageAccount,
  selectLoanDetails,
} from 'modules/mortgageAccounts'
import { selectHasApproveLoanGroupFulfilled } from 'modules/loanGroup'

import EntityBox from './EntityBox'
import SecurityBox from './SecurityBox'
import LoanBox from './LoanBox'
import {
  selectCustomerTransactions,
  fetchCustomerLoanTransactions,
  selectIsFetchCustomerLoanTransactionsPending,
} from 'modules/customer'

const {
  STATUS: { APPROVED, PENDING, REQUESTED },
} = MORTGAGE_ACCOUNT_CONSTANTS

const LOAN_GROUP_STATUS = {
  [APPROVED]: 'APPROVED',
  [PENDING]: 'PENDING',
  [REQUESTED]: 'REQUESTED',
}

const Root = styled.div(s('flex flex-column flex-1 mx-8'), { overflow: 'none' })
const BackButton = styled.button(
  s('border-transparent bg-transparent flex content-center '),
  {
    cursor: 'pointer',
  }
)
const ChevronLeft = styled(ChevronLeftBase)(
  s('text-grey-700', { marginTop: 28.5, cursor: 'pointer' })
)
const BackButtonText = styled(SectionTitleBase)(s('mt-8 text-sm'))

const ReadyToApproveButton = styled.div(
  s(
    'text-sm bg-secondary tracking-tight font-semibold px-4 py-3 rounded-lg text-primary text-center justity-center flex h-auto w-auto',
    {
      maxWidth: 161,
      minWidth: 146,
      minHeight: 45,
      maxHeight: 60,
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    }
  )
)

const AddNewEntryButton = styled.div(
  s(
    'text-sm bg-secondary tracking-tight font-semibold px-4 py-3 rounded-lg text-primary text-center flex h-auto w-auto',
    {
      maxWidth: 135,
      minWidth: 124,
      minHeight: 45,
      maxHeight: 60,
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    }
  )
)

const TitleContainer = styled.div(s('flex my-2'))
const Title = styled.div(s('text-primary text-3xl font-extrabold', { fontSize: 28 }))
const AccountNumberBox = styled.div(s('flex'))
const SubTitle = styled.div(s('font-normal text-sm text-grey-800 my-2'))
const BoxTitle = styled.div(s('pb-2 font-extrabold text-xl text-primary mt-9'))

const LoanGroupNumber = styled.div(s('mx-1 my-2 font-semibold text-sm text-grey-900'))
const LoanGroupStatusPill = styled.div(
  s('flex flex-row justify-center items-center py-1 px-2 mb-1  text-xs font-normal', {
    borderRadius: 30,
    maxWidth: 79,
  }),
  ({ status }) =>
    status === LOAN_GROUP_STATUS[APPROVED]
      ? s('bg-secondary')
      : s('bg-accent-pink', { maxWidth: 80, minWidth: 68 })
)
const {
  admin: {
    loans: { overview },
  },
} = routes

// eslint-disable-next-line complexity
function AccountDetails() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id: loanId } = useParams()

  const [isModalOpened, setIsModalOpened] = useState(false)

  const isFetchingLoan = useSelector(selectIsFetchMortgageAccountPending)
  const isRefetchingLoan = useSelector(selectIsRefetchingMortgageAccount)
  const transactions = useSelector(selectCustomerTransactions)

  const loan = useSelector(selectLoanDetails)

  const loanGuarantors = loan?.entities?.filter((e) => e?.role === 'guarantor') || []

  const loanEntities = loan?.entities?.filter((e) => e?.role !== 'guarantor') || []
  const loanPrimaryEntity = loan?.entities?.find((e) => e?.isPrimary)
  const allLoanEntityNames = loanEntities?.map((e) => e?.name).join(', ')

  const groupLoans = useSelector(selectAllMortgageAccounts)

  const securities = loan?.securities || []

  const loanGroupId = loan?.loanGroup?._id || ''
  const loanGroupNumber = loan?.loanGroup?.groupNumber || ''
  const handleLoanNumberClick = () =>
    history.push(`${routes.admin.loans.root}/overview?loanGroupId=${loanGroupId}`)

  const loanNumber = loan?.loanNumber || ''
  const loanGroupStatus = loan?.loanGroup?.status
  const displayedLoanGroupStatus = LOAN_GROUP_STATUS[loanGroupStatus] ?? '-'

  const loanDetails = {
    loanBalance: defaultTo('', loan?.balance),
    loanLimit: defaultTo('', loan?.loanLimit),
    loanRepaymentAmount: defaultTo('', loan?.repaymentAmount),
    loanRepaymentRecurrence: defaultTo('', loan?.repaymentRecurrence),
    loanAmount: loan?.loanAmount,
    loanTerm: defaultTo('', loan?.loanTermInMonths),
    settlementDate: defaultTo('', loan?.loanGroup?.settlementDate),
  }

  const isFetchingTransactions = useSelector(
    selectIsFetchCustomerLoanTransactionsPending
  )
  const isRefetchingTransactions = useSelector(selectIsRefetchingTransactions)

  const goToAccountDetails = () => history.push(overview)
  const addNewTransaction = () =>
    history.push(`${routes.admin.loans.root}/${loanId}/new-transaction`)

  const hasApproveLoanGroupFulfilled = useSelector(selectHasApproveLoanGroupFulfilled)

  useEffect(() => {
    dispatch(fetchLoan(loanId))
    dispatch(fetchTransactions(loanId))
    dispatch(fetchLegalEntitiesByLoanId(loanId))
  }, [dispatch, loanId, hasApproveLoanGroupFulfilled])

  useEffect(() => {
    if (loanGroupStatus === PENDING) {
      dispatch(
        fetchLoans({
          searchParams: {
            limit: 20,
            filters: { loanGroupId },
          },
          pageIndex: 0,
        })
      )
    }
  }, [dispatch, loanGroupId, loanGroupStatus])

  useEffect(() => {
    const transactionFilters = {
      id: loanId,
    }
    const transactionSorting = {
      recordedAt: SORT_ORDER.DESC,
    }

    dispatch(
      fetchCustomerLoanTransactions({
        loanId,
        filters: transactionFilters,
        sorting: transactionSorting,
      })
    )
  }, [dispatch, loanId])

  return (
    <Root>
      {isFetchingLoan ? (
        <Loading />
      ) : (
        <>
          <Row>
            <BackButton onClick={goToAccountDetails}>
              <ChevronLeft /> <BackButtonText title="Back" />
            </BackButton>
          </Row>
          <Row>
            <Col span={21}>
              <TitleContainer>
                <Title>{allLoanEntityNames || '-'}&apos;s Loan Account</Title>
              </TitleContainer>
              <LoanGroupStatusPill
                data-test-id={TEST_IDS.LOAN_STATUS}
                status={displayedLoanGroupStatus}
              >
                {displayedLoanGroupStatus}
              </LoanGroupStatusPill>
            </Col>

            <Col span={3} style={s('flex justify-end')}>
              {displayedLoanGroupStatus === LOAN_GROUP_STATUS[PENDING] && (
                <ReadyToApproveButton
                  data-test-id={TEST_IDS.APPROVE_LOAN_BTN}
                  onClick={() => setIsModalOpened(true)}
                >
                  Ready to Approve
                </ReadyToApproveButton>
              )}
              {displayedLoanGroupStatus === LOAN_GROUP_STATUS[APPROVED] && (
                <AddNewEntryButton
                  data-test-id={TEST_IDS.ADD_NEW_ENTRY_BTN}
                  onClick={addNewTransaction}
                >
                  Add New Entry
                </AddNewEntryButton>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <AccountNumberBox>
                <SubTitle>Loan Group No:</SubTitle>
                <LoanGroupNumber
                  data-test-id={TEST_IDS.LOAN_GROUP_NO}
                  onClick={handleLoanNumberClick}
                  style={{ cursor: 'pointer' }}
                >
                  {loanGroupNumber}
                </LoanGroupNumber>
              </AccountNumberBox>
              <AccountNumberBox>
                <SubTitle>Mortgage No:</SubTitle>
                <LoanGroupNumber data-test-id={TEST_IDS.LOAN_NO}>
                  {loanNumber}
                </LoanGroupNumber>
              </AccountNumberBox>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <BoxTitle>Entities</BoxTitle>
              <EntityBox
                isLoading={isFetchingLoan}
                numberOfEntities={loanEntities?.length || '-'}
                {...loanPrimaryEntity}
              />
            </Col>

            <Col span={8}>
              <BoxTitle>Security Details</BoxTitle>
              <SecurityBox
                isLoading={isFetchingLoan}
                securities={securities}
                guarantors={loanGuarantors}
                loanId={loanId}
              />
            </Col>

            <Col span={8}>
              <BoxTitle>Loan overview</BoxTitle>
              <LoanBox
                {...loanDetails}
                isLoading={isFetchingLoan || isRefetchingLoan}
              />
            </Col>
          </Row>
          <TransactionHistoryTable
            transactions={transactions}
            containerStyle={s('bg-white rounded-lg shadow-md flex-1')}
            style={s('pb-0 m-0')}
            tableHeaderRowStyles={s(
              'bg-white border-solid border-0 border-b-1 border-grey-200'
            )}
            loaderLabel="Fetching Transactions"
            loading={isFetchingTransactions || isRefetchingTransactions}
          />
          <ApproveLoanGroup
            isOpen={isModalOpened}
            setIsOpen={setIsModalOpened}
            loans={groupLoans}
            loanGroupId={loanGroupId}
            loanGroupNumber={loanGroupNumber}
          />
        </>
      )}
    </Root>
  )
}

export { AccountDetails }
