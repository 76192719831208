import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Row, Col, useLargeMediaQuery } from '@vega/styled'
import PaymentCard from './PaymentCard'
import { safelyParseDate } from 'utils/date'
import { Loading } from '@vega/components'
import { isNilOrEmpty } from '@solta/ramda-extra'

const Root = styled.div(s('bg-white rounded-lg flex flex-column px-5 pb-6 py-2'))

const DateHeaderContainer = styled.div(
  s('flex-row bg-grey-100 py-2 pl-4 mt-6 font-semibold', { borderRadius: 6 }),
  ({ hidden }) => hidden && s('hidden')
)

const ViewMoreButton = styled.button(
  s('border-transparent bg-secondary py-1 font-semibold text-sm', { borderRadius: 6 }),
  ({ hidden }) => hidden && s('hidden')
)

const NoPaymentsText = styled.div(
  s('text-center text-grey-700 font-normal text-base my-16')
)

const isOverdue = (paymentDateString) => {
  const todaysDate = new Date()
  const paymentDate = new Date(paymentDateString)

  return todaysDate > paymentDate
}

const isSameAsPreviousMonth = (currentDateString, previousDateString) => {
  const currentMonth = new Date(currentDateString).getMonth()
  const previousMonth = new Date(previousDateString).getMonth()
  const currentYear = new Date(currentDateString).getFullYear()
  const previousYear = new Date(previousDateString).getFullYear()
  return (
    currentMonth === previousMonth &&
    currentYear === previousYear &&
    !isOverdue(previousDateString)
  )
}

export function UpcomingPaymentsContainer({
  loans,
  payments,
  getMorePayments,
  hasNoMorePayments,
  isLoading,
}) {
  const aboveLargeScreenSize = useLargeMediaQuery('larger')

  if (isLoading)
    return (
      <Root isLargeScreen={aboveLargeScreenSize} style={s('py-16')}>
        <Loading />
      </Root>
    )

  if (isNilOrEmpty(payments))
    return (
      <Root isLargeScreen={aboveLargeScreenSize} className="poppins">
        <NoPaymentsText>No upcoming payments</NoPaymentsText>
      </Root>
    )
  return (
    <Root isLargeScreen={aboveLargeScreenSize}>
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <Root>
            {payments.map((payment, index) => (
              <>
                <div key={index}>
                  {!isSameAsPreviousMonth(
                    payment?.dueDate,
                    payments[index - 1]?.dueDate
                  ) && (
                    <DateHeaderContainer
                      key={index}
                      hidden={isOverdue(payment?.dueDate)}
                    >
                      {safelyParseDate(payment?.dueDate, 'MMMM')}{' '}
                      {safelyParseDate(payment?.dueDate, 'yyyy')}
                    </DateHeaderContainer>
                  )}
                  <PaymentCard
                    loan={loans?.find((loan) => loan._id === payment.loanId)}
                    payment={payment}
                    hidden={isOverdue(payment?.dueDate)}
                  />
                </div>
              </>
            ))}

            <ViewMoreButton
              onClick={() => {
                getMorePayments(payments.at(-1)?.dueDate)
              }}
              hidden={hasNoMorePayments}
            >
              Load More Payments
            </ViewMoreButton>
          </Root>
        </Col>
      </Row>
    </Root>
  )
}
