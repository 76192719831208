export default function remainingLoanTerm(months, settlementDate) {
  if (months === 0) {
    return '-'
  }
  const initialDate = new Date(settlementDate)
  const dateNow = new Date()
  const monthDiff =
    dateNow.getMonth() -
    initialDate.getMonth() +
    12 * (dateNow.getFullYear() - initialDate.getFullYear())
  const totalYears = Math.floor((months - monthDiff) / 12)
  const totalMonths = (months - monthDiff) % 12

  if (totalYears === 0) return `${totalMonths} months`
  if (totalMonths === 0) return `${totalYears} years`
  return `${totalYears} years ${totalMonths} months`
}
