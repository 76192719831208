import React from 'react'
import { Item } from '@react-stately/collections'
import { s, styled } from '@vega/styled/v2'
import { Menu } from '@vega/components'
import { reconciledPaymentsOptions } from './filterOptions'
import DateRangePicker from 'features/common/OverlayDateRangePicker'

const Container = styled.div(s('flex justify-between', { gap: 10 }))
function FiltersBar({
  updateSearchProps,
  statusFilterLabel,
  setStatusFilterLabel,
  filters,
}) {
  const handleFilterAction = (targetFilter) => (value) => {
    updateSearchProps({ filters: { [targetFilter]: value } })
    const updateStatusFilterObject = reconciledPaymentsOptions.find(
      (filter) => filter.key === value
    )
    setStatusFilterLabel(updateStatusFilterObject)
  }

  const onDatesChange = ([startDate, endDate]) => {
    if (!(startDate && endDate)) return
    updateSearchProps({
      filters: {
        startDate,
        endDate,
      },
    })
  }

  const StatusFilter = styled(Menu)(
    s('', {
      minWidth: '195px',
      maxWidth: '195px',
    })
  )

  return (
    <Container>
      <StatusFilter
        label={statusFilterLabel.label || 'Status'}
        aria-label="status"
        onAction={handleFilterAction('status')}
      >
        {reconciledPaymentsOptions.map((option) => (
          <Item key={option.key} value={option.key}>
            {option.label}
          </Item>
        ))}
      </StatusFilter>

      <DateRangePicker
        value={[filters?.startDate || null, filters?.endDate || null]}
        onChange={onDatesChange}
      />
    </Container>
  )
}

export default FiltersBar
