import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { customerService, mortgageAccountService } from 'apiService'
import { normalizeError } from '@vega/services'
import { isNilOrEmpty } from '@solta/ramda-extra'

export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  async (
    { searchTerm: q, pageIndex, limit, filters, sorting },
    { rejectWithValue }
  ) => {
    try {
      return await customerService.fetchCustomers({
        q,
        filters,
        start: limit * pageIndex,
        sorting,
        limit,
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchCustomer = createAsyncThunk(
  'customers/fetchCustomer',
  async ({ customerId }, { rejectWithValue }) => {
    try {
      return await customerService.fetchCustomer(customerId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateCustomer = createAsyncThunk(
  'customers/updateCustomer',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await customerService.updateCustomer(id, payload)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchCustomerByProfileId = createAsyncThunk(
  'customers/fetchCustomerByProfileId',
  async (_, { rejectWithValue }) => {
    try {
      return await customerService.fetchCustomerByProfileId()
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchCustomerBroker = createAsyncThunk(
  'customers/fetchCustomerBroker',
  async (customerId, { rejectWithValue }) => {
    try {
      return await customerService.fetchCustomerBroker(customerId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchCustomerLoans = createAsyncThunk(
  'customers/fetchCustomerLoans',
  async ({ customerId }, { rejectWithValue }) => {
    try {
      return await customerService.fetchCustomerLoans(customerId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchCustomerPayments = createAsyncThunk(
  'customers/fetchCustomerPayments',
  async (
    { customerId, filters, limit, sorting, pageIndex, shouldAppend },
    { rejectWithValue }
  ) => {
    try {
      const data = await customerService.fetchCustomerPayments({
        customerId,
        filters,
        start: limit * pageIndex,
        limit,
        sorting,
      })
      return {
        shouldAppend,
        data,
      }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchCustomerTransactions = createAsyncThunk(
  'customers/fetchCustomerTransactions',
  async (
    { customerId, filters = {}, limit, sorting = {}, pageIndex },
    { rejectWithValue }
  ) => {
    try {
      const { loanGroupId } = filters
      const { recordedAt } = sorting

      return await customerService.fetchCustomerTransactions({
        customerId,
        filters: { loanGroupId },
        start: limit * pageIndex,
        limit,
        sorting: { recordedAt },
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchCustomerLoanTransactions = createAsyncThunk(
  'customers/fetchCustomerLoanTransactions',
  async ({ loanId, filters, limit, sorting = {}, pageIndex }, { rejectWithValue }) => {
    try {
      const { recordedAt } = sorting
      return await mortgageAccountService.fetchLoanTransactions({
        loanId,
        filters,
        start: limit * pageIndex,
        limit,
        sorting: { recordedAt },
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const downloadStatement = createAsyncThunk(
  'customers/downloadStatement',
  async ({ payload, loanId, loanNumber }, { rejectWithValue }) => {
    try {
      return await customerService.downloadStatement(payload, loanId, loanNumber)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const customersAdapter = createEntityAdapter()

const initialState = customersAdapter.getInitialState({
  entities: {},
  ids: [],
  total: undefined,
  currentUserCustomerId: undefined,
  loanGroups: [],
  loans: [],
  payments: [],
  transactions: [],
  broker: { name: '', email: '', mobile: '' },
  noMorePayments: false,
})

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  extraReducers: {
    [fetchCustomers.fulfilled]: (state, action) => {
      const { items: customers, pagination } = action.payload

      customersAdapter.setAll(state, customers)
      state.total = pagination.total
    },

    [fetchCustomer.fulfilled]: (state, action) => {
      customersAdapter.upsertOne(state, action.payload)
    },

    [fetchCustomerByProfileId.fulfilled]: (state, action) => {
      const { id } = action.payload
      state.currentUserCustomerId = id
    },

    [fetchCustomerBroker.fulfilled]: (state, action) => {
      const brokerDetails = action.payload
      state.broker = brokerDetails || initialState.broker
    },

    [fetchCustomerLoans.fulfilled]: (state, action) => {
      const loanGroups = action.payload || []
      const loans = loanGroups.map((loanGroup) => loanGroup.loans).flat() || []

      state.loanGroups = loanGroups
      state.loans = loans
    },

    [fetchCustomerPayments.fulfilled]: (state, action) => {
      const { shouldAppend, data } = action.payload
      const { items: payments } = data

      if (shouldAppend) {
        state.payments = state.payments.concat(payments)
        if (isNilOrEmpty(payments)) state.noMorePayments = true
      } else {
        state.payments = payments || []
        state.noMorePayments = false
      }
    },

    [fetchCustomerTransactions.fulfilled]: (state, action) => {
      const transactions = action.payload
      state.transactions = transactions || []
    },

    [fetchCustomerLoanTransactions.fulfilled]: (state, action) => {
      const transactions = action.payload
      state.transactions = transactions || []
    },
  },
})

const { reducer: customersReducer } = customersSlice

export { customersReducer }
