import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { ReactComponent as CheckBase } from '@vega/components/src/assets/images/check.svg'

const Check = styled(CheckBase)(s('text-grey-700'))
const Checkbox = styled.div(s('mr-2 h-1 w-1 rounded-md bg-secondary'))
const Container = styled.div(s('mb-6 flex items-center'))
const Text = styled.div(
  s('text-base text-grey-900 font-normal', { letterSpacing: '0.01em', lineHeight: 1.5 })
)

const ApprovingLoan = ({ loanNumber, loanAmount, productInfo, ...props }) => {
  return (
    <Container {...props}>
      <Checkbox>
        <Check />
      </Checkbox>

      <Text>
        {loanNumber} - {loanAmount} - {productInfo}
      </Text>
    </Container>
  )
}

export { ApprovingLoan }
