import React from 'react'
import { styled, s } from '@vega/styled/v2'

const Container = styled.div(s('flex flex-column w-full'))

export const Label = styled.span(s('text-grey-600 mb-2 ', { fontSize: 14 }))
export const Text = styled.span(
  s('block text-grey-900 font-semibold text-xl', {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  })
)

export const PreviewDataBlock = ({ label, children, ...otherProps }) => (
  <Container {...otherProps}>
    <Label>{label}</Label>
    <Text>{children}</Text>
  </Container>
)
