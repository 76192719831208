import React from 'react'
import PropTypes from 'prop-types'

import { styled, s } from '@vega/styled'
import { prop } from '@solta/ramda-extra'

// adapted from: https://projects.lukehaas.me/css-loaders/
const circleSizes = {
  sm: {
    fontSize: '3px',
    width: '3px',
    height: '3px',
  },
  md: {
    fontSize: '4px',
    width: '4px',
    height: '4px',
  },
  lg: {
    fontSize: '6px',
    width: '8px',
    height: '8px',
  },
}

const accentColor = s('text-green-600').color
const defaultColor = s('text-grey-200').color

const dottedRunningCircle = `@keyframes loader {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em ${accentColor}, 1.8em -1.8em 0 0em ${defaultColor}, 2.5em 0em 0 0em ${defaultColor}, 1.75em 1.75em 0 0em ${defaultColor}, 0em 2.5em 0 0em ${defaultColor}, -1.8em 1.8em 0 0em ${defaultColor}, -2.6em 0em 0 0em ${defaultColor}, -1.8em -1.8em 0 0em ${defaultColor};
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em ${defaultColor}, 1.8em -1.8em 0 0em ${accentColor}, 2.5em 0em 0 0em ${defaultColor}, 1.75em 1.75em 0 0em ${defaultColor}, 0em 2.5em 0 0em ${defaultColor}, -1.8em 1.8em 0 0em ${defaultColor}, -2.6em 0em 0 0em ${defaultColor}, -1.8em -1.8em 0 0em ${defaultColor};
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em ${defaultColor}, 1.8em -1.8em 0 0em ${defaultColor}, 2.5em 0em 0 0em ${accentColor}, 1.75em 1.75em 0 0em ${defaultColor}, 0em 2.5em 0 0em ${defaultColor}, -1.8em 1.8em 0 0em ${defaultColor}, -2.6em 0em 0 0em ${defaultColor}, -1.8em -1.8em 0 0em ${defaultColor};
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em ${defaultColor}, 1.8em -1.8em 0 0em ${defaultColor}, 2.5em 0em 0 0em ${defaultColor}, 1.75em 1.75em 0 0em ${accentColor}, 0em 2.5em 0 0em ${defaultColor}, -1.8em 1.8em 0 0em ${defaultColor}, -2.6em 0em 0 0em ${defaultColor}, -1.8em -1.8em 0 0em ${defaultColor};
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em ${defaultColor}, 1.8em -1.8em 0 0em ${defaultColor}, 2.5em 0em 0 0em ${defaultColor}, 1.75em 1.75em 0 0em ${defaultColor}, 0em 2.5em 0 0em ${accentColor}, -1.8em 1.8em 0 0em ${defaultColor}, -2.6em 0em 0 0em ${defaultColor}, -1.8em -1.8em 0 0em ${defaultColor};
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em ${defaultColor}, 1.8em -1.8em 0 0em ${defaultColor}, 2.5em 0em 0 0em ${defaultColor}, 1.75em 1.75em 0 0em ${defaultColor}, 0em 2.5em 0 0em ${defaultColor}, -1.8em 1.8em 0 0em ${accentColor}, -2.6em 0em 0 0em ${defaultColor}, -1.8em -1.8em 0 0em ${defaultColor};
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em ${defaultColor}, 1.8em -1.8em 0 0em ${defaultColor}, 2.5em 0em 0 0em ${defaultColor}, 1.75em 1.75em 0 0em ${defaultColor}, 0em 2.5em 0 0em ${defaultColor}, -1.8em 1.8em 0 0em ${defaultColor}, -2.6em 0em 0 0em ${accentColor}, -1.8em -1.8em 0 0em ${defaultColor};
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em ${defaultColor}, 1.8em -1.8em 0 0em ${defaultColor}, 2.5em 0em 0 0em ${defaultColor}, 1.75em 1.75em 0 0em ${defaultColor}, 0em 2.5em 0 0em ${defaultColor}, -1.8em 1.8em 0 0em ${defaultColor}, -2.6em 0em 0 0em ${defaultColor}, -1.8em -1.8em 0 0em ${accentColor};
  }
}`

const AnimatedContainer = styled.div(
  s('mx-6 my-2 relative'),
  dottedRunningCircle,
  {
    borderRadius: '50%',
    animation: `loader 1s infinite linear`,
  },
  ({ size }) => circleSizes[size],
  prop('customStyles')
)

function DottedCircleLoader({ size = 'sm', customStyles, ...otherProps }) {
  return <AnimatedContainer size={size} customStyles={customStyles} {...otherProps} />
}

DottedCircleLoader.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
}

export default DottedCircleLoader
