import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { useLargeMediaQuery } from '@vega/styled'
import { LoanOverview } from './LoanOverview'
import { Loading } from '@vega/components'
import { Divider as BaseDivider } from '@vega/components/src/v2'

const Root = styled.div(s('bg-white rounded-lg flex flex-column px-5 pb-6 py-2'))
const Divider = styled(BaseDivider)(({ isHidden }) => isHidden && s('hidden'))
const LoanGroupTitle = styled.h3(
  s('flex-row bg-grey-100 py-2 pl-4 mt-6 font-semibold', {
    borderRadius: 6,
  })
)

export function LoanOverviewContainer({ loans, isLoading }) {
  const isLargeScreen = useLargeMediaQuery('larger')

  if (isLoading)
    return (
      <Root style={s('py-16')}>
        <Loading />
      </Root>
    )

  return (
    <Root>
      <LoanGroupTitle>Your Home Loans</LoanGroupTitle>
      {loans.map((loan, index) => (
        <>
          <LoanOverview loan={loan} key={index} isLargeScreen={isLargeScreen} />
          <Divider isHidden={index === loans.length - 1} />
        </>
      ))}
    </Root>
  )
}
