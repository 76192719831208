/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { map } from '@solta/ramda-extra'
import { differenceInDays } from 'date-fns'
import { s, styled } from '@vega/styled/v2'
import { formatDateString } from 'utils/date'
import { MORTGAGE_ACCOUNT_CONSTANTS } from '@neo/constants'
import { formatCurrency } from 'utils/formatter'
import { Tooltip } from '@mui/material'

const {
  STATUS: { APPROVED, PENDING, REQUESTED },
} = MORTGAGE_ACCOUNT_CONSTANTS

const MORTGAGE_ACCOUNT_STATUS = {
  [APPROVED]: 'Approved',
  [PENDING]: 'Pending',
  [REQUESTED]: 'Requested',
}

const StatusPill = styled.div(
  s('rounded-2xl px-3 py-1 h-2 flex items-center justify-center font-semibold'),
  ({ status }) => (status === APPROVED ? s('bg-green-100') : s('bg-grey-100'))
)

const RecentlyAddedPill = styled.div(
  s(
    'bg-secondary-yellow rounded-2xl px-3 py-1 h-2 flex items-center justify-center font-semibold'
  ),
  { whiteSpace: 'nowrap' }
)

const headerStyleBase = (customStyles) =>
  s(' inline-block text-grey-600 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s(' inline-block text-grey-800 text-sm text-left flex items-center', {
    ...customStyles,
  })

const TextOverflowEllipsis = styled.span({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Added Date',
        accessor: (row) => formatDateString(row.createdAt),
        tableHeaderStyles: headerStyleBase({ width: '7%' }),
        tableCellStyles: cellStyleBase({ width: '7%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Customers',
        accessor: 'applicantNames',
        tableHeaderStyles: headerStyleBase({ width: '29%' }),
        tableCellStyles: cellStyleBase(s('font-semibold', { width: '29%' })),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Loan No.',
        accessor: 'loanNumber',
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Loan Group No.',
        accessor: 'groupNumber',
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Loan Product',
        accessor: 'productName',
        tableHeaderStyles: headerStyleBase({ width: '17%' }),
        tableCellStyles: cellStyleBase({ width: '17%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Total Loan Request',
        accessor: (row) => formatCurrency(row?.loanAmount),
        tableHeaderStyles: headerStyleBase(s('', { width: '8%' })),
        tableCellStyles: cellStyleBase(s('font-semibold', { width: '8%' })),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        tableHeaderStyles: headerStyleBase(s('text-center', { width: '8%' })),
        tableCellStyles: cellStyleBase(
          s('justify-center font-semibold', { width: '8%' })
        ),
        Cell: ({ value: status }) => {
          return (
            <Tooltip title={status}>
              <StatusPill status={status}>
                <TextOverflowEllipsis>
                  {MORTGAGE_ACCOUNT_STATUS[status]}
                </TextOverflowEllipsis>
              </StatusPill>
            </Tooltip>
          )
        },
      },
      {
        Header: '',
        accessor: 'isRecentlyAdded',
        tableHeaderStyles: headerStyleBase({ width: '11%' }),
        tableCellStyles: cellStyleBase({ width: '11%' }),
        Cell: ({ value: isRecentlyAdded }) => {
          if (!isRecentlyAdded) return null
          return (
            <Tooltip title="Recently Added">
              <div style={s('flex items-center justify-center w-full')}>
                <TextOverflowEllipsis>
                  <RecentlyAddedPill>
                    <TextOverflowEllipsis>Recently Added</TextOverflowEllipsis>
                  </RecentlyAddedPill>
                </TextOverflowEllipsis>
              </div>
            </Tooltip>
          )
        },
      },
    ],
    []
  )
}

export const TableData = ({ mortgageAccounts }) => {
  return React.useMemo(
    () =>
      map((account) => {
        const dayPassedSinceAccountAdded = differenceInDays(
          new Date(),
          new Date(account?.createdAt)
        )
        const isRecentlyAdded = dayPassedSinceAccountAdded < 1

        return {
          ...account,
          isRecentlyAdded,
        }
      }, mortgageAccounts),
    [mortgageAccounts]
  )
}
