import React, { useEffect } from 'react'
import { Row, Col, Container as ContainerBase } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import Overview from 'features/admin/homePage/pageSections/Overview'
import MortgageOverview from 'features/admin/homePage/pageSections/MortgageOverview'
import UpcomingSettlements from 'features/admin/homePage/pageSections/UpcomingSettlements'
import UpcomingPayments from 'features/admin/homePage/pageSections/UpcomingPayments'
import { useSelector, useDispatch } from 'react-redux'
import { MonthPicker, DottedCircleLoader } from '@vega/components'
import {
  fetchReportData,
  selectReport,
  selectIsFetchReportDataPending,
} from 'modules/dashboard'
import { calculatePercentageChange } from 'features/admin/homePage/utils/calculatePercentageChange'
import { findCurrentMonth } from 'features/admin/homePage/utils/findCurrentMonth'
import { useFormikContext } from 'formik'

const Container = styled(ContainerBase)(
  s('flex flex-column border-grey-200 rounded-lg p-5 bg-white', {
    boxShadow:
      '0px 2px 4px -1px rgba(21, 61, 58, 0.06), 0px 4px 6px -1px rgba(21, 61, 58, 0.1)',
    height: 180,
  })
)

const Title = styled.div(
  s('font-semibold text-primary', { fontSize: 28, marginTop: 30 })
)
const Space = styled.div(s('bg-green-100 flex my-4 justify-between', { gap: 16 }))

function HomePageOverview() {
  const { setFieldValue } = useFormikContext()
  const onMonthPickerMonthChanged = ({ year, month }) => {
    setFieldValue('currentYear', year)
    setFieldValue('currentMonth', month)
  }
  const dispatch = useDispatch()
  const report = useSelector(selectReport)
  const isFetchingReportData = useSelector(selectIsFetchReportDataPending)
  const { values: formikValues } = useFormikContext()
  const { currentYear, currentMonth } = formikValues

  useEffect(() => {
    dispatch(fetchReportData())
  }, [dispatch])

  if (isFetchingReportData)
    return (
      <Container style={s('flex items-center justify-center h-10')}>
        <DottedCircleLoader size="lg" />
      </Container>
    )

  const num = findCurrentMonth(report, currentMonth, currentYear)

  const queriedMonthReport = report[num] || {}
  const prevMonthReport = report[num + 1] || {}

  const {
    avgLoan,
    totalCustomers,
    totalNumberOfLoans,
    pendingLoans,
    approvedLoans,
    totalValueOfLoans,
    loanPurposeBreakdown,
    mortgageCountByLocation,
  } = queriedMonthReport

  const {
    avgLoan: preavgLoan,
    totalCustomers: pretotalCustomers,
    pendingLoans: prependingLoans,
    approvedLoans: preapprovedLoans,
  } = prevMonthReport

  if (isFetchingReportData)
    return (
      <Container style={s('flex items-center justify-center h-10')}>
        <DottedCircleLoader size="lg" />
      </Container>
    )

  return (
    <Row style={{ width: '200%' }}>
      <Col span={16} style={s('ml-4 items-start ')}>
        <Row
          style={s('items-start justify-between ', {
            width: '145%',
            marginLeft: '0.5%',
          })}
        >
          <Title className="sohne" style={s('ml-8', { left: 400 })}>
            Dashboard
          </Title>
          <Col style={s('items-start justify-between')}>
            <Space />

            <MonthPicker
              onMonthChanged={onMonthPickerMonthChanged}
              pickerStyle={{ left: 'initial', right: '5%', top: 80 }}
            />
          </Col>
        </Row>

        <Space />

        <Overview
          totalClients={totalCustomers}
          averageLoanSize={Math.round(avgLoan)}
          customerPercentageChanges={calculatePercentageChange(
            totalCustomers,
            pretotalCustomers
          )}
          mortgagePercentageChanges={calculatePercentageChange(
            Math.round(avgLoan),
            Math.round(preavgLoan)
          )}
          year={currentYear}
          month={currentMonth}
        />

        <Space />
        <MortgageOverview
          totalNumberOfLoans={totalNumberOfLoans}
          pendingLoans={pendingLoans}
          approvalLoans={approvedLoans}
          totalValueOfLoans={totalValueOfLoans}
          pendingPercentageChanges={calculatePercentageChange(
            pendingLoans,
            prependingLoans
          )}
          approvalPercentageChanges={calculatePercentageChange(
            approvedLoans,
            preapprovedLoans
          )}
          year={currentYear}
          month={currentMonth}
          loanPurposeBreakdown={loanPurposeBreakdown}
          mortgageCountByLocation={mortgageCountByLocation}
        />
      </Col>

      <Col
        span={8}
        style={s(' items-start justify-start ', {
          marginLeft: '-2%',
          marginTop: 79,
        })}
      >
        <Space style={s('items-start justify-start', { gap: 56 })} />
        <UpcomingSettlements style={s('mt-10')} />
        <Space />
        <UpcomingPayments />
      </Col>
    </Row>
  )
}

export default HomePageOverview
