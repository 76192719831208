import { mergeDeepRight } from '@solta/ramda-extra'
import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    persistentNavDrawer: {
      isOpen: true,
    },
    temporaryNavDrawer: {
      isOpen: false,
      isMounted: false,
      leanMode: false,
    },
    topBar: {
      isOpen: true,
    },
    isSideAccountNavOpen: false,
  },
  reducers: {
    setPersistentNavDrawerState: (state, action) => {
      state.persistentNavDrawer = mergeDeepRight(
        state.persistentNavDrawer,
        action.payload
      )
    },
    setTemporaryNavDrawerState: (state, action) => {
      state.temporaryNavDrawer = mergeDeepRight(
        state.temporaryNavDrawer,
        action.payload
      )
    },
    setTopBarState: (state, action) => {
      state.topBar = mergeDeepRight(state.topBar, action.payload)
    },
    setIsSideAccountNavOpen: (state, action) => {
      state.isSideAccountNavOpen = action.payload
        ? action.payload
        : !state.isSideAccountNavOpen
    },
  },
})

export const {
  setPersistentNavDrawerState,
  setTemporaryNavDrawerState,
  setTopBarState,
  setIsSideAccountNavOpen,
} = uiSlice.actions

const { reducer: uiReducer } = uiSlice
export { uiReducer }
