import React from 'react'
import { noop, isNilOrEmpty } from '@solta/ramda-extra'
import { s, styled } from '@vega/styled/v2'
import { useHistory, useLocation } from 'react-router-dom'
import { InterestLoanTypeDisplayNameMapper } from 'features/customer/myAccount/displayNameMappers'
import { formatCurrency } from 'utils/formatter'
import { Row, Col } from '@vega/styled'

const Container = styled.div(
  s('flex flex-column rounded-lg justify-center p-4 no-decoration mr-1 mt-1', {
    transition: '0.4s',
    cursor: 'pointer',
    ':hover': s('bg-secondary'),
  }),
  ({ isActive }) =>
    isActive &&
    s('bg-secondary rounded-lg ', {
      borderColor: '#E9E9DB',
    })
)

const LoanNumber = styled.h2(s('text-xs font-normal m-0 mb-2 text-grey-900'))

const Title = styled.h3(
  s('text-base font-bold text-grey-700 mb-1 text-lg m-0 text-primary', {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  })
)

const SubTitle = styled.div(
  s('text-xs font-normal text-grey-600 mb-1', { lineHeight: 1.5 })
)

const Amount = styled.div(s('text-sm font-semibold text-grey-700', { lineHeight: 1.5 }))

const monthsToYears = (months) => {
  try {
    if (isNilOrEmpty(months)) return ''

    return months / 12
  } catch (error) {
    return ''
  }
}

const NavItem = ({ loan, path, onItemClick = noop, ...props }) => {
  const history = useHistory()

  const {
    loanNumber,
    balance,
    loanLimit,
    loanTermInMonths,
    loanGroupStatus,
    customLoanName,
  } = loan
  const { repaymentType, displayName } = loan?.product || {}

  const { pathname } = useLocation()
  const isActive = pathname.startsWith(path)

  const handleClick = () => {
    history.push(path)
    onItemClick()
  }

  return (
    <Container
      onClick={handleClick}
      isActive={isActive}
      {...props}
      style={{ marginLeft: 5 }}
    >

      <div>
        <Title className="sohne">
          <span className="sohne">{customLoanName || displayName}</span>
        </Title>
        <LoanNumber>{loanNumber}</LoanNumber>
      </div>

      <Row>
        <Col span={24} lg={12}>- {monthsToYears(loanTermInMonths)} years</Col>
        <Col span={24} lg={12}>- {InterestLoanTypeDisplayNameMapper[repaymentType] || '-'}</Col>
      </Row>

      {/* TODO: Balance by default is negative but might still need to ensure it is negative */}
      {/* TODO: Later on we should hide Loan Balance from the list customer loans endpoint if the loan group is not approved */}
      <Row>
        <Col span={24} lg={12}>
          <SubTitle style={s('font-bold')}>Loan Balance</SubTitle>
          <Amount style={s('font-bold')}>
            {loanGroupStatus === 'approved' ? formatCurrency(balance, 2) : '-'}
          </Amount>
        </Col>
        <Col span={24} lg={12}>
          <SubTitle>Loan Limit</SubTitle>
          <Amount>-{formatCurrency(loanLimit, 2)}</Amount>
        </Col>
      </Row>
    </Container>
  )
}

export default NavItem
