import { isNilOrEmpty } from '@solta/ramda-extra'
import { normalizeError } from './normalizeError'
import { createCustomError } from './createCustomError'

export const thunkErrorProcessor = async (err) => {
  let normalizedError = await normalizeError(err)
  const errorIsNotCustomized = isNilOrEmpty(normalizedError?.isCustomError)

  if (errorIsNotCustomized) {
    normalizedError = customizeErrorForUIToHandle(normalizedError)
  }

  return normalizedError
}

function customizeErrorForUIToHandle(error) {
  return createCustomError({
    type: error?.error,
    description: error?.message,
    ...error,
  })
}
