import {
  ProductService,
  MortgageAccountService,
  CustomerService,
  AccountService,
  TransactionService,
  PaymentService,
  ReportService,
  LoanGroupService,
  PreferenceService,
  LegalEntityService,
  ProfileService,
  ReconciledPaymentsService,
} from '@vega/services'

const bankingApiUri = process.env.REACT_APP_BANKING_API_URI

const profileApiUri = process.env.REACT_APP_PROFILE_API_URI

const getOptions = (baseUri) => ({ baseUri })

export const productService = new ProductService(getOptions(bankingApiUri))
export const mortgageAccountService = new MortgageAccountService(
  getOptions(bankingApiUri)
)
export const customerService = new CustomerService(getOptions(bankingApiUri))
export const accountService = new AccountService(getOptions(bankingApiUri))
export const transactionService = new TransactionService(getOptions(bankingApiUri))
export const paymentService = new PaymentService(getOptions(bankingApiUri))
export const reportService = new ReportService(getOptions(bankingApiUri))
export const loanGroupService = new LoanGroupService(getOptions(bankingApiUri))
export const preferenceService = new PreferenceService(getOptions(profileApiUri))
export const legalEntityService = new LegalEntityService(getOptions(bankingApiUri))
export const profileService = new ProfileService(getOptions(profileApiUri))
export const reconciledPaymentsService = new ReconciledPaymentsService(
  getOptions(bankingApiUri)
)
