import BaseService from '../baseService'
import { accounts } from './mocks/account'

export class AccountService extends BaseService {
  fetchAccounts() {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(accounts)
      }, 1000)
    )
  }

  fetchAccount(id) {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(accounts.items.filter((account) => account.id === id)[0])
      }, 1000)
    )
  }
}
