import React from 'react'
import { Row, Col, larger } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'

import { InterestLoanTypeDisplayNameMapper } from '../displayNameMappers'
import { ReactComponent as ChevronUp } from '@vega/components/src/assets/images/chevron-up.svg'

import { formatMonthsToYears } from 'utils/formatter'

const ChevronUpIcon = styled(ChevronUp)(s('ml-2'), {
  cursor: 'pointer',
  path: { stroke: s('text-primry').color },
})
const CollapsableBox = styled.div(
  s('px-8 py-6'),
  { background: 'rgba(241, 241, 232, 0.58)' },
  larger('lg')(s('px-6'))
)

const CollapseButton = styled.div(
  s('pt-6 text-center text-sm font-semibold text-primary'),
  { cursor: 'pointer' },
  larger('lg')(s('px-6'))
)

const Label = styled.span(s('text-sm font-normal text-grey-600'))

const Value = styled.span(s('font-bold'))

export const ExpandedLoanBox = ({ product = [], onClick }) => {
  return (
    <CollapsableBox>
      <div style={s('mb-6')}>
        <Row style={s('mb-1')}>
          <Col span={12}>
            <Label>Initial Loan Type</Label>
          </Col>

          <Col span={12}>
            <Label>Initial Interest Only Term</Label>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Value>
              {InterestLoanTypeDisplayNameMapper[product?.initialRepaymentType] || '-'}
            </Value>
          </Col>

          <Col span={12}>
            <Value>{formatMonthsToYears(product?.initialPeriod) || '-'}</Value>
          </Col>
        </Row>
      </div>

      <div>
        <Row style={s('mb-1')}>
          <Col span={12}>
            <Label>Ongoing Loan Type</Label>
          </Col>

          <Col span={12}>
            <Label>Ongoing Term</Label>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Value>
              {InterestLoanTypeDisplayNameMapper[product?.ongoingRepaymentType] || '-'}
            </Value>
          </Col>

          <Col span={12}>
            <Value>{formatMonthsToYears(product?.maxTermForInterestOnly) || '-'}</Value>
          </Col>
        </Row>
      </div>
      <CollapseButton onClick={onClick}>
        <Row style={s('justify-center')}>
          <div>Collapse</div>
          <ChevronUpIcon width={20} height={20} />
        </Row>
      </CollapseButton>
    </CollapsableBox>
  )
}
