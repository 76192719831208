import React from 'react'
import { Row, Col, Container as ContainerBase } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { DottedCircleLoader } from '@vega/components'
import { formatCurrency } from 'utils/formatter'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes'
import { TEST_IDS } from '@neo/constants'

const Container = styled(ContainerBase)(
  s('flex flex-column border-grey-200 rounded-lg p-5 bg-white', {
    boxShadow:
      '0px 2px 4px -1px rgba(21, 61, 58, 0.06), 0px 4px 6px -1px rgba(21, 61, 58, 0.1)',
    height: 215,
  })
)
const ValueTitle = styled.div(s('font-normal text-sm text-grey-600'))
const Value = styled.div(s('mt-1 text-base text-grey-900 font-semibold'))
const ViewButton = styled.div(
  s('text-primary font-semibold text-sm mr-3', { cursor: 'pointer' })
)

function SecurityBox({ isLoading, securities = [], guarantors = [], loanId }) {
  const totalSecurityValue = securities.reduce((currentTotal, security) => {
    const totalNumberOfValuations = security?.valuations.length

    if (totalNumberOfValuations === 1) {
      return security?.valuations[0]?.value + currentTotal
    }

    return security?.valuations[totalNumberOfValuations - 1]?.value + currentTotal
  }, 0)

  const history = useHistory()

  const handleOnClick = () => {
    history.push(`${routes.admin.loans.root}/${loanId}/security-details`)
  }

  if (isLoading) {
    return (
      <Container style={s('flex items-center justify-center h-10')}>
        <DottedCircleLoader size="lg" />
      </Container>
    )
  }

  return (
    <Container>
      <Row>
        <Col span={20}>
          <ValueTitle>Total Security Value</ValueTitle>
        </Col>

        <Col span={4} style={s('flex justify-end')}>
          <ViewButton
            onClick={handleOnClick}
            data-test-id={TEST_IDS.SECURITY_DETAIL_VIEW_BTN}
          >
            View
          </ViewButton>
        </Col>
      </Row>

      <Row>
        <Col>
          <Value data-test-id={TEST_IDS.TOTAL_SECURITY_VALUE}>
            {formatCurrency(totalSecurityValue, 2)}
          </Value>
        </Col>
      </Row>

      <Row style={s('mt-4')}>
        <Col span={12}>
          <ValueTitle># of Securities</ValueTitle>
          <Value data-test-id={TEST_IDS.NUMBER_OF_SECURITIES}>
            {securities.length + guarantors.length}
          </Value>
        </Col>
      </Row>
    </Container>
  )
}

export default SecurityBox
