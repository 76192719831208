import React, { useState } from 'react'
import { Item } from '@react-stately/collections'
import { s, styled } from '@vega/styled/v2'
import { Button as ButtonBase, DropdownCheckbox } from '@vega/components'
import { ReactComponent as ResetIcon } from '@vega/components/src/assets/images/refresh.svg'
import { isEmpty } from '@solta/ramda-extra'

import DateRangePicker from 'features/common/OverlayDateRangePicker'

import {
  productFilterOptions,
  interestFilterOptions,
  statusFilterOptions,
  interestRateFilterOptions,
} from './filterOptions'

const Container = styled.div(s('flex justify-between', { gap: 10 }))

const Button = styled(ButtonBase)(
  s(
    'bg-white border-solid border-1 border-grey-200 py-3 px-4 text-sm text-grey-600 rounded-lg'
  )
)

function FiltersBar({ updateSearchProps, filters }) {
  const [selectedProductTypeKeys, setSelectedProductTypeKeys] = useState(new Set())
  const [selectedInterestTypeKeys, setSelectedInterestTypeKeys] = useState(new Set())
  const [selectedStatusKeys, setSelectedStatusKeys] = useState(new Set())
  const onDatesChange = ([startDate, endDate]) => {
    if (!(startDate && endDate)) return
    updateSearchProps({
      filters: {
        startDate,
        endDate,
      },
    })
  }

  function handleProductTypeFilterChange(values) {
    setSelectedProductTypeKeys(values)

    updateSearchProps({
      filters: {
        product: Array.from(values),
      },
    })
  }

  function handleInterestTypeFilterChange(values) {
    setSelectedInterestTypeKeys(values)

    updateSearchProps({
      filters: {
        interest: Array.from(values),
      },
    })
  }

  function handleStatusFilterChange(values) {
    setSelectedStatusKeys(values)

    updateSearchProps({
      filters: {
        status: Array.from(values),
      },
    })
  }

  function handleResetFilters() {
    setSelectedProductTypeKeys(new Set())
    setSelectedInterestTypeKeys(new Set())
    setSelectedStatusKeys(new Set())

    updateSearchProps({
      filters: {
        product: [],
        interest: [],
        status: [],
      },
    })
  }

  return (
    <Container>
      <DropdownCheckbox
        label="Product type"
        aria-label="product-type"
        selectedKeys={selectedProductTypeKeys}
        onSelectionChange={handleProductTypeFilterChange}
      >
        {productFilterOptions.map((option) => (
          <Item key={option.key}>{option.label}</Item>
        ))}
      </DropdownCheckbox>

      <DropdownCheckbox
        label="Interest type"
        aria-label="interest-type"
        selectedKeys={selectedInterestTypeKeys}
        onSelectionChange={handleInterestTypeFilterChange}
      >
        {interestFilterOptions.map((option) => (
          <Item key={option.key}>{option.label}</Item>
        ))}
      </DropdownCheckbox>

      <DropdownCheckbox label="Interest rate" aria-label="interest-rate">
        {interestRateFilterOptions.map((option) => (
          <Item key={option.key}>{option.label}</Item>
        ))}
      </DropdownCheckbox>

      <DropdownCheckbox
        label={isEmpty(filters.status) ? 'Status: All' : 'Status'}
        aria-label="status"
        selectedKeys={selectedStatusKeys}
        onSelectionChange={handleStatusFilterChange}
      >
        {statusFilterOptions.map((option) => (
          <Item key={option.key}>{option.label}</Item>
        ))}
      </DropdownCheckbox>

      <DateRangePicker
        value={[filters.startDate, filters.endDate]}
        onChange={onDatesChange}
      />

      <Button
        StartIcon={<ResetIcon style={s('w-1 h-1 text-grey-400 mr-1')} />}
        onClick={handleResetFilters}
      >
        Reset
      </Button>
    </Container>
  )
}

export default FiltersBar
