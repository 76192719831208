import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Row } from '@vega/styled'
import { formatCurrency } from 'utils/formatter'

const ProgressBarContainer = styled.div(
  s('bg-white rounded-lg px-2 h-4 h-full', {
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    width: '96%',
  })
)
const Title = styled.div(s('justify-end mt-2 text-grey-600 text-sm mb-4 '))
const Space = styled.div(s('bg-white flex my-4 justify-between text-base'))
const EmptyBar = styled.div(s('bg-grey-100 rounded-lg', { height: 12 }))
const FilledBar = styled.div(
  s('bg-secondary rounded-lg w-0 justify-center', {
    height: 12,
    marginTop: -16,
  }),
  ({ percentageFilled }) => percentageFilled && { width: `${percentageFilled}%` }
)

const getPercentageFilled = (valueMoved, fullValue) => {
  const negativeFullValue = -fullValue
  const percentage = (valueMoved / negativeFullValue) * 100
  if (100 - percentage > 100) return 100
  return 100 - percentage
}

export function PaymentProgressBar({ loanBalance, loanAmount }) {
  return (
    <ProgressBarContainer>
      <Space />
      <EmptyBar>
        <FilledBar percentageFilled={getPercentageFilled(loanBalance, loanAmount)} />
        <Row style={s('justify-end')}>
          <Title style={s('font-bold text-black mr-2')}>
            {formatCurrency(loanBalance, 2)}
          </Title>
          <Title style={s('mb-4 mr-2')}> remaining</Title>
          <Space />
        </Row>
      </EmptyBar>
      <Space />
    </ProgressBarContainer>
  )
}
