import { prop, isNilOrEmpty } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { customersAdapter } from './module'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

const selectCustomers = prop('customers')

export const selectTotal = createSelector(selectCustomers, prop('total'))
export const selectNoMorePayments = createSelector(
  selectCustomers,
  prop('noMorePayments')
)
export const selectCurrentUserCustomerId = createSelector(
  selectCustomers,
  prop('currentUserCustomerId')
)

export const {
  selectEntities: selectCustomerEntities,
  selectAll: selectAllCustomers,
  selectById: selectCustomerById,
} = customersAdapter.getSelectors(prop('customers'))

export const selectCustomerLoanGroups = createSelector(
  selectCustomers,
  prop('loanGroups')
)
export const selectCustomerLoans = createSelector(selectCustomers, prop('loans'))

export const selectCustomerLoanById = (loanId) =>
  createSelector(selectCustomerLoans, (loans = []) => {
    if (isNilOrEmpty(loans)) return {}

    return loans.find((loan) => loan?._id === loanId)
  })

export const selectCustomerLoanGroupById = (loanGroupId) =>
  createSelector(selectCustomerLoanGroups, (loanGroups = []) => {
    if (isNilOrEmpty(loanGroups)) return {}

    return loanGroups.find((loanGroup) => loanGroup?._id === loanGroupId) || {}
  })

export const selectCustomerPayments = createSelector(selectCustomers, prop('payments'))

export const selectCustomerPaymentsByLoanId = (loanId) =>
  createSelector(selectCustomerPayments, (payments = []) => {
    if (isNilOrEmpty(payments)) return {}

    return payments.find((payment) => payment?.loanId === loanId)
  })

export const { selectIsPending: selectIsStatementPending } = createAsyncThunkSelectors(
  'customers/downloadStatement'
)

export const selectCustomerTransactions = createSelector(
  selectCustomers,
  prop('transactions')
)

export const selectCustomerBroker = createSelector(selectCustomers, prop('broker'))

export const {
  selectIsPending: selectIsFetchCustomersPending,
} = createAsyncThunkSelectors('customers/fetchCustomers')

export const {
  selectIsPending: selectIsFetchCustomerPending,
} = createAsyncThunkSelectors('customers/fetchCustomer')

export const {
  selectIsPending: selectIsFetchCustomerBrokerPending,
} = createAsyncThunkSelectors('customers/fetchCustomerBroker')

export const {
  selectIsPending: selectIsFetchCustomerByProfileIdPending,
} = createAsyncThunkSelectors('customers/fetchCustomerByProfileId')

export const {
  selectIsPending: selectIsFetchCustomerLoansPending,
} = createAsyncThunkSelectors('customers/fetchCustomerLoans')

export const {
  selectIsPending: selectIsFetchCustomerPaymentsPending,
} = createAsyncThunkSelectors('customers/fetchCustomerPayments')

export const {
  selectIsPending: selectIsFetchCustomerTransactionsPending,
} = createAsyncThunkSelectors('customers/fetchCustomerTransactions')

export const {
  selectIsPending: selectIsFetchCustomerLoanTransactionsPending,
} = createAsyncThunkSelectors('customers/fetchCustomerLoanTransactions')
