const PAYMENT_STATUS = /** @type {const} */ ({
  NOT_DUE_YET: 'not_due_yet',
  DUE_TODAY: 'due_today',
  OVERDUE_LESS_THAN_30_DAYS: 'overdue_less_than_30_days',
  OVERDUE_LESS_THAN_60_DAYS: 'overdue_less_than_60_days',
  OVERDUE_GREATER_THAN_60_DAYS: 'overdue_greater_than_60_days',
})

const PAYMENT_METHOD = /** @type {const} */ ({
  DIRECT_DEBIT: 'direct_debit',
})

const REPAYMENT_FREQUENCY = /** @type {const} */ ({
  ONE_OFF: 'one_off',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  FORTNIGHTLY: 'fortnightly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  BIANNUALLY: 'biannually',
  ANNUALLY: 'annually',
  SEASONAL: 'seasonal',
})

const RECONCILED_PAYMENT_STATUS_CONSTANTS = /** @type {const} */ ({
  PAID: 'paid',
  DUPLICATED_PAYMENT: 'duplicated_payment',
  UNMATCHED_REFERENCE: 'unmatched_reference',
  UNMATCHED_AMOUNT: 'unmatched_amount',
  PAYMENT_ERROR: 'payment_error',
})

export {
  PAYMENT_STATUS,
  PAYMENT_METHOD,
  REPAYMENT_FREQUENCY,
  RECONCILED_PAYMENT_STATUS_CONSTANTS,
}
