/* eslint-disable react/no-multi-comp */
import React from 'react'
import { Row, Col, larger } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { DottedCircleLoader } from '@vega/components'
import { ExpandedPropertyBox } from './ExpandedPropertyBox'
import { ReactComponent as ChevronDown } from '@vega/components/src/assets/images/chevron-down.svg'

const Container = styled.div(s('bg-white shadow-sm rounded-lg'))
const TopContainer = styled.div(s('px-8 py-8'), larger('lg')(s('px-6')))

const TitleContainer = styled.div(s('flex justify-between items-center mt-9'))
const Title = styled.div(s('text-xl font-semibold text-primary', { lineHeight: 1.5 }))
const ChevronDownIcon = styled(ChevronDown)(s('ml-2'), {
  cursor: 'pointer',
  path: { stroke: s('text-primary').color },
})
const FieldLabel = styled.span(
  s('font-normal text-base text-grey-800', {
    lineHeight: '150%',
  }),
  larger('lg')(s('text-sm'))
)

const Value = styled.span(
  s('font-normal text-base font-bold', {
    lineHeight: '150%',
    color: '#141616',
  }),
  larger('lg')(s('text-sm'))
)

const ExpandButton = styled.div(
  s('px-8 text-center text-sm font-semibold text-primary py-4'),
  { background: 'rgba(241, 241, 232, 0.58)' },
  { cursor: 'pointer' },
  larger('lg')(s('px-6'))
)

export const PropertyCard = ({ isLoading = true, properties = [] }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  if (isLoading)
    return (
      <>
        <TitleContainer>
          <Title className="sohne">Properties</Title>
        </TitleContainer>
        <Container style={s('h-12 flex items-center justify-center')}>
          <DottedCircleLoader size="md" />
        </Container>
      </>
    )

  return (
    <>
      <TitleContainer>
        <Title className="sohne">Properties</Title>
      </TitleContainer>

      {isExpanded ? (
        <>
          <Container style={s('mt-3')}>
            <TopContainer>
              <Row style={s('mb-2')}>
                <Col span={24}>
                  <FieldLabel>Total Properties:</FieldLabel>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Value>{properties.length}</Value>
                </Col>
              </Row>
            </TopContainer>
            <ExpandedPropertyBox
              properties={properties}
              onClick={() => setIsExpanded(false)}
            />
          </Container>
        </>
      ) : (
        <Container style={s('mt-3')}>
          <TopContainer>
            <Row style={s('mb-2')}>
              <Col span={24}>
                <FieldLabel>Total Properties:</FieldLabel>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Value>{properties.length}</Value>
              </Col>
            </Row>
          </TopContainer>
          <ExpandButton onClick={() => setIsExpanded(true)}>
            <Row style={s('justify-center')}>
              <div>View Details</div>
              <ChevronDownIcon width={20} height={20} />
            </Row>
          </ExpandButton>
        </Container>
      )}
    </>
  )
}
