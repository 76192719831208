import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { Overview } from './Overview'
import { routes } from 'routes'

const reconciledPaymentsCenterRoutes = routes.admin.reconciledPaymentsCenter

function ReconciledPaymentsCenterRouter() {
  return (
    <Switch>
      <Route
        exact
        path={reconciledPaymentsCenterRoutes.overview}
        component={Overview}
      />
      <Redirect to={reconciledPaymentsCenterRoutes.overview} />
    </Switch>
  )
}

export { ReconciledPaymentsCenterRouter }
