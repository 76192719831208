import React, { useMemo } from 'react'

import { useLargeMediaQuery } from '@vega/styled'
import { List } from './List'
import { Table } from './Table'

const TransactionHistory = ({
  transactions = [],
  setDateRange,
  dateRange,
  loanId,
  loanNumber,
  ...otherProps
}) => {
  const isLargeOrLargerScreen = useLargeMediaQuery('larger')
  const TransactionHistory = useMemo(() => (isLargeOrLargerScreen ? Table : List), [
    isLargeOrLargerScreen,
  ])

  return (
    <TransactionHistory
      transactions={transactions}
      setDateRange={setDateRange}
      dateRange={dateRange}
      loanId={loanId}
      loanNumber={loanNumber}
      {...otherProps}
    />
  )
}

export { TransactionHistory }
