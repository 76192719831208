import { prop } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { mortgageAccountsAdapter } from './module'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

const selectMortgageAccounts = prop('mortgageAccounts')

export const selectIsFetchingMortgageAccount = createSelector(
  selectMortgageAccounts,
  prop('isFetchingMortgageAccounts')
)

export const selectTotal = createSelector(selectMortgageAccounts, prop('total'))

export const {
  selectIsPending: selectIsFetchMortgageAccountsPending,
  selectStatus: selectFetchMortgageAccountsStatus,
} = createAsyncThunkSelectors('loans/fetchLoans')

export const {
  selectIsPending: selectIsFetchMortgageAccountPending,
} = createAsyncThunkSelectors('mortgageAccounts/fetchLoan')

export const {
  selectIsPending: selectIsFetchMortgageAccountSecuritiesPending,
} = createAsyncThunkSelectors('mortgageAccounts/fetchLoanSecurities')

export const {
  selectIsPending: selectIsFetchCustomerMortgagesPending,
} = createAsyncThunkSelectors('mortgageAccounts/fetchCustomerMortgages')

export const {
  selectIsPending: selectIsAddingCustomerToMortgage,
  selectHasFulfilled: selectCustomerHasBeenAdded,
} = createAsyncThunkSelectors('mortgageAccounts/addCustomerToMortgage')

export const {
  selectIsPending: selectIsUpdatingMortgageAccount,
  selectHasFulfilled: selectIsMortgageAccountUpdated,
} = createAsyncThunkSelectors('mortgageAccounts/updateLoan')

export const selectLoanDetails = createSelector(
  selectMortgageAccounts,
  prop('loanDetails')
)

export const selectLoanSecurities = createSelector(
  selectMortgageAccounts,
  prop('securities')
)

export const {
  selectEntities: selectMortgageAccountEntities,
  selectAll: selectAllMortgageAccounts,
  selectById: selectMortgageAccountById,
} = mortgageAccountsAdapter.getSelectors(prop('mortgageAccounts'))
