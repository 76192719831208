import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Container as ContainerBase, Row, Col } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { DottedCircleLoader } from '@vega/components'
import { routes } from 'routes'
import { TEST_IDS } from '@neo/constants'

const Container = styled(ContainerBase)(
  s('flex flex-column border-grey-200 rounded-lg p-5 bg-white', {
    boxShadow:
      '0px 2px 4px -1px rgba(21, 61, 58, 0.06), 0px 4px 6px -1px rgba(21, 61, 58, 0.1)',
    height: 215,
  })
)

const CustomerAndStatusBox = styled.div(s('flex'))
const CustomerName = styled.div(s('font-semibold text-lrg text-grey-900'))
const AccountTypePill = styled.div(
  s('flex bg-secondary justify-center text-center mx-2 py-1 px-2 text-xs', {
    borderRadius: 32,
    maxHeight: 24,
  })
)
const ViewButton = styled.div(s('text-primary font-semibold text-sm mr-3'), {
  cursor: 'pointer',
})

const NumberOfEntitiesLabel = styled.div(s('mt-3 font-normal text-sm text-grey-600'))
const NumberOfEntities = styled.div(s('flex text-base font-semibold text-grey-800'))

function EntityBox({ isLoading, _id, name = '-', numberOfEntities = '-' }) {
  const history = useHistory()
  const { id: loanId } = useParams()

  const insertId = (url = '') => url.replace(':id', loanId).replace(':entityId', _id)

  if (isLoading) {
    return (
      <Container style={s('flex items-center justify-center h-10')}>
        <DottedCircleLoader size="lg" />
      </Container>
    )
  }

  const viewCustomerDetails = () => {
    history.push(insertId(routes.admin.loans.entityDetails.root), { entityId: _id })
  }

  return (
    <Container>
      <Row>
        <Col span={20}>
          <CustomerAndStatusBox>
            <CustomerName>{name}</CustomerName>
            <AccountTypePill>PRIMARY</AccountTypePill>
          </CustomerAndStatusBox>
        </Col>

        <Col span={4} style={s('flex justify-end')}>
          <ViewButton
            data-test-id={TEST_IDS.ENTITIY_DETAIL_VIEW_BTN}
            onClick={viewCustomerDetails}
          >
            View
          </ViewButton>
        </Col>

        <Col span={12}>
          <NumberOfEntitiesLabel># of Entities</NumberOfEntitiesLabel>
          <NumberOfEntities data-test-id={TEST_IDS.NUMBER_OF_ENTITIES}>
            {numberOfEntities}
          </NumberOfEntities>
        </Col>
      </Row>
    </Container>
  )
}

export default EntityBox
