/* eslint-disable react/jsx-key */
import React from 'react'
import { prop } from '@solta/ramda-extra'
import { styled, s } from '@vega/styled'

const Tr = styled.tr(
  s('flex bg-green-100'),
  {
    '&:last-child': { '& td': { borderBottom: 0 } },
  },
  prop('tableHeaderRowStyles')
)

const Th = styled.th(
  s('p-3 sticky z-1 pin-t-0'),
  s('tracking-wide text-sm font-normal'),
  prop('tableHeaderStyles')
)

const Head = styled.div({
  letterSpacing: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
})

function TableHeader({ headerGroups, tableHeaderRowStyles }) {
  return (
    <thead style={s('flex flex-column sticky z-1 pin-t-0')}>
      {headerGroups.map((headerGroup) => (
        <Tr
          tableHeaderRowStyles={tableHeaderRowStyles}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column) => (
            <Th
              tableHeaderStyles={prop('tableHeaderStyles', column)}
              {...column.getHeaderProps()}
            >
              <Head>
                {column.render('Header')}
                {column.canSort ? null : ''}
              </Head>
            </Th>
          ))}
        </Tr>
      ))}
    </thead>
  )
}

export { TableHeader }
