import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { paymentService } from 'apiService'
import { normalizeError } from '@vega/services'
import { thunkErrorProcessor } from '@vega/error-standardizer'
import { omit } from '@solta/ramda-extra'

export const fetchPayments = createAsyncThunk(
  'payments/fetchPayments',
  async ({ searchParams, pageIndex }, { rejectWithValue, signal }) => {
    try {
      const { searchTerm: q, filters = {}, sorting = {}, limit = 20 } =
        searchParams || {}
      return await paymentService.fetchPayments(
        {
          q,
          filters: omit(['date', 'overdue', 'method'], filters),
          start: limit * pageIndex,
          sorting,
          limit,
        },
        signal
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const processPayment = createAsyncThunk(
  'payments/processPayment',
  async (payment, { rejectWithValue }) => {
    try {
      return await paymentService.processRepayment(payment)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const settlePayment = createAsyncThunk(
  'payments/settlePayment',
  async (payload, { rejectWithValue }) => {
    try {
      return await paymentService.settlePayment(payload)
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const sendEmail = createAsyncThunk(
  'payments/sendEmail',
  async (paymentId, { rejectWithValue }) => {
    try {
      return await paymentService.sendEmail(paymentId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchPaymentsExport = createAsyncThunk(
  'payments/exportPayments',
  async ({ filters }, { rejectWithValue }) => {
    try {
      return await paymentService.fetchPaymentsExport({ filters })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const paymentsAdapter = createEntityAdapter({ entities: {}, ids: [] })

const initialState = paymentsAdapter.getInitialState()

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  extraReducers: {
    [fetchPayments.fulfilled]: (state, { payload }) => {
      const { items: payments, pagination } = payload

      paymentsAdapter.setAll(state, payments)
      state.total = pagination.total
    },
    [processPayment.fulfilled]: (state, { payload }) => {
      const { id, ...changes } = payload
      paymentsAdapter.updateOne(state, { id, changes })
    },
  },
})

const { reducer: paymentsReducer } = paymentsSlice

export { paymentsReducer }
