/* eslint-disable complexity */
import React from 'react'

import { styled, s } from '@vega/styled/v2'
import { Col as GridCol, Row as GridRow } from '@vega/styled'
import { ReactComponent as CheckIconBase } from '@vega/components/src/assets/images/check-circle.svg'

import { formatDateString } from 'utils/date'
import { formatCurrency } from 'utils/formatter'

import {
  PaymentTypeDisplayNameMapper,
  StatusDisplayNameMapper,
} from '../../displayNameMappers'

const Container = styled.div(
  s(
    'flex flex-column bg-grey-50 border-solid border-0 border-b-1 border-grey-200 py-4 px-5'
  ),
  { background: '#E9E9DB', borderLeft: '2px solid #00FFCD' }
)

const Row = styled.div(s('flex flex-row justify-between'))

const Col = styled.div(s('flex-column self-center'))

const DateText = styled.div(
  s('font-normal text-xs text-grey-700 mb-1', { lineHeight: 1.5 })
)

const TransactionType = styled.div(
  s('text-base font-medium text-grey-900 my-1', { lineHeight: 1.5 })
)

const Status = styled.div(
  s('text-sm font-semibold text-grey-700', {
    lineHeight: 1.5,
  })
)

const Amount = styled.div(s('text-lg font-semibold text-grey-700', { lineHeight: 1.5 }))

const Divider = styled.div(s('border-solid border-0 border-b-1 border-grey-100 my-3'))

const Label = styled.div(s('text-xs font-semibold text-grey-600', { lineHeight: 1.5 }))

const Text = styled.div(s('text-base font-bold text-grey-700', { lineHeight: 1.5 }))

const CheckIcon = styled(CheckIconBase)({
  path: { stroke: s('text-secondary-green').color },
  marginRight: 6,
})

const ExpandedListItem = ({ transaction, onClick }) => {
  const { recordedAt, paymentType, reference, amount, status } = transaction ?? {}

  return (
    <Container onClick={onClick}>
      <Row>
        <Col>
          <DateText>{formatDateString(recordedAt)}</DateText>

          <TransactionType>
            {PaymentTypeDisplayNameMapper[paymentType] || '-'}
          </TransactionType>

          <Status>
            {transaction?.status === 'paid' && <CheckIcon width={16} height={16} />}
            {StatusDisplayNameMapper[status] || '-'}
          </Status>
        </Col>

        <Col>
          <Amount>{formatCurrency(amount, 2)}</Amount>
        </Col>
      </Row>

      <Divider />

      <GridRow style={s('mb-3')}>
        <GridCol span={10}>
          <Label>Reference</Label>

          <Text>{reference ?? '-'}</Text>
        </GridCol>
      </GridRow>
    </Container>
  )
}

export { ExpandedListItem }
