import React from 'react'
import { styled, s } from '@vega/styled/v2'

const Container = styled.div()
const Label = styled.div(
  s('mb-2 text-base text-grey-600 font-normal', { lineHeight: 1.5 })
)
const Value = styled.div(s('text-xl text-grey-800 font-semibold', { lineHeight: 1.2 }))

const BreakdownItem = ({ label, value, ...props }) => (
  <Container {...props}>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </Container>
)

export { BreakdownItem }
