import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { styled, s } from '@vega/styled/v2'
import { useLargeMediaQuery } from '@vega/styled'

import AppSwitcher from 'features/common/AppSwitcher'
import ProductRouter from 'features/admin/product'
import CustomerRouter from 'features/admin/customer'
import { PaymentCenterRouter } from 'features/admin/paymentCenter'
import { ReconciledPaymentsCenterRouter } from 'features/admin/reconciledPaymentCenter'
import HomepageRouter from 'features/admin/homePage/homePageRouter'
import { LoansRouter } from 'features/admin/loans'
import NavigationDrawer from './AdminNavigationDrawer'
import { AccountSettings } from './accountSettings'
import { routes } from 'routes'

import { UnsupportedScreenPage } from '@vega/components'

const Root = styled.div(s('flex h-full bg-primary-background'), { overflow: 'hidden' })

const adminRoutes = routes.admin

const AdminRouter = () => {
  const isLargeScreen = useLargeMediaQuery('larger')

  if (!isLargeScreen)
    return (
      <Root>
        <UnsupportedScreenPage app="Neo Banking" />
      </Root>
    )

  return (
    <Root>
      <NavigationDrawer />
      <AppSwitcher />

      <Switch>
        <Route path={adminRoutes.homepage.root} component={HomepageRouter} />
        <Route path={adminRoutes.products.root} component={ProductRouter} />
        <Route path={adminRoutes.customers.root} component={CustomerRouter} />
        <Route path={adminRoutes.paymentCenter.root} component={PaymentCenterRouter} />
        <Route
          path={adminRoutes.reconciledPaymentsCenter.root}
          component={ReconciledPaymentsCenterRouter}
        />
        <Route path={adminRoutes.loans.root} component={LoansRouter} />
        {/* <Route path={adminRoutes.reports.root} component={ReportRouter} /> */}
        <Route exact path={adminRoutes.account.root} component={AccountSettings} />

        <Redirect to={adminRoutes.homepage.root} />
      </Switch>
    </Root>
  )
}

export default AdminRouter
