import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { profileService } from 'apiService'
import { normalizeError } from '@vega/services'

export const createInvitation = createAsyncThunk(
  'invitations/createInvitation',
  async ({ email, role }, { rejectWithValue }) => {
    try {
      return await profileService.createInvitation({ email, role })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const resendInvitation = createAsyncThunk(
  'invitations/',
  async (invitationCode, { rejectWithValue }) => {
    try {
      return await profileService.resendInvitation(invitationCode)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const fetchInvitation = createAsyncThunk(
  'invitations/fetchInvitation',
  async (email, { rejectWithValue }) => {
    try {
      return await profileService.fetchInvitations(email)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const invitationAdapter = createEntityAdapter({
  entities: [],
})

const initialState = invitationAdapter.getInitialState()

const invitationSlice = createSlice({
  name: 'invitations',
  initialState,
  extraReducers: {
    [fetchInvitation.fulfilled]: (state, action) => {
      invitationAdapter.setAll(state, action.payload.items)
    },
  },
})

const { reducer: invitationsReducer } = invitationSlice

export { invitationsReducer }
