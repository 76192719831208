import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Col, Row } from '@vega/styled'
import {
  ContentCard,
  DataDisplay as DataDisplayBase,
  Divider,
} from '@vega/components/src/v2'
import { Loading } from '@vega/components'
import formatters from 'utils/formatters'
import { formatCurrency } from 'utils/formatter'

const DataDisplay = styled(DataDisplayBase)(s('mb-2'))
const GuarantorNumber = styled.p(s('my-4 font-semibold text-xs text-primary'))
const Text = styled.p(s('my-0'))

export const GuarantorsOverview = ({ guarantors = [], isLoading }) => {
  if (isLoading)
    return (
      <ContentCard>
        <Loading />
      </ContentCard>
    )

  return (
    <ContentCard>
      {guarantors?.length === 0 ? (
        <Text>No Guarantors information to display</Text>
      ) : (
        <>
          <DataDisplay label="# of Guarantors" value={guarantors?.length} />

          {guarantors?.map((guarantor, index, { length }) => {
            const isLastGuarantor = index === length - 1

            const residentialAddress =
              guarantor?.addresses?.find(
                (address) => address?.type === 'residential'
              ) ?? guarantor?.addresses[0]

            return (
              <>
                <GuarantorNumber>GUARANTOR {index + 1}</GuarantorNumber>

                <Row>
                  <Col span={12}>
                    <DataDisplay label="Name" value={guarantor?.name} />
                  </Col>

                  <Col span={12}>
                    <DataDisplay label="Cust No." value={guarantor?.customerNumber} />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <DataDisplay label="Email" value={guarantor?.email} />
                  </Col>

                  <Col span={12}>
                    <DataDisplay
                      label="Phone Number"
                      value={formatters.formatPhoneNumber(guarantor?.phoneNumber)}
                    />
                  </Col>
                </Row>

                <DataDisplay
                  label="Address"
                  value={formatters.buildAddress(
                    {
                      streetName: residentialAddress?.streetName,
                      streetNumber: residentialAddress?.streetNumber,
                      streetType: residentialAddress?.streetType,
                    },
                    residentialAddress?.suburbName,
                    residentialAddress?.territorialAuthorityName,
                    residentialAddress?.countryISO,
                    residentialAddress?.streetUnitNumber,
                    residentialAddress?.postCode,
                    residentialAddress?.fullAddress
                  )}
                />
                <Row>
                  <Col span={12}>
                    <DataDisplay
                      label="Security Guarantee"
                      value={formatCurrency(guarantor?.guarantees?.securityGuarantee)}
                    />
                  </Col>
                  <Col span={12}>
                    <DataDisplay
                      label="Servicing Guarantee"
                      value={formatCurrency(guarantor?.guarantees?.servicingGuarantee)}
                    />
                  </Col>
                </Row>

                <Divider style={s(isLastGuarantor ? 'hidden' : 'my-5')} />
              </>
            )
          })}
        </>
      )}
    </ContentCard>
  )
}
