import React, { useState, useEffect } from 'react'
import { Formik as FormProvider, Form } from 'formik'
import * as Validator from 'yup'
import { s, styled } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import { ReactComponent as PencilIcon } from '@vega/components/src/assets/images/pencil.svg'
import { ReactComponent as CloseIcon } from '@vega/components/src/assets/images/x.svg'
import { Button, TextField } from '@vega/components'
import { isNotNilOrEmpty } from '@solta/ramda-extra'

const { object, string } = Validator

const TitleRow = styled(Row)(s('items-center'))
const Title = styled.h1(s('text-3xl text-primary font-semibold'), {
  whiteSpace: 'nowrap',
  letterSpacing: '-0.02em',
})
const EditButton = styled.div(s('text-sm font-semibold'), {
  color: '#490B2D',
  cursor: 'pointer',
})

const LoanNumber = styled.div(s('text-lg text-grey-900 font-normal'), {
  whiteSpace: 'nowrap',
  letterSpacing: '-0.01em',
})

const EditContainer = styled(Col)(s('bg-accent-pink py-4 px-6 rounded-lg'))
const TitleContainer = styled.div(s('flex justify-between items-center'), {
  cursor: 'pointer',
})
const EditTitle = styled.h2(s('text-primary text-base mb-1'))
const EditSubtitle = styled.h3(s('text-grey-700 text-xs font-normal mt-1'))
const ButtonGroup = styled.div(s('flex justify-end mt-12'))
const SaveButton = styled(Button)(s('w-6 ml-2 bg-primary text-white font-semibold'))
const CancelButton = styled(Button)(s('w-6 text-primary font-semibold bg-transparent'))
const CharCount = styled.p(
  s('text-grey-700 text-xs text-right ml-a'),
  ({ lengthExceeded }) => ({
    color: lengthExceeded ? s('text-accent-red').color : s('text-grey-700').color,
  })
)

export function AccountNameSection({
  loanNumber,
  isLoading,
  isUpdatingAccountName = false,
  customLoanName,
  updateLoan,
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [charCount, setCharCount] = useState(0)

  const initialFormValues = { loanName: customLoanName }
  const validationSchema = object({
    loanName: string().required().min(5).max(25).trim(),
  })

  const onClose = () => {
    setIsEditing(false)
    setCharCount(customLoanName.length)
  }

  useEffect(() => {
    if (isNotNilOrEmpty(customLoanName)) {
      setCharCount(customLoanName.length)
    }

    return () => setCharCount(customLoanName?.length)
  }, [customLoanName])

  if (isLoading) return null

  if (isEditing)
    return (
      <Row>
        <EditContainer span={24} lg={12}>
          <TitleContainer>
            <div>
              <EditTitle>Change Account Nickname</EditTitle>
              <EditSubtitle>Maximum 25 characters</EditSubtitle>
            </div>
            <CloseIcon onClick={onClose} />
          </TitleContainer>
          <FormProvider
            validationSchema={validationSchema}
            initialValues={initialFormValues}
            onSubmit={async (data, action) =>
              updateLoan(data, action, () => setIsEditing(false))
            }
          >
            <Form>
              <TextField
                name="loanName"
                label="Account Nickname"
                inputContainerStyle={s('bg-white')}
                onChange={(e) => setCharCount(e.target.value.length)}
              />
              <CharCount lengthExceeded={charCount > 25}>{charCount}/25</CharCount>
              <ButtonGroup>
                <CancelButton type="button" onClick={() => setIsEditing(false)}>
                  Cancel
                </CancelButton>

                <SaveButton type="submit" loading={isUpdatingAccountName}>
                  Save
                </SaveButton>
              </ButtonGroup>
            </Form>
          </FormProvider>
        </EditContainer>
      </Row>
    )

  return (
    <>
      <TitleRow gutter={[16, 16]}>
        <Col>
          <Title className="Sohne">{customLoanName}</Title>
        </Col>
        <Col>
          <EditButton onClick={() => setIsEditing(true)}>
            <PencilIcon />
            <span style={s('mx-1')}>Edit</span>
          </EditButton>
        </Col>
      </TitleRow>
      <LoanNumber>{loanNumber}</LoanNumber>
    </>
  )
}
