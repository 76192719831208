import React from 'react'
import { isNotNilOrEmpty, noop } from '@solta/ramda-extra'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { useField } from 'formik'
import MomentUtils from '@date-io/date-fns'
import { formatTime } from './timeFormatter'

import { styled, s } from '@vega/styled/v2'
import { TextField } from '@vega/components'
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

const Root = styled.div(s(''))
const Container = styled.div(s('flex'), ({ labelPosition }) =>
  labelPosition === 'inline' ? s('flex-row items-center') : s('flex-column')
)
const Label = styled.label(s('tracking-wide text-base font-normal text-grey-700'), {
  marginBottom: -3,
})

const timePickerTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      day: {
        '&:focus': s('bg-primary text-white'),
      },
      daySelected: {
        ...s('bg-primary text-white'),
        '&:hover': {
          backgroundColor: '#9E2AA4',
        },
      },
    },
  },
})

function TimeField({
  disabled,
  isReadOnly,
  name,
  id,
  label,
  disablePast,
  labelPosition,
  inputFormat = '00:00',
  containerProps,
  pickerProps,
  inputStyle,
  inputContainerStyle,
  placeholder,
  labelProps,
  textFieldProps,
  testId,
  ...props
}) {
  const [{ value }, , { setValue: setFieldValue }] = useField({
    name,
    id,
  })

  const handleChange = (newValue) => {
    setFieldValue(formatTime(newValue))
  }

  return (
    <Root {...containerProps}>
      <Container labelPosition={labelPosition}>
        <Label
          isVisible={isNotNilOrEmpty(label)}
          htmlFor={name || id}
          labelPosition={labelPosition}
          {...labelProps}
        >
          {label}
        </Label>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={timePickerTheme}>
            <TimePicker
              {...props}
              label
              readOnly={isReadOnly}
              value={value}
              format={inputFormat}
              disablePast={disablePast}
              onAccept={handleChange}
              onChange={noop}
              reduceAnimations
              TextFieldComponent={(inputProps) => {
                return (
                  <TextField
                    {...inputProps}
                    name={name}
                    placeholder={placeholder}
                    aria-label={label}
                    isReadOnly={isReadOnly}
                    disabled={disabled}
                    testId={testId}
                    {...textFieldProps}
                  />
                )
              }}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </Container>
    </Root>
  )
}

export default TimeField
