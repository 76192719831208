import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { useDispatch, useSelector } from 'react-redux'
import { downloadStatement, selectIsStatementPending } from 'modules/customer'

import { DottedCircleLoader, Button as ButtonBase } from '@vega/components'

import { ListItem } from './ListItem'
import DateRangePicker from 'features/common/OverlayDateRangePicker'

const Container = styled.div(
  s('w-full rounded-lg flex flex-column bg-white shadow-sm mb-9 ', {
    marginTop: '10px',
  })
)
const Title = styled.div(
  s('text-xl font-semibold text-primary mt-9', { lineHeight: 1.5 })
)
const Card = styled.div(s('bg-white rounded-lg shadow-md'))
const Button = styled(ButtonBase)(
  s('h-3 font-semibold text-sm text-grey-900 w-full', {
    marginTop: '10px',
  })
)
const ResetButton = styled(ButtonBase)(
  s(
    'bg-white border-solid border-1 border-grey-200 py-3 px-4 text-sm text-grey-600 rounded-lg',
    { width: 'calc(100vw - 48px - 205px)' }
  )
)

const ActionsContainer = styled.div(s('flex flex-wrap justify-between'), {
  gap: 10,
  marginTop: '10px',
})

const TransactionList = ({
  isLoading,
  transactions,
  setDateRange,
  dateRange,
  loanId,
  loanNumber,
}) => {
  const dispatch = useDispatch()
  const isStatementPending = useSelector(selectIsStatementPending)

  const onDatesChange = ([startDate, endDate]) => {
    if (startDate && endDate) {
      setDateRange({ startDate, endDate })
    }
  }
  const downloadPDFStatement = () => {
    dispatch(downloadStatement({ payload: dateRange, loanId, loanNumber }))
  }

  return (
    <>
      <Title>Transaction History</Title>
      <Button
        onClick={downloadPDFStatement}
        disabled={!dateRange.startDate}
        loading={isStatementPending}
      >
        Download Statement
      </Button>
      <ActionsContainer>
        <DateRangePicker
          value={[dateRange?.startDate || null, dateRange?.endDate || null]}
          onChange={onDatesChange}
        />
        <ResetButton
          disabled={!dateRange.startDate}
          onClick={() => setDateRange({ startDate: null, endDate: null })}
        >
          Reset Date
        </ResetButton>
      </ActionsContainer>

      <Container>
        <Card>
          {isLoading ? (
            <div style={s('py-4 flex justify-center items-center')}>
              <DottedCircleLoader />
            </div>
          ) : (
            transactions?.map((transaction) => (
              <ListItem key={transaction.date} transaction={transaction} />
            ))
          )}
        </Card>
      </Container>
    </>
  )
}

export { TransactionList as List }
