import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class ReconciledPaymentsService extends BaseService {
  fetchReconciledPayments({ q, start, limit, filters = {} }, signal) {
    const searchParams = QueryBuilder()
      .setQ(q)
      .setPaging({ start, limit })
      .setFilters(filters)
      .build()

    return this.client.get(`reconciled-payments?${searchParams}`, { signal }).json()
  }

  updateNote(id, note) {
    const config = {
      json: { note },
    }

    return this.client.put(`reconciled-payments/notes/${id}`, config).json()
  }
}
