import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { PieChart } from './PieChart'
import {
  isNilOrEmpty,
  mapObjIndexed,
  omit,
  pipe,
  sum,
  keys,
  values,
  pick,
} from '@solta/ramda-extra'

const Container = styled.div(
  s('flex justify-between items-center ', {
    width: 300,
    height: 292,
  })
)

// TODO: as there are a lot of loan purposes, need to confirm how to group them together
const loanPurposeTypeToUiDetails = {
  refinance: { label: 'Refinance', color: 'rgba(235,149,76,1)' },
  firstHome: { label: 'First home', color: 'rgba(1,20,76,1)' },
  others: { label: 'Others', color: 'rgba(150,100,131,1)' },
  commercialInvestment: {
    label: 'Nonresidential investment',
    color: 'rgba(135,207,145,1)',
  },
  residentialInvestment: {
    label: 'Residential investment',
    color: 'rgba(231,228,129,1)',
  },
  ownerOccupied: {
    label: 'Owner occupied',
    color: 'rgba(34,99,94,1)',
  },
}

/**
 * @param {object} breakdown an object containing the count of mortgages by loan purpose type
 * @returns {object[]} chartData: an array of objects with `label`, `frequency` (the original `count`) & `color` for the pie chart to render
 */
const mapToChartData = (breakdown) => {
  if (isNilOrEmpty(breakdown)) return []

  const loanPurposeTypesToShow = keys(loanPurposeTypeToUiDetails)
  const othersTotalCount = pipe(omit(loanPurposeTypesToShow), values, sum)(breakdown)

  return Object.values(
    mapObjIndexed(
      (count, type) => ({
        frequency: count,
        ...loanPurposeTypeToUiDetails[type],
      }),
      { ...pick(loanPurposeTypesToShow, breakdown), others: othersTotalCount }
    )
  )
}

const Breakdowns = ({ loanPurposeBreakdown }) => {
  return (
    <Container>
      <PieChart chartData={mapToChartData(loanPurposeBreakdown)} />
    </Container>
  )
}

export { Breakdowns }
