import React from 'react'
import { useSelector } from 'react-redux'
import {
  PersistentDrawer,
  TemporaryNavItem
} from 'features/common/NavigationDrawer'
import { ReactComponent as HomeIcon } from '@vega/components/src/assets/images/home.svg'
import { ReactComponent as MyAccountIcon } from '@vega/components/src/assets/images/clipboard.svg'
import { ReactComponent as CalendarIcon } from '@vega/components/src/assets/images/calendar.svg'
import { ReactComponent as AccountIcon } from '@vega/components/src/assets/images/user-circle.svg'
import { ReactComponent as ExitIcon } from '@vega/components/src/assets/images/exit.svg'
import { selectIsPersistentNavDrawerOpen } from 'modules/ui'
import { s } from '@vega/styled/v2'
import { handleSignOut } from '@vega/components'

import { routes } from 'routes'

const customerRoutes = routes.customer

export const PersistentNavigationDrawer = () => {
  const isNavDrawerOpen = useSelector(selectIsPersistentNavDrawerOpen)

  if (!isNavDrawerOpen) return null
  return (
    <PersistentDrawer homeRoute={routes.customer.root} style={s('relative z-2 px-2', { width: '14rem' })}>
      <TemporaryNavItem
        label="Home"
        route={customerRoutes.homepage}
        isExactRoute
        Icon={HomeIcon}
      />

      <TemporaryNavItem
        label="My Loans"
        route={customerRoutes.myLoans.root}
        Icon={MyAccountIcon}
      />

      <TemporaryNavItem
        label="Payment Centre"
        route={customerRoutes.paymentSchedule.root}
        Icon={CalendarIcon}
      />

      <TemporaryNavItem
        label="Account"
        route={customerRoutes.account.root}
        Icon={AccountIcon}
      />

      <TemporaryNavItem
        label="Log out"
        Icon={ExitIcon}
        onClick={handleSignOut}
      />
    </PersistentDrawer>
  )
}
