import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class ProductService extends BaseService {
  create({ name, displayName, code, notes, fields }) {
    const config = {
      json: { name, displayName, code, notes, fields },
    }

    return this.client.post(`products`, config).json()
  }

  fetchProducts({ q, filters, limit, start }, signal) {
    const searchParams = QueryBuilder()
      .setQ(q)
      .setFilters(filters)
      .setPaging({ start, limit })
      .build()

    return this.client.get(`products/?${searchParams}`, { signal }).json()
  }

  fetchProduct(id) {
    return this.client.get(`products/${id}`).json()
  }

  update({ id, ...updatingProps }) {
    const config = {
      json: updatingProps,
    }

    return this.client.patch(`products/${id}`, config).json()
  }
}
