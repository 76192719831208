import { isNil } from '@solta/ramda-extra'
import BaseService from '../baseService'

export class InvitationService extends BaseService {
  createInvitation({ invitedBy, firstName, email, role }) {
    const config = {
      json: { invitedBy, firstName, email, role },
    }

    return this.client.post(`invitations`, config).json()
  }

  getInvitation(code) {
    if (isNil(code)) throw new Error('Invalid Invitation Code')

    return this.publicClient.get(`invitations/${code}`).json()
  }

  acceptInvitation({ email, invitationCode, firstName, lastName, password }) {
    if (isNil(invitationCode)) throw new Error('Invalid Invitation Code')

    const config = { json: { email, invitationCode, firstName, lastName, password } }

    return this.publicClient.post(`invitations/${invitationCode}/accept`, config).json()
  }

  list({ query = '', limit = 10, start = 0, statuses = [] }) {
    const searchParams = new URLSearchParams({
      q: query,
      limit,
      start,
    }).toString()

    const statusesString = `&statuses=${statuses.join(',')}`

    return this.client.get(`invitations?${searchParams}${statusesString}`).json()
  }

  resend(code) {
    if (isNil(code)) throw new Error('Invalid Invitation Code')

    return this.client.put(`invitations/${code}`).json()
  }

  revoke(code) {
    if (isNil(code)) throw new Error('Invalid Invitation Code')

    return this.client.delete(`invitations/${code}`).json()
  }
}
