import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { isNilOrEmpty, defaultTo } from '@solta/ramda-extra'

import { mortgageAccountService } from 'apiService'
import { normalizeError } from '@vega/services'
import { thunkErrorProcessor, createCustomError } from '@vega/error-standardizer'

export const fetchLoans = createAsyncThunk(
  'loans/fetchLoans',
  async ({ searchParams, pageIndex }, { rejectWithValue, signal }) => {
    try {
      const { searchTerm: q, filters = {}, sorting = {}, limit = 20 } = searchParams
      let formattedFilters = filters
      if (filters.startDate && filters.endDate) {
        formattedFilters = {
          ...filters,
          startDate: filters.startDate.toISOString().slice(0, 10),
          endDate: filters.endDate.toISOString().slice(0, 10),
        }
      }
      return await mortgageAccountService.fetchLoans(
        {
          q,
          filters: formattedFilters,
          start: limit * pageIndex,
          sorting,
          limit,
        },
        signal
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchCustomerMortgages = createAsyncThunk(
  'mortgageAccounts/fetchCustomerMortgages',
  async (customerId, { rejectWithValue }) => {
    try {
      return await mortgageAccountService.fetchCustomerMortgages(customerId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchMortgageAccount = createAsyncThunk(
  'mortgageAccounts/fetchMortgageAccount',
  async (id, { rejectWithValue }) => {
    try {
      return await mortgageAccountService.fetchMortgageAccount(id)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const fetchLoan = createAsyncThunk(
  'mortgageAccounts/fetchLoan',
  async (id, { rejectWithValue }) => {
    try {
      return await mortgageAccountService.fetchLoan(id)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)
export const fetchLoanSecurities = createAsyncThunk(
  'mortgageAccounts/fetchLoanSecurities',
  async (id, { rejectWithValue }) => {
    try {
      return await mortgageAccountService.fetchLoanSecurities(id)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateLoan = createAsyncThunk(
  'mortgageAccounts/updateLoan',
  async ({ id, loan }, { rejectWithValue }) => {
    try {
      if (isNilOrEmpty(loan))
        throw createCustomError({
          type: 'loanUpdateFailed',
          description: 'Loan Update Failed',
        })

      const loanUpdatePayload = {
        productId: loan.product?.id,
        loanPurpose: loan.loanPurpose,
        loanLimit: loan.loanLimit,
        loanTermInMonths: loan.loanTermInMonths,
        repaymentDay: loan.repaymentDay,
        repaymentAmount: loan.repaymentAmount,
        repaymentRecurrence: loan.repaymentRecurrence,
        customLoanName: loan.customLoanName,
      }

      await mortgageAccountService.updateLoan(id, loanUpdatePayload)
      return loan
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const updateMortgageStatus = createAsyncThunk(
  'mortgageAccounts/updateMortgageStatus',
  async (id, { rejectWithValue }) => {
    try {
      return await mortgageAccountService.updateMortgageStatus(id)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const mortgageAccountsAdapter = createEntityAdapter()

const initialState = mortgageAccountsAdapter.getInitialState({
  entities: {},
  ids: [],
  loanDetails: {},
  total: undefined,
})

const mortgageAccountsSlice = createSlice({
  name: 'mortgageAccounts',
  initialState,
  extraReducers: {
    [fetchLoans.fulfilled]: (state, action) => {
      const { items: loans, pagination } = action.payload

      mortgageAccountsAdapter.setAll(state, loans)
      state.total = pagination.total
    },
    [fetchLoanSecurities.fulfilled]: (state, action) => {
      const securities = action.payload

      state.securities = securities
    },
    [fetchLoan.fulfilled]: (state, action) => {
      const loan = action.payload

      state.loanDetails = loan
    },
    [updateLoan.fulfilled]: (state, action) => {
      const updateLoanPayload = action.payload
      const { customLoanName } = updateLoanPayload || {}

      state.loanDetails = {
        ...state.loanDetails,
        customLoanName: defaultTo(state.loanDetails?.customLoanName, customLoanName),
      }
    },
    [updateMortgageStatus.fulfilled]: (state, { payload }) => {
      // Only updating status seeing as it's currently only field that's changed
      const { id, status } = payload
      mortgageAccountsAdapter.updateOne(state, { id, changes: { status } })
    },
    [fetchCustomerMortgages.fulfilled]: (state, action) => {
      mortgageAccountsAdapter.setAll(state, action.payload)
    },
  },
})

const { reducer: mortgageAccountsReducer } = mortgageAccountsSlice

export { mortgageAccountsReducer }
