import React from 'react'
import { PersistentNavigationDrawer } from './PersistentNavigationDrawer'
import { TemporaryNavigationDrawer } from './TemporaryNavigationDrawer'
import { useLargeMediaQuery } from '@vega/styled'

export const CustomerNavigationDrawer = () => {
  const isAboveLargeScreenSize = useLargeMediaQuery('larger')

  if (isAboveLargeScreenSize) return <PersistentNavigationDrawer />

  return <TemporaryNavigationDrawer />
}
