export const searchOptions = [
  {
    key: 'name',
    label: 'Entity Name',
    searchLabel: 'Search entity name',
  },
  {
    key: 'customerNumber',
    label: 'Customer No.',
    searchLabel: 'Search customer number',
  },
  {
    key: 'email',
    label: 'Email Address',
    searchLabel: 'Search email address',
  },
]

export const entityOptions = [
  {
    key: '',
    label: 'All',
  },
  {
    key: 'individual',
    label: 'Individual',
  },
  {
    key: 'trust',
    label: 'Trust',
  },
  {
    key: 'company',
    label: 'Business',
  },
]
