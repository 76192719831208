/* eslint-disable complexity */
import React, { useState } from 'react'
import { useComboBox } from '@react-aria/combobox'
import { Item } from '@react-stately/collections'
import { useComboBoxState } from '@react-stately/combobox'
import { ReactComponent as XCircleIcon } from '@vega/components/src/assets/images/x-circle.svg'
import { prop, isNotNilOrEmpty, noop, find, propEq } from '@solta/ramda-extra'
import PropTypes from 'prop-types'

import { useField } from 'formik'

import { useIllionAddressSearch } from './helpers/useIllionAddressSearch'
import { styled, s } from '@vega/styled'

import { ListBoxPopup } from './ListBoxPopup'
import { FormMessage } from '../../FormInput/FormMessage'

const Root = styled.div(
  s('inline-flex flex-column w-full h-full', { boxSizing: 'border-box' })
)
const Label = styled.label(s('text-grey-600 font-normal text-base mb-4'))
const InputContainer = styled.div(
  s(
    'relative flex items-center justify-center border-1 border-solid border-grey-200 bg-transparent'
  ),
  s('p-4 w-full h-full'),
  {
    boxSizing: 'border-box',
    borderRadius: 6,
    borderTopRightRadius: 0,
    ':focus-within': s('border-green-400', { boxShadow: '0 0 0 1px #58C6BD' }),
    ':hover': s('border-green-400'),
  },
  ({ isTouched }) => isTouched && s('border-green-400'),
  ({ hasError }) =>
    hasError &&
    s('border-error-400', {
      ':hover': s('border-error-400', { boxShadow: '0 0 0 1px #F53244' }),
    }),
  ({ hasError, isTouched }) =>
    hasError &&
    isTouched && {
      ':focus-within': s('border-green-400', { boxShadow: '0 0 0 1px #58C6BD' }),
    },
  ({ isFilled }) => isFilled && s('border-grey-300'),
  ({ hasError, isFilled }) =>
    hasError &&
    isFilled &&
    s('border-error-400', {
      ':focus-within': s('border-error-400', { boxShadow: '0 0 0 1px #F53244' }),
    }),

  ({ isReadOnly }) =>
    isReadOnly &&
    s('bg-grey-100 border-1 border-solid border-transparent', {
      pointerEvents: 'none',
    }),
  ({ isDisabled }) => isDisabled && s('bg-grey-200', { pointerEvents: 'none' })
)

const Input = styled.input(
  s('h-full w-full border-0 bg-transparent'),
  s('text-grey-800 text-base font-medium'),
  {
    outline: 'none',
    '&::placeholder': s('text-grey-500'),
    '#search-clear': s('text-red-200'),

    '&::-webkit-search-cancel-button': {
      '-webkit-appearance': 'none',
    },
  },
  ({ isReadOnly }) => isReadOnly && s('text-grey-800'),
  ({ isDisabled }) => isDisabled && s('text-grey-400')
)

const ClearInputBtn = styled(XCircleIcon)(
  s('absolute pin-tr-0 mr-4', {
    width: 20,
    height: 20,
    top: '50%',
    transform: 'translateY(-50%)',
  })
)

const { string, bool } = PropTypes
const ValocityAddressField = ({
  id,
  name,
  label,
  placeholder,
  disabled,
  readOnly,
  onSelectionChange = noop,
  menuStyles,
  testId,
  menuId,
  InputPrefixIcon,
  ...props
}) => {
  const [
    { onChange: onFieldChange, onBlur: onFieldBlur },
    { touched, error, value },
    { setTouched: setFieldTouched },
  ] = useField({
    name,
    id,
  })

  const [selectedResult, setSelectedResult] = useState(value)
  const [searchQuery, setSearchQuery] = useState('')
  const [isSearching, setIsSearching] = useState(false)

  const { results, searchAddress, resetResults } = useIllionAddressSearch()

  const clearInputValue = () => {
    setSearchQuery('')
    setSelectedResult('')
    onFieldChange({ target: { value: undefined, name } })
  }

  const fieldErrorMessage = error?.propertyAddress

  const handleInputChange = (value) => {
    setSelectedResult(undefined)

    if (isNotNilOrEmpty(value)) {
      setIsSearching(true)
      searchAddress(value)
      onFieldChange({ target: { value: undefined, name } })
      setIsSearching(false)
    }

    setSearchQuery(value)
  }

  const handleSelectionChange = (key) => {
    const selectedResult = find(propEq('propertyId', key))(results)

    setSelectedResult(selectedResult)
    setSearchQuery(selectedResult?.propertyAddress ?? '')
    onFieldChange({ target: { value: selectedResult, name } })
    onSelectionChange(selectedResult)
  }

  const handleBlur = (...args) => {
    setFieldTouched(true)
    resetResults()

    onFieldBlur(...args)
  }

  const noFilter = () => true
  const state = useComboBoxState({
    ...props,
    /* eslint-disable-next-line */
    children: (item) => <Item key={item.propertyId}>{item.propertyAddress}</Item>,

    id: id ?? name,

    isOpen: !selectedResult && (results.length > 0 || isSearching),

    items: results,

    inputValue: selectedResult ? selectedResult.propertyAddress : searchQuery,
    onInputChange: handleInputChange,

    selectedKey: prop('key')(selectedResult),
    onSelectionChange: handleSelectionChange,

    defaultFilter: noFilter,
    disallowEmptySelection: false,

    autoFocus: false,
  })

  const triggerRef = React.useRef()
  const inputRef = React.useRef()
  const listBoxRef = React.useRef()
  const popoverRef = React.useRef()

  const { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      ...props,
      placeholder,
      label,
      inputRef,
      // We must pass in a button ref, otherwise useComboBox() will fail.
      buttonRef: triggerRef,
      listBoxRef,
      popoverRef,
      menuTrigger: 'input',
      isDisabled: disabled,
      isReadOnly: readOnly,
    },
    state
  )

  return (
    <Root {...props}>
      {label && <Label {...labelProps}>{label}</Label>}
      <InputContainer
        isFilled={Boolean(value)}
        isDisabled={disabled}
        isReadOnly={readOnly}
        isTouched={touched}
        hasError={Boolean(error) && touched}
      >
        {InputPrefixIcon}
        <Input
          {...inputProps}
          type="search"
          ref={inputRef}
          isDisabled={disabled}
          isReadOnly={readOnly}
          onBlur={handleBlur}
          data-test-id={testId}
        />
        {isNotNilOrEmpty(inputProps?.value) && (
          <ClearInputBtn onClick={clearInputValue} />
        )}
        {state.isOpen && (
          <ListBoxPopup
            {...listBoxProps}
            // Use virtual focus to get aria-activedescendant tracking and
            // ensure focus doesn't leave the input field
            shouldUseVirtualFocus
            listBoxRef={listBoxRef}
            popoverRef={popoverRef}
            state={state}
            menuStyles={menuStyles}
            data-test-id={menuId}
          />
        )}
      </InputContainer>
      <FormMessage message={fieldErrorMessage} visible={Boolean(touched && error)} />
    </Root>
  )
}

ValocityAddressField.propTypes = {
  name: string.isRequired,
  label: string,
  placeholder: string,
  disabled: bool,
  readOnly: bool,
}

export { ValocityAddressField }
