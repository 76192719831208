/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { isNotNilOrEmpty, assoc, noop } from '@solta/ramda-extra'
import { Row, Col, useLargeMediaQuery, smaller } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { SORT_ORDER } from '@neo/constants'
import SideNav from './SideNav'
import { TransactionHistory } from './TransactionHistory'
import { PaymentCard } from './PaymentCard'
import { LoanCard } from './LoanCard'
import { PropertyCard } from './PropertyCard'
import { AccountNameSection } from './AccountNameSection'
import { Modal } from '@vega/components'
import { ReactComponent as X } from '@vega/components/src/assets/images/x.svg'
import { OutlinedBtn, Subtitle } from 'features/customer/accountSettings/common'

import {
  selectCustomerLoanGroups,
  selectIsFetchCustomerLoansPending,
  selectIsFetchCustomerPaymentsPending,
  selectCurrentUserCustomerId,
  selectCustomerTransactions,
  selectIsFetchCustomerLoanTransactionsPending,
  fetchCustomerLoanTransactions,
} from 'modules/customer'

import { fetchPayments, selectAllPayments } from 'modules/payment'

import {
  selectIsFetchMortgageAccountPending,
  selectIsUpdatingMortgageAccount,
  fetchLoan,
  selectLoanDetails,
  updateLoan,
} from 'modules/mortgageAccounts'
import { unwrapResult } from '@reduxjs/toolkit'

const Header = styled.div(s('flex flex-row mb-6'))

const CloseButton = styled.button(
  s('text-grey-700 border-transparent text-base pb-2 bg-transparent')
)

const Root = styled.div(
  s(
    'flex flex-column flex-1 self-center w-full h-full py-8',
    { overflowX: 'hidden' }
  ),
  smaller('md')(s('px-6'))
)

const Space = styled.div(s('bg-green-100 flex my-4 justify-between', { gap: 16 }))

const addLoanGroupStatusToEachLoan = (loans = [], loanGroupStatus) =>
  loans.map(assoc('loanGroupStatus', loanGroupStatus))

const LoanOverview = () => {
  const dispatch = useDispatch()
  const { id: loanId } = useParams()

  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null })

  const [loansModalOpen, setLoansModalOpen] = useState(false)

  const customerId = useSelector(selectCurrentUserCustomerId)
  // Nav bar data
  const loanGroups = useSelector(selectCustomerLoanGroups) || []
  const loans =
    loanGroups
      .map((loanGroup) =>
        addLoanGroupStatusToEachLoan(loanGroup?.loans, loanGroup?.status)
      )
      .flat() || []

  // Loan overview page data
  const loanDetails = useSelector(selectLoanDetails) || {}
  const loanNumber = loanDetails?.loanNumber
  const product = loanDetails?.product || {}
  const properties = loanDetails?.properties || []

  const payments = useSelector(selectAllPayments) || []
  const upcomingPayment = payments[0] || {}

  const transactions = useSelector(selectCustomerTransactions) || []

  const isFetchingLoan = useSelector(selectIsFetchMortgageAccountPending)
  const isUpdatingLoan = useSelector(selectIsUpdatingMortgageAccount)
  const isFetchingLoans = useSelector(selectIsFetchCustomerLoansPending)
  const isFetchingPayments = useSelector(selectIsFetchCustomerPaymentsPending)
  const isFetchingTransactions = useSelector(
    selectIsFetchCustomerLoanTransactionsPending
  )

  const customLoanName =
    loanDetails?.customLoanName || loanDetails?.product?.displayName

  const isLargeScreen = useLargeMediaQuery('larger')

  const onUpdateLoanName = async (data, action, closeEditCard = noop) => {
    try {
      const updateResult = dispatch(
        updateLoan({
          id: loanDetails.id,
          loan: { ...loanDetails, customLoanName: data?.loanName },
        })
      )
      unwrapResult(updateResult)

      closeEditCard()
    } catch (error) {
      action.setFieldError('loanName', 'Loan Update Failed')
    }
  }

  useEffect(() => {
    dispatch(fetchLoan(loanId))
  }, [dispatch, loanId])

  useEffect(() => {
    if (isNotNilOrEmpty(loanId)) {
      const paymentFilters = {
        status: ['unpaid', 'pending'],
        loanId,
      }
      const paymentSorting = {
        dueDate: SORT_ORDER.DESC,
      }

      dispatch(
        fetchPayments({
          searchParams: {
            filters: paymentFilters,
            sorting: paymentSorting,
          },
        })
      )
    }
  }, [customerId, dispatch, loanId])

  useEffect(() => {
    const transactionFilters = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    }
    const transactionSorting = {
      recordedAt: SORT_ORDER.DESC,
    }

    dispatch(
      fetchCustomerLoanTransactions({
        loanId,
        filters: transactionFilters,
        sorting: transactionSorting,
      })
    )
  }, [customerId, dispatch, loanId, dateRange])

  const modalStyle = isLargeScreen ?
    s('bg-white p-6 h-3/4 w-1/2') : s('bg-white p-5 w-full h-full m-4')

  return (
    <Root>
      <Row>
        <Col span={24} lg={24}>
          <Row justify="center">
            <Col span={24} lg={16}>
              <AccountNameSection
                loanNumber={loanNumber}
                isLoading={isFetchingLoan}
                isUpdatingAccountName={isUpdatingLoan}
                customLoanName={customLoanName}
                updateLoan={onUpdateLoanName}
              />

              <OutlinedBtn variant="contained" onClick={() => setLoansModalOpen(true)}>
                View other loans
              </OutlinedBtn>

              <Modal
                isOpen={loansModalOpen}
                onClose={() => setLoansModalOpen(false)}
                contentStyle={modalStyle}
              >
                <Header>
                  <CloseButton onClick={() => setLoansModalOpen(false)}>
                    <X style={s('text-primary')} />
                  </CloseButton>
                  <Subtitle>Choose a loan to view</Subtitle>
                </Header>

                <SideNav
                  loans={loans}
                  onItemClick={() => setLoansModalOpen(false)}
                  isLoading={isFetchingLoans}
                />
              </Modal>
            </Col>
            <Col span={0} lg={4} />
          </Row>

          <Row justify="center">
            <Col span={24} lg={10}>
              <LoanCard
                isLoading={isFetchingLoan}
                loan={loanDetails}
                product={product}
              />
            </Col>

            <Col span={24} lg={6}>
              <PaymentCard isLoading={isFetchingPayments} {...upcomingPayment} />
            </Col>
            <Col span={0} lg={4} />
          </Row>

          <Row justify="center">
            <Col span={24} lg={16}>
              <PropertyCard isLoading={isFetchingLoan} properties={properties} />
            </Col>
            <Col span={0} lg={4} />
          </Row>

          <Row justify="center">
            <Col span={24} lg={16}>
              <TransactionHistory
                isLoading={isFetchingTransactions}
                transactions={transactions}
                style={{ marginTop: 28 }}
                setDateRange={setDateRange}
                dateRange={dateRange}
                loanId={loanId}
                loanNumber={loanNumber}
              />

              <Space />
            </Col>
            <Col span={0} lg={4} />
          </Row>
        </Col>
      </Row>
    </Root>
  )
}

export { LoanOverview }
