export const pickColor = (colorStyleName) => {
  switch (colorStyleName) {
    case 'color2':
      return '#00FFCD'
    case 'color3':
      return '#F35A56'
    case 'color4':
      return '#FAF54F'
    case 'color5':
      return '#D2D2B0'
    default:
      return '#F9C2E8'
  }
}
