import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { loanGroupService } from 'apiService'
import { normalizeError } from '@vega/services'

export const approveLoanGroup = createAsyncThunk(
  'loanGroup/approveLoanGroup',
  async (id, { rejectWithValue }) => {
    try {
      return await loanGroupService.approveLoanGroup(id)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const loanGroupAdapter = createEntityAdapter()

const initialState = loanGroupAdapter.getInitialState({
  entities: {},
  ids: [],
})

const loanGroupSlice = createSlice({
  name: 'loanGroup',
  initialState,
  extraReducers: {
    [approveLoanGroup.fulfilled]: (state, { payload }) => {
      loanGroupAdapter.setAll(state, payload)
    },
  },
})

const { reducer: loanGroupReducer } = loanGroupSlice

export { loanGroupReducer }
