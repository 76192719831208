import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { styled, s } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import { ReactComponent as ChevronLeftBase } from '@vega/components/src/assets/images/chevron-left.svg'
import { SectionTitle as SectionTitleBase } from '@vega/components/src/v2'
import {
  fetchLoan,
  fetchLoanSecurities,
  selectIsFetchMortgageAccountSecuritiesPending,
  selectLoanDetails,
  selectLoanSecurities,
} from 'modules/mortgageAccounts'
import {
  fetchLegalEntitiesByLoanId,
  selectAllLegalEntities,
  selectIsFetchLegalEntitiesByLoanIdPending,
} from 'modules/legalEntity'
import { routes } from 'routes'
import { SecuritiesContainer } from './SecuritiesContainer'
import { GuarantorsOverview } from './GuarantorsOverview'
import { isGuarantorEntity } from '../entitiesDetails/common'

const Root = styled.div(s('flex flex-column flex-1 pb-5 px-8'), {
  msOverflowStyle: 'none',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})
const TitleContainer = styled.div(s('flex'))
const Title = styled.h3(s('p-0 m-0 font-semibold text-primary', { fontSize: 28 }))

const ChevronLeft = styled(ChevronLeftBase)(
  s('text-primary font-semibold', {
    marginRight: -5,
    height: 12,
    marginLeft: -10,
  })
)
const BackButton = styled.button(
  s(
    'bg-transparent border-transparent text-center text-primary font-semibold p-0 mt-6',
    { cursor: 'pointer' }
  )
)

const SectionTitle = styled(SectionTitleBase)(s('mb-3 mt-12'))

const SecuritiesDetails = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const history = useHistory()

  const handleOnClickBack = () => {
    history.push(`${routes.admin.loans.root}/${id}`)
  }

  const securities = useSelector(selectLoanSecurities)
  const legalEntities = useSelector(selectAllLegalEntities)
  const loanDetails = useSelector(selectLoanDetails)

  const guarantors = legalEntities?.filter((entity) =>
    isGuarantorEntity(loanDetails, entity.id)
  )

  const isFetchSecuritiesPending = useSelector(
    selectIsFetchMortgageAccountSecuritiesPending
  )
  const isFetchLegalEntitiesPending = useSelector(
    selectIsFetchLegalEntitiesByLoanIdPending
  )

  useEffect(() => {
    dispatch(fetchLoan(id))
    dispatch(fetchLegalEntitiesByLoanId(id))
    dispatch(fetchLoanSecurities(id))
  }, [dispatch, id])

  return (
    <Root>
      <Row>
        <Col span={24}>
          <BackButton onClick={handleOnClickBack}>
            <ChevronLeft /> <span style={s('mr-0 ')}>Back</span>
          </BackButton>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <TitleContainer>
            <Title>Securities Details</Title>
          </TitleContainer>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={12}>
          <SectionTitle title="Securities" />

          <SecuritiesContainer
            securities={securities}
            isLoading={isFetchSecuritiesPending}
          />
        </Col>
      </Row>

      <Row justify="center">
        <Col span={12}>
          <SectionTitle title="Guarantors" />

          <GuarantorsOverview
            guarantors={guarantors}
            isLoading={isFetchLegalEntitiesPending}
          />
        </Col>
      </Row>
    </Root>
  )
}

export default SecuritiesDetails
