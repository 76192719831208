import { parseJSON, format } from 'date-fns'

export const formatDateString = (dateString, targetFormat = 'dd/MM/yyyy') => {
  try {
    return format(parseJSON(dateString), targetFormat)
  } catch (error) {
    // don't return `invalid date` or similar as this is displayed in the UI, and we agree to show `-` in case of formatting error OR absence of value
    return '-'
  }
}
