/* eslint-disable complexity */
import React from 'react'
import { Col, Row } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'

const PaymentHeaderContainer = styled.div(
  s('bg-primary rounded-t-lg px-4 w-full ', {
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    height: 129,
  })
)

function PaymentHeader() {
  return (
    // Component title and value deleted.Component container kept for formatting
    <PaymentHeaderContainer>
      <Row style={s('p-4')} gutter={[0, 0]}>
        <Col>
          <></>
        </Col>
      </Row>
    </PaymentHeaderContainer>
  )
}

export default PaymentHeader
