import { isNil, isNilOrEmpty } from '@solta/ramda-extra'
import { QueryBuilder } from '../utils'
import BaseService from '../baseService'
import * as CONSTANTS from '@vega/constants'

const {
  LOAN_APPLICATION: {
    OUTCOMES: { APPROVED, REJECTED },
  },
} = CONSTANTS

export class VersionedLoanApplicationService extends BaseService {
  // TODO: is this actually used anywhere? (should it be LIXIs responsiblilty?)
  createApplication(createdBy) {
    const config = {
      json: { createdBy },
    }

    return this.client.post('versioned-loan-applications', config).json()
  }

  updateApplication(id, circumstanceChanges) {
    const config = {
      json: { circumstanceChanges },
    }

    return this.client.patch(`versioned-loan-applications/${id}`, config).json()
  }

  updateLoanApplication(id, payload) {
    const config = {
      json: payload,
    }

    return this.client.put(`versioned-loan-applications/${id}`, config).json()
  }

  getApplication(id) {
    if (isNil(id)) throw new Error('Invalid Loan Application Id')

    return this.client.get(`versioned-loan-applications/${id}`, { timeout: 40000}).json()
  }

  submitApplication(id) {
    if (isNil(id)) throw new Error('Invalid Loan Application Id')

    return this.client.post(`versioned-loan-applications/${id}/submit`).json()
  }

  getPropertyAddresses(query) {
    if (isNil(query)) throw new Error('Invalid Address Query')

    const encoded = encodeURIComponent(query)

    return this.client.get(`address?q=${encoded}`).json()
  }

  createApplicant(payload = {}) {
    const config = {
      json: payload,
    }

    return this.client.post('applicants', config).json()
  }

  addApplicants(id, applicantIds = []) {
    if (isNil(id)) throw new Error('Application Id should be provided')
    const config = {
      json: applicantIds,
    }

    return this.client.post(`versioned-loan-applications/${id}/applicants`, config).json()
  }

  updateIntent(
    id,
    {
      loanPurpose,
      propertyPurpose,
      estimatedPropertyValue,
      borrowingAmount,
      expectedRentAmount,
    } = {}
  ) {
    if (isNil(id)) throw new Error('Invalid Loan Application Id')

    const config = {
      json: {
        loanPurpose,
        propertyPurpose,
        estimatedPropertyValue,
        borrowingAmount,
        expectedRentAmount,
      },
    }

    return this.client.patch(`versioned-loan-applications/${id}/intent`, config).json()
  }

  updateStructure(
    id,
    {
      loanFlexibilityPreference,
      loanRateType,
      fixedLoanRateTerm,
      repaymentType,
      repaymentFrequency,
      loanTerm,
      offsetAccount,
    } = {}
  ) {
    if (isNil(id)) throw new Error('Invalid Loan Application Id')

    const config = {
      json: {
        loanFlexibilityPreference,
        loanRateType,
        fixedLoanRateTerm,
        repaymentType,
        repaymentFrequency,
        loanTerm,
        offsetAccount,
      },
    }

    return this.client.patch(`versioned-loan-applications/${id}/structure`, config).json()
  }

  createFinancials(
    loanApplicationId,
    { applicantIds, income, assets, expenses, liabilities }
  ) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNilOrEmpty(applicantIds)) throw new Error('Invalid Applicant Ids')

    const config = {
      json: {
        applicantIds,
        income,
        assets,
        expenses,
        liabilities,
      },
    }

    return this.client
      .post(`versioned-loan-applications/${loanApplicationId}/financials`, config)
      .json()
  }

  updateFinancials(
    loanApplicationId,
    { applicantIds, income, assets, expenses, liabilities }
  ) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNilOrEmpty(applicantIds)) throw new Error('Invalid Applicant Ids')

    const config = {
      json: {
        applicantIds,
        income,
        assets,
        expenses,
        liabilities,
      },
    }

    return this.client.put(`versioned-loan-applications/${loanApplicationId}/financials`, config)
  }

  updateEmployment(applicantId, { employment }) {
    if (isNil(applicantId)) throw new Error('Invalid applicant Id')

    const config = {
      json: employment,
    }

    return this.client.put(`applicants/${applicantId}/employment`, config).json()
  }

  addDocument({ loanApplicationId, docType } = {}) {
    // one of risk assessment, supporting, identification

    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNil(docType)) throw new Error('Invalid Document Type')

    const config = {
      json: { loanApplicationId, docType },
    }

    return this.client.post('application-documents', config).json()
  }

  downloadApplicantDocuments(applicantId) {
    if (isNil(applicantId)) throw new Error('Invalid Applicant Id')

    return this.client
      .get(`applicants/identity-documents?applicantId=${applicantId}`)
      .json()
  }

  updateStatus(loanApplicationId, status) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNil(status)) throw new Error('Invalid Update Status Model')

    const config = { json: { status } }

    return this.client
      .post(`versioned-loan-applications/${loanApplicationId}/update-status`, config)
      .json()
  }

  updateCreditAssessorCheck(loanApplicationId, check) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNil(check)) throw new Error('Invalid Update Check Model')

    const config = { json: check }

    return this.client
      .patch(`versioned-loan-applications/${loanApplicationId}/check`, config)
      .json()
  }

  getFinancialsRetrievalMetadata(loanApplicationId, applicantId) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNil(applicantId)) throw new Error('Invalid applicant Id')

    return this.client
      .get(
        `versioned-loan-applications/${loanApplicationId}/applicants/${applicantId}/financials-status`
      )
      .json()
  }

  sendRequestApplicantFinancialsEmail(loanApplicationId, applicantId) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNil(applicantId)) throw new Error('Invalid applicant Id')

    return this.client
      .post(
        `versioned-loan-applications/${loanApplicationId}/applicants/${applicantId}/send-financials-email`
      )
      .json()
  }

  updateFinancialsAccessMethod(loanApplicationId, applicantId, accessMethod) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNil(applicantId)) throw new Error('Invalid applicant Id')

    const config = {
      json: { loanApplicationId, applicantId, accessMethod },
    }

    return this.client.post(`financials-access-method`, config).json()
  }

  updateClientIdentification(id, documents) {
    if (isNil(id)) throw new Error('Invalid Applicant Id')

    const config = {
      json: documents,
    }

    return this.client.post(`applicants/${id}/documents`, config).json()
  }

  getLoanApplications({ q, filters, sorting, limit, start }, signal) {
    const searchParams = QueryBuilder()
      .setQ(q)
      .setFilters(filters)
      .setSorting(sorting)
      .setPaging({ start, limit })
      .build()

    return this.client
      .get(`versioned-loan-applications?${searchParams.replace('start', 'skip')}`, {
        signal,
      })
      .json()
  }

  searchApplicants(query) {
    return this.client.get(`applicants/search?q=${query}`).json()
  }

  getApplicant({ email }) {
    const searchParams = QueryBuilder()
      .setParam('email', encodeURIComponent(email))
      .build()

    return this.client.get(`applicants/?${searchParams}`).json()
  }

  updateApplicant(applicantModel, applicantId) {
    const config = {
      json: applicantModel,
    }
    return this.client.patch(`applicants/${applicantId}`, config).json()
  }

  createNote(applicationId, noteData) {
    if (isNil(applicationId)) throw new Error('Invalid Application Id')

    const config = {
      json: noteData,
    }

    return this.client.post(`versioned-loan-applications/${applicationId}/notes`, config).json()
  }

  approveApplicant(loanApplicationId, applicantId) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNil(applicantId)) throw new Error('Invalid Applicant Id')

    const config = {
      json: { decision: APPROVED },
    }

    return this.client
      .put(
        `versioned-loan-applications/${loanApplicationId}/applicants/${applicantId}/status`,
        config
      )
      .json()
  }

  rejectApplicant(
    loanApplicationId,
    applicantId,
    idVerified,
    creditCheckVerified,
    financialsVerified,
    rejectionReason
  ) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNil(applicantId)) throw new Error('Invalid Applicant Id')

    const config = {
      json: {
        decision: REJECTED,
        idVerified,
        creditCheckVerified,
        financialsVerified,
        rejectionReason,
      },
    }

    return this.client
      .put(
        `versioned-loan-applications/${loanApplicationId}/applicants/${applicantId}/status`,
        config
      )
      .json()
  }

  removeApplicant(loanApplicationId, applicantId) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNil(applicantId)) throw new Error('Invalid Applicant Id')

    const config = { json: { loanApplicationId, applicantId } }

    return this.client
      .put(
        `versioned-loan-applications/${loanApplicationId}/applicants/${applicantId}/remove`,
        config
      )
      .json()
  }
}
