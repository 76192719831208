import React from 'react'
import { styled, s } from '@vega/styled/v2'

const EmptyBar = styled.div(s('bg-grey-100 rounded-lg mt-3', { height: 8 }))
const FilledBar = styled.div(
  s('bg-secondary rounded-lg w-0 justify-center', {
    height: 8,
  }),
  ({ percentageFilled }) => percentageFilled && { width: `${percentageFilled}%` }
)

const getPercentageFilled = (valueMoved, fullValue) => {
  const negativeFullValue = -fullValue
  const negativePercentage = (valueMoved / negativeFullValue) * 100
  const percentage = 100 - negativePercentage
  if (percentage >= 100) {
    return 100
  }
  if (percentage <= 0) {
    return 0
  }
  return percentage
}
export function PaymentProgressBar({ loanBalance, loanAmount }) {
  return (
    <EmptyBar>
      <FilledBar percentageFilled={getPercentageFilled(loanBalance, loanAmount)} />
    </EmptyBar>
  )
}
