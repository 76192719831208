import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { routes } from 'routes'
import { Overview } from './Overview'
import { PropertyDetails } from './PropertyDetails'
import { AccountDetails } from './AccountDetails'
import { CustomerDetailsRouter } from './entitiesDetails/index'
import { NewLedgerEntry } from './NewLegderEntry'
import SecuritiesDetails from './SecuritiesDetails'

const loansRoutes = routes.admin.loans

export const LoansRouter = () => (
  <Switch>
    <Route path={loansRoutes.overview} component={Overview} />
    <Route path={loansRoutes.entityDetails.root} component={CustomerDetailsRouter} />
    <Route path={loansRoutes.propertyDetails} component={PropertyDetails} />
    <Route path={loansRoutes.newLedgerEntry} component={NewLedgerEntry} />
    <Route exact path={loansRoutes.accountDetails} component={AccountDetails} />
    <Route path={loansRoutes.securitiesDetails} component={SecuritiesDetails} />

    <Redirect to={loansRoutes.overview} />
  </Switch>
)
