import { keys, intersection, uniqWith } from '@solta/ramda-extra'

function ensureNoDuplicatedRoute(grantedRoutes = []) {
  return uniqWith((r1, r2) => r1.path === r2.path, grantedRoutes)
}

export const generateRoutesForAuthenticatedUser = (
  userRoles = [],
  roleToRoutesControl = {}
) => {
  const permittedRoles = keys(roleToRoutesControl)
  const relevantUserRoles = intersection(userRoles, permittedRoles)

  let grantedRoutes = []
  relevantUserRoles.forEach((role) => {
    const routesControl = roleToRoutesControl[role]
    grantedRoutes = grantedRoutes.concat(routesControl.routes)
  })

  return ensureNoDuplicatedRoute(grantedRoutes)
}
