import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { preferenceService } from 'apiService'
import { normalizeError } from '@vega/services'

export const fetchPreference = createAsyncThunk(
  'preferences/fetchPreferences',
  async (profileId, { rejectWithValue }) => {
    try {
      const res = await preferenceService.fetchPreference(profileId)
      return res
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updatePreference = createAsyncThunk(
  'preferences/updatePreference',
  async (payload, { rejectWithValue }) => {
    try {
      const updatingProps = payload
      return await preferenceService.updatePreference(updatingProps)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const preferenceAdapter = createEntityAdapter({
  selectId: (preference) => preference.profileId,
})

const initialState = preferenceAdapter.getInitialState({
  entities: {},
  ids: [],
  total: undefined,
})

const preferenceSlice = createSlice({
  name: 'preferences',
  initialState,
  extraReducers: {
    [fetchPreference.fulfilled]: preferenceAdapter.upsertOne,
  },
})

const { reducer: preferenceReducer } = preferenceSlice
export { preferenceReducer }
