import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TemporaryDrawer,
  TemporaryNavItem,
} from 'features/common/NavigationDrawer'
import { ReactComponent as HomeIcon } from '@vega/components/src/assets/images/home.svg'
import { ReactComponent as MyAccountIcon } from '@vega/components/src/assets/images/clipboard.svg'
import { ReactComponent as CalendarIcon } from '@vega/components/src/assets/images/calendar.svg'
import { ReactComponent as AccountIcon } from '@vega/components/src/assets/images/user-circle.svg'
import { ReactComponent as ExitIcon } from '@vega/components/src/assets/images/exit.svg'
import { styled, s } from '@vega/styled/v2'
import { selectTemporaryNavDrawer, setTemporaryNavDrawerState } from 'modules/ui'

import { routes } from 'routes'
import { componentDomIds } from 'constants/componentDomIds'
import { handleSignOut } from '@vega/components'

const customerRoutes = routes.customer
const {
  customer: {
    temporaryNavigationDrawer: { RIGHT_SIDE_BAR },
  },
} = componentDomIds

const SideBarMountingPoint = styled.div(
  s('absolute pin-t-0 h-full', {
    left: '100%',
  })
)

export const TemporaryNavigationDrawer = () => {
  const dispatch = useDispatch()
  const { leanMode } = useSelector(selectTemporaryNavDrawer)

  // Update it's own mounting state to redux ui module, allow interested components be able to know.
  // For example the components that need to render component in SideBarMountingPoint.
  React.useEffect(() => {
    dispatch(setTemporaryNavDrawerState({ isMounted: true }))

    return () => dispatch(setTemporaryNavDrawerState({ isMounted: false }))
  }, [dispatch])

  return (
    <TemporaryDrawer homeRoute={routes.customer.root}>
      <TemporaryNavItem
        label="Home"
        route={customerRoutes.homepage}
        isExactRoute
        Icon={HomeIcon}
        leanMode={leanMode}
      />

      <TemporaryNavItem
        label="My Loans"
        route={customerRoutes.myLoans.root}
        Icon={MyAccountIcon}
        leanMode={leanMode}
      />

      <TemporaryNavItem
        label="Payment schedule"
        route={customerRoutes.paymentSchedule.root}
        Icon={CalendarIcon}
        leanMode={leanMode}
      />

      <TemporaryNavItem
        label="Account"
        route={customerRoutes.account.root}
        Icon={AccountIcon}
        leanMode={leanMode}
      />

      <TemporaryNavItem
        label="Log out"
        Icon={ExitIcon}
        leanMode={leanMode}
        onClick={handleSignOut}
      />

      <SideBarMountingPoint id={RIGHT_SIDE_BAR} />
    </TemporaryDrawer>
  )
}
