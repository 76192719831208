/* eslint-disable camelcase */
import { Switch, Route } from 'react-router-dom'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { USER_ROLES } from '@vega/constants'

import { selectUserCurrentRole, selectProfileRoles } from '@vega/redux.profile'

import AdminRouter from 'features/admin'
import CustomerRouter from 'features/customer'
import InactivityModal from '@vega/components/src/InactivityModal/InactivityModal'

import { ErrorPage, Toaster, useActivityTracker } from '@vega/components'
import { generateRoutesForAuthenticatedUser } from '@neo/app-access-controller'
import { routes } from 'routes'
import { styled, s } from '@vega/styled'

const { SUPER_ADMIN, BANKING_ADMIN, BANKING_CLIENT } = USER_ROLES

const roleToRoutesControl = {
  [SUPER_ADMIN]: {
    routes: [
      { path: routes.admin.root, Component: AdminRouter },
      { path: routes.customer.root, Component: CustomerRouter },
    ],
    rootRoute: { path: routes.admin.root, Component: AdminRouter },
  },
  [BANKING_ADMIN]: {
    routes: [{ path: routes.admin.root, Component: AdminRouter }],
    rootRoute: { path: routes.admin.root, Component: AdminRouter },
  },
  [BANKING_CLIENT]: {
    routes: [{ path: routes.customer.root, Component: CustomerRouter }],
    rootRoute: { path: routes.customer.root, Component: CustomerRouter },
  },
}

const Root = styled.div(s('flex flex-column h-full bg-green-50'))

const App = () => {
  const [userIsInactive, setUserIsInactive] = useState(false)

  useActivityTracker(() => {
    if (!userIsInactive) setUserIsInactive(true)
  })

  const userRoles = useSelector(selectProfileRoles) ?? []
  const userCurrentRole = useSelector(selectUserCurrentRole)

  const permittedRoutes = generateRoutesForAuthenticatedUser(
    userRoles,
    roleToRoutesControl
  )

  const RootUrlComponent =
    roleToRoutesControl[userCurrentRole]?.rootRoute?.Component || ErrorPage

  return (
    <Root>
      <Switch>
        {permittedRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} component={Component} />
        ))}

        <Route exact path="/" component={RootUrlComponent} />
        <Route path="/" component={ErrorPage} />
      </Switch>

      <Toaster />

      <InactivityModal isOpen={userIsInactive} setIsOpen={setUserIsInactive} />
    </Root>
  )
}

export default App
