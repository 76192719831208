import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { larger } from '@vega/styled'
import { Button as ButtonBase, TextField as TextFieldBase } from '@vega/components'

export const Header = styled.div(
  s('flex flex-column py-8 pt-4 w-full mx-a'),
  larger('lg')(s('px-8')),
  ({ isLargeScreen }) => isLargeScreen && s('mb-10')
)

export const Title = styled.h2(
  s('m-0 font-bold text-primary text-3xl', {
    fontFamily: 'Sohne',
    whiteSpace: 'nowrap',
    letterSpacing: '-0.01em',
  }),
  larger('lg')(s('py-6', { fontSize: 28 }))
)

export const Subtitle = styled.span(
  s('text-primary text-xl font-extrabold mb-4 font-semibold inline-block mb-2')
)

export const CardContainer = styled.div(
  s('bg-white rounded-lg p-6', {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
  })
)

export const Row = styled.div(s('flex justify-between'))
export const Column = styled.div(s('flex flex-column'))

export const TextField = styled((props) => (
  <TextFieldBase containerStyle={s('flex-1')} {...props} />
))()

export const OutlinedBtn = styled(({ style, ...otherProps }) => (
  <ButtonBase
    type="submit"
    variant="outlined"
    style={s('border-grey-300 py-3', style)}
    {...otherProps}
  />
))()
