import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Row } from '@vega/styled'

const Root = styled.div(s('flex flex-column flex-1 mx-8'))
const Title = styled.h3(s('p-0 m-0 font-semibold text-primary text-xl'))
const SubTitle = styled.h3(s('p-0 m-0 font-semibold text-grey-800 text-sm'))
const Space = styled.div(s('bg-green-100 flex my-4 justify-between text-base'))
const LittleSpace = styled.div(s('bg-green-100 flex my-2 justify-between text-base'))
const Line = styled.div(s('bg-grey-200', { height: 2 }))
const TableContainer = styled.div(s('h-full mt-4', { overflow: 'hidden' }))
const OverviewContainer = styled.div(
  s('bg-white rounded-lg p-5', {
    width: '100%',
    height: 429,
    boxShadow:
      '0px 2px 4px -1px rgba(21, 61, 58, 0.06), 0px 4px 6px -1px rgba(21, 61, 58, 0.1)',
  })
)

function UpcomingSettlements() {
  return (
    <Root>
      <OverviewContainer>
        <Title className="sohne">Upcoming Settlements</Title>
        <Space />
        <Row style={s('items-center justify-between ml-1 mr-1')}>
          <SubTitle>Address</SubTitle>
          <SubTitle>Values</SubTitle>
        </Row>
        <LittleSpace />
        <Line />
        <LittleSpace />
        <TableContainer>
          {/* <Table
            columns={columns}
            data={data}
            tBodyStyle={s('bg-white')}
            containerStyle={s('bg-white rounded-lg shadow-md flex-1')}
            paginationProps={paginationProps}
            style={s('pb-0 m-0')}
            tableHeaderRowStyles={s(
              'bg-white border-solid border-0 border-b-1 border-grey-200'
            )}
            loaderLabel="Fetching customers"
            loading={isFetchingCustomers}
            onClick={handleRowClick}
          /> */}
        </TableContainer>
      </OverviewContainer>
    </Root>
  )
}

export default UpcomingSettlements
