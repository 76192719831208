import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { useLargeMediaQuery } from '@vega/styled'
import { Link as LinkBase } from 'react-router-dom'
import { useLocation } from 'react-router'
import { routes } from 'routes'

const paymentScheduleRoutes = routes.customer.paymentSchedule

const NavItem = styled.h2(
  s('text-2xl text-grey-600 m-0 p-0 mb-3 mr-3'),
  ({ isActive }) => isActive && s('text-primary'),
  ({ isLarge }) => !isLarge && s('text-center text-xl'),
  ({ isLarge }) => isLarge && s('mr-8')
)
const Link = styled(LinkBase)(s('no-decoration'))

function PaymentCenterNavigation() {
  const isLargerThanLargeScreen = useLargeMediaQuery('larger')

  const { pathname } = useLocation()

  const checkIsActive = (route) => pathname === route

  return (
    <div style={s('flex')}>
      <Link to={paymentScheduleRoutes.allLoans}>
        <NavItem
          isLarge={isLargerThanLargeScreen}
          isActive={checkIsActive(paymentScheduleRoutes.allLoans)}
        >
          All Loans
        </NavItem>
      </Link>

      <Link to={paymentScheduleRoutes.upcomingPayments}>
        <NavItem
          isLarge={isLargerThanLargeScreen}
          isActive={checkIsActive(paymentScheduleRoutes.upcomingPayments)}
        >
          Upcoming
        </NavItem>
      </Link>

      <Link to={paymentScheduleRoutes.paidPayments}>
        <NavItem
          isLarge={isLargerThanLargeScreen}
          isActive={checkIsActive(paymentScheduleRoutes.paidPayments)}
        >
          Paid
        </NavItem>
      </Link>
    </div>
  )
}

export default PaymentCenterNavigation
