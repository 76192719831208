/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useMemo } from 'react'
import { styled, s } from '@vega/styled/v2'
import { Table } from '@vega/components/src/v2'
import { useDispatch, useSelector } from 'react-redux'

import { formatDateString } from 'utils/date'
import {
  PaymentTypeDisplayNameMapper,
  StatusDisplayNameMapper,
} from '../../displayNameMappers'
import { formatCurrency } from 'utils/formatter'
import DateRangePicker from 'features/common/OverlayDateRangePicker'
import { Button as ButtonBase } from '@vega/components'
import { downloadStatement, selectIsStatementPending } from 'modules/customer'

const textEllipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const headerStyleBase = (customStyles) =>
  s('px-4 inline-block text-left text-sm font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s('px-4 inline-block text-sm text-left flex items-center font-semibold border-b-0', {
    lineHeight: '150%',
    ...textEllipsis,
    ...customStyles,
  })

const Button = styled(ButtonBase)(s('h-3 font-semibold text-sm text-grey-900'))
const ResetButton = styled(ButtonBase)(
  s(
    'bg-white border-solid border-1 border-grey-200 py-3 px-4 text-sm text-grey-600 rounded-lg'
  )
)

const FilterContainer = styled.div(s('flex justify-between'), { gap: 10 })
const TextOverflowEllipsisBox = ({ children }) => {
  return (
    <div
      style={s('flex items-center', {
        overflow: 'hidden',
      })}
    >
      <span className="textEllipsis">{children}</span>
    </div>
  )
}
const Root = styled.div(s(' w-full rounded-lg flex flex-column bg-white shadow-sm '))
const Title = styled.div(s('text-xl font-semibold text-primary', { lineHeight: 1.5 }))
const TitleContainer = styled.div(s('flex justify-between mt-9'), {
  verticalAlign: 'top',
})

function TransactionHistoryTable({
  isLoading,
  transactions = [],
  setDateRange,
  dateRange,
  loanId,
  loanNumber,
  ...props
}) {
  const dispatch = useDispatch()
  const isStatementPending = useSelector(selectIsStatementPending)
  const onDatesChange = ([startDate, endDate]) => {
    if (startDate && endDate) {
      setDateRange({ startDate, endDate })
    }
  }

  const downloadPDFStatement = () => {
    dispatch(downloadStatement({ payload: dateRange, loanId, loanNumber }))
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (row) => formatDateString(row.recordedAt),
        tableHeaderStyles: headerStyleBase({
          width: '20%',
        }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Transaction type',
        accessor: (row) => PaymentTypeDisplayNameMapper[row.paymentType] || '-',
        tableHeaderStyles: headerStyleBase({
          width: '25%',
        }),
        tableCellStyles: cellStyleBase({ width: '25%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Reference',
        accessor: (row) => row.reference || '-',
        tableHeaderStyles: headerStyleBase(
          s('text-right', {
            width: '20%',
          })
        ),
        tableCellStyles: cellStyleBase(s('justify-end', { width: '20%' })),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Amount',
        accessor: (row) => formatCurrency(row.amount, 2),
        tableHeaderStyles: headerStyleBase(
          s('text-right', {
            width: '20%',
          })
        ),
        tableCellStyles: cellStyleBase(s('justify-end', { width: '20%' })),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Status',
        accessor: (row) => StatusDisplayNameMapper[row.status] || '-',
        tableHeaderStyles: headerStyleBase(
          s('text-center', {
            width: '15%',
          })
        ),
        tableCellStyles: cellStyleBase(s('justify-center', { width: '15%' })),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
    ],
    []
  )

  const rows = useMemo(
    () =>
      transactions.map((customer) => {
        const customRowStyle = s('border-solid border-0 border-b-1 border-grey-200', {
          height: 50,
        })
        return { ...customer, customRowStyle }
      }),
    [transactions]
  )

  return (
    <>
      <TitleContainer style={{ marginBottom: -16 }}>
        <div>
          <Title className="sohne">Transaction History</Title>
        </div>
        <FilterContainer>
          <DateRangePicker
            value={[dateRange?.startDate || null, dateRange?.endDate || null]}
            onChange={onDatesChange}
          />
          <ResetButton
            disabled={!dateRange.startDate}
            onClick={() => setDateRange({ startDate: null, endDate: null })}
          >
            Reset Date
          </ResetButton>
          <Button
            onClick={downloadPDFStatement}
            disabled={!dateRange.startDate}
            loading={isStatementPending}
          >
            Download Statement
          </Button>
        </FilterContainer>
      </TitleContainer>

      <Root {...props}>
        <Table
          loading={isLoading}
          loaderLabel="Fetching Transactions"
          data={rows}
          columns={columns}
          tBodyStyle={s('bg-white', { height: 450 })}
          containerStyle={s('bg-white h-a shadow-md')}
          tableHeaderRowStyles={s(
            'bg-white border-solid border-0 border-b-1 border-grey-200'
          )}
          placeholderText={'No Transactions Yet'}
        />
      </Root>
    </>
  )
}

export { TransactionHistoryTable as Table }
