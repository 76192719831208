/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState } from 'react'
import { s, styled } from '@vega/styled/v2'
import { formatDateString } from 'utils/date'
import { PAYMENT_CONSTANTS } from '@neo/constants'
import { formatCurrency } from 'utils/formatter'
import { Tooltip } from '@mui/material'
import { differenceInDays } from 'date-fns'
import { ReactComponent as XIconBase } from '@vega/components/src/assets/images/x.svg'
import { useDispatch } from 'react-redux'
import { reconciledPaymentsUpdateNote } from 'modules/reconciledPayments'

const {
  RECONCILED_PAYMENT_STATUS_CONSTANTS: {
    PAID,
    DUPLICATED_PAYMENT,
    UNMATCHED_REFERENCE,
    UNMATCHED_AMOUNT,
    PAYMENT_ERROR,
  },
} = PAYMENT_CONSTANTS

const PAYMENT_STATUS = {
  [PAID]: 'Paid',
  [DUPLICATED_PAYMENT]: 'Duplicated Pmt',
  [UNMATCHED_REFERENCE]: 'Unmatched Ref',
  [UNMATCHED_AMOUNT]: 'Unmatched Amt',
  [PAYMENT_ERROR]: 'Payment Err',
}

const StatusPill = styled.div(
  s('rounded-2xl px-3 py-1 h-2 flex items-center justify-center font-normal'),
  ({ status }) =>
    status === PAID
      ? s('bg-green-100')
      : s(
          'rounded-2xl px-3 py-1 h-2 flex items-center justify-center text-white bg-accent-red'
        )
)

const XIcon = styled(XIconBase)(s('mr-2'), { '&:hover': { cursor: 'pointer' } })

const RecentlyAddedPill = styled.div(
  s(
    'bg-secondary-yellow rounded-2xl px-3 py-1 h-2 flex items-center justify-center font-semibold'
  ),
  { whiteSpace: 'nowrap' }
)

const headerStyleBase = (customStyles) =>
  s(' inline-block text-grey-600 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s(' inline-block text-grey-800 text-sm text-left flex items-center', {
    ...customStyles,
  })

const TextOverflowEllipsis = styled.span({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const Input = styled.textarea(
  s('h-full w-full border-0 bg-transparent pb-3 pl-3 text-sm ', {
    resize: 'none',
    paddingTop: '18px',
  }),
  s('text-grey-800  font-medium'),
  {
    outline: 'none',
    '&::placeholder': s('text-grey-500'),
    '#search-clear': s('text-red-200'),

    '&::-webkit-search-cancel-button': {
      '-webkit-appearance': 'none',
    },
  },
  ({ isReadOnly }) => isReadOnly && s('text-grey-800'),
  ({ isDisabled }) => isDisabled && s('text-grey-400')
)

const InputContainer = styled.div(
  s('rounded-lg bg-grey-100 border-1 border-grey-200 flex', {
    height: '90%',
    width: '98%',
  })
)

const AddButton = styled.button(
  s(
    'text-primary bg-secondary text-primary px-5 py-2 font-semibold border-transparent rounded-md cursor-pointer',
    {
      cursor: 'pointer',
    }
  )
)

const EnterNotes = ({ value, searchParams }) => {
  const { note, id } = value
  const dispatch = useDispatch()
  const [isInputChanged, setIsInputChanged] = useState(false)
  const [noteInput, setNoteInput] = useState(note || '')

  const postNote = () => {
    dispatch(
      reconciledPaymentsUpdateNote({
        id,
        note: noteInput,
        searchParams,
      })
    )
  }

  const resetInput = () => {
    setNoteInput(note || '')
    setIsInputChanged(false)
  }

  return (
    <InputContainer>
      <Input
        id="note-input"
        placeholder="Leave a note..."
        onChange={(e) => {
          setIsInputChanged(true)
          setNoteInput(e.target.value)
        }}
        value={noteInput}
      />

      {isInputChanged && (
        <div style={s('flex items-center flex-row justify-end mr-3 ')}>
          <XIcon onClick={resetInput} />

          <AddButton onClick={postNote}>Add</AddButton>
        </div>
      )}
    </InputContainer>
  )
}

export const Columns = (searchParams) => {
  return React.useMemo(
    () => [
      {
        Header: 'Transaction Date',
        accessor: (row) => formatDateString(row.transactionDate),
        tableHeaderStyles: headerStyleBase({ width: '8%' }),
        tableCellStyles: cellStyleBase({ width: '8%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Amount',
        accessor: (row) => formatCurrency(row?.amount, 2),
        tableHeaderStyles: headerStyleBase({ width: '8%' }),
        tableCellStyles: cellStyleBase(s('font-semibold', { width: '8%' })),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Reference',
        accessor: 'reference',
        tableHeaderStyles: headerStyleBase({ width: '8%' }),
        tableCellStyles: cellStyleBase({ width: '8%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Payment Id',
        accessor: 'paymentId',
        tableHeaderStyles: headerStyleBase({ width: '15%' }),
        tableCellStyles: cellStyleBase({ width: '15%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Transaction Type',
        accessor: 'transactionType',
        tableHeaderStyles: headerStyleBase({ width: '12%' }),
        tableCellStyles: cellStyleBase({ width: '12%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        tableHeaderStyles: headerStyleBase(s('text-center', { width: '8%' })),
        tableCellStyles: cellStyleBase(
          s('justify-center font-semibold', { width: '8%' })
        ),
        Cell: ({ value: status }) => {
          return (
            <StatusPill status={status}>
              <TextOverflowEllipsis>{PAYMENT_STATUS[status]}</TextOverflowEllipsis>
            </StatusPill>
          )
        },
      },
      {
        Header: '',
        accessor: 'isRecentlyAdded',
        tableHeaderStyles: headerStyleBase({ width: '11%' }),
        tableCellStyles: cellStyleBase({ width: '11%' }),
        Cell: ({ value: isRecentlyAdded }) => {
          if (!isRecentlyAdded) return null
          return (
            <Tooltip title="Recently Added">
              <div style={s('flex items-center justify-center w-full')}>
                <TextOverflowEllipsis>
                  <RecentlyAddedPill>
                    <TextOverflowEllipsis>Recently Added</TextOverflowEllipsis>
                  </RecentlyAddedPill>
                </TextOverflowEllipsis>
              </div>
            </Tooltip>
          )
        },
      },
      {
        Header: 'Notes',
        accessor: (row) => {
          return { note: row?.note, id: row?._id }
        },
        tableHeaderStyles: headerStyleBase({ width: '30%' }),
        tableCellStyles: cellStyleBase({ width: '30%' }),
        Cell: ({ value }) => <EnterNotes value={value} searchParams={searchParams} />,
      },
    ],
    [searchParams]
  )
}

export const Rows = (reconciledPayments) => {
  return React.useMemo(
    () =>
      reconciledPayments.map((payment) => {
        const dayPassedSinceAccountAdded = differenceInDays(
          new Date(),
          new Date(payment?.transactionDate)
        )
        const isRecentlyAdded = dayPassedSinceAccountAdded < 1

        return { ...payment, isRecentlyAdded }
      }),
    [reconciledPayments]
  )
}
