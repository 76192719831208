import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class LegalEntityService extends BaseService {
  search({ q, filters, limit, sorting, start }, signal) {
    const searchParams = QueryBuilder()
      .setQ(q)
      .setFilters(filters)
      .setPaging({ start, limit })
      .setSorting(sorting)
      .build()

    return this.client.get(`legal-entities/search?${searchParams}`, { signal }).json()
  }

  fetchLegalEntity(id) {
    return this.client.get(`legal-entities/${id}`).json()
  }

  createLegalEntity({ newLegalEntity, versionedApplicationId }) {
    const config = {
      json: { newLegalEntity, versionedApplicationId },
    }

    return this.client.post('legal-entities', config).json()
  }

  updateLegalEntity(id, payload, versionedLoanApplicationId) {
    const config = {
      json: { payload, versionedLoanApplicationId },
    }

    return this.client.put(`legal-entities/${id}`, config).json()
  }
}
